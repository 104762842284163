import EarnCertificate from "../../Assets/Home/earn-certificate-54px.svg";
import LearnFromExpert from "../../Assets/Home/learn-from-xperts-54px.svg";
import StaySafe from "../../Assets/Home/stay-safe-54px.svg";

// New Figma Import

// Chapter 1
import Chapter1Lesson1 from "../../Assets/Home/chapter1/card-image-1.png";
import Chapter1Lesson2 from "../../Assets/Home/chapter1/card-image-2.jpg";
import Chapter1Lesson3 from "../../Assets/Home/chapter1/card-image-3.jpg";
import Chapter1Lesson4 from "../../Assets/Home/chapter1/card-image-4.jpg";

// Chapter 2
import Chapter2Lesson1 from "../../Assets/Home/chapter2/card-image-1.jpg";
import Chapter2Lesson2 from "../../Assets/Home/chapter2/card-image-2.png";
import Chapter2Lesson3 from "../../Assets/Home/chapter2/card-image-3.jpg";
import Chapter2Lesson4 from "../../Assets/Home/chapter2/card-image-4.jpg";

// Chapter 3
import Chapter3Lesson1 from "../../Assets/Home/chapter3/card-image-1.png";
import Chapter3Lesson2 from "../../Assets/Home/chapter3/card-image-2.png";
import Chapter3Lesson3 from "../../Assets/Home/chapter3/card-image-3.jpg";
import Chapter3Lesson4 from "../../Assets/Home/chapter3/card-image-4.png";

// Chapter 4
import Chapter4Lesson1 from "../../Assets/Home/chapter4/card-image-1.png";
import Chapter4Lesson2 from "../../Assets/Home/chapter4/card-image-2.png";
import Chapter4Lesson3 from "../../Assets/Home/chapter4/card-image-3.jpg";
import Chapter4Lesson4 from "../../Assets/Home/chapter4/card-image-4.png";

// Chapter 5
import Chapter5Lesson1 from "../../Assets/Home/chapter5/card-image-1.jpg";
import Chapter5Lesson2 from "../../Assets/Home/chapter5/card-image-2.jpg";
import Chapter5Lesson3 from "../../Assets/Home/chapter5/card-image-3.jpg";
import Chapter5Lesson4 from "../../Assets/Home/chapter5/card-image-4.jpg";

// Chapter 6
import Chapter6Lesson1 from "../../Assets/Home/chapter6/card-image-1.jpg";
import Chapter6Lesson2 from "../../Assets/Home/chapter6/card-image-2.png";
import Chapter6Lesson3 from "../../Assets/Home/chapter6/card-image-3.jpg";
import Chapter6Lesson4 from "../../Assets/Home/chapter6/card-image-4.jpg";

import Book from "../../Assets/Embark/book-54px.svg";
import Media from "../../Assets/Embark/media-54px.svg";
import progress from "../../Assets/Embark/progress-54px.svg";

// about card
import icon1 from "../../Assets/About/Icon1.svg";
import icon2 from "../../Assets/About/Icon2.svg";
import icon3 from "../../Assets/About/Icon3.svg";

// home card
import Ability from "../../Assets/Home/scroll-card-1.jpg";
import InnovationAndTech from "../../Assets/Home/scroll-card-2.jpg";
import AdvancedDriving from "../../Assets/Home/scroll-card-3.jpg";

// Common CONSTANT
export const CONSTANT = {
  FOOTER_ABOUT_LIST: [
    {
      link: "https://www.vw.co.za/en/volkswagen-experience/corporate-information.html",
      label: "Volkswagen Group South Africa",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/volkswagen-experience/driving-academy.html",
      label: "Volkswagen Advance Driving",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/volkswagen-experience/newsroom.html",
      label: "Volkswagen Newsroom",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/offers-and-finance/fleet.html",
      label: "Corporate Sales",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/volkswagen-experience/corporate-information/careers.html",
      label: "Careers",
      target: "_blank",
    },
  ],

  FOOTER_OWN_LIST: [
    {
      link: "https://www.vw.co.za/app/forms/vw-za/quote/en/",
      label: "Request a Quote",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/app/forms/vw-za/booktestdrive/en/+/+/+/+/+/+/+/+/+/+/+/+/+/+/+",
      label: "Book a Test Drive",
      target: "_blank",
    },
    {
      link: "https://forms.vw.co.za/i/book-a-service",
      label: "Book a Service",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/app/easyfinanceoffers/",
      label: "EasyFinance Offers",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/app/specialoffers/",
      label: "Family Offers",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/offers-and-finance/certified-pre-owned-mastercars.html",
      label: "Certified Pre-Owned MasterCars",
      target: "_blank",
    },
  ],

  FOOTER_OWNER_LIST: [
    {
      link: "https://www.vw.co.za/en/owners.html",
      label: "Owners",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/offers-and-finance/financial-services.html",
      label: "Volkswagen Financial Service",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/help-and-support.html",
      label: "Contact Us",
      target: "_blank",
    },
    {
      link: "https://www.vw.co.za/en/dealer-search-south-africa.html",
      label: "Find A Dealer",
      target: "_blank",
    },
  ],

  FOOTER_SOCIAL_MEDIA_LIST: [
    {
      link: "https://www.facebook.com/VWSA/",
      label: "Facebook",
      target: "_blank",
    },
    {
      link: "https://www.youtube.com/user/volkswagensa",
      label: "YouTube",
      target: "_blank",
    },
    {
      link: "https://twitter.com/VolkswagenSA",
      label: "Twitter",
      target: "_blank",
    },
    {
      link: "https://www.instagram.com/volkswagensouthafrica/",
      label: "Instagram",
      target: "_blank",
    },
  ],
};

// Common button CONSTANT
export const BUTTON_CONSTANT = {
  PROCESSING: "Processing...",
  START_THE_NIGHT_SCHOOL: "Start The Night School",
  ENROLL_NOW: "Enrol Now",
  CONTACT_US: "Contact Us",
  VIEW_FULL_COURSE: "View Full Course",
  LOGIN_IN: "Log In",
  REGISTER: "Register",
  SIGN_IN: "Sign In",
  RESET_PASSWORD: "Reset Password",
  GET_IN_TOUCH: "Get In Touch",
  DOWNLOAD: "Download",
  SHARE: "Share",
  NEXT: "Next",
  RESEND: "Resend",
  RESEND_EMAIL: "Resend E-Mail",
  SKIP_TO_SIGN_IN: "Skip To Sign In",
  NEXT_CHAPTER: "Next Chapter",
  RETAKE_TEST: "Take Test",
  VIEW_CERTIFICATE: "View Certificate",
  VW_NIGHT_SCHOOL: "VW Night School",
  SAVE: "Save",
  LEARN_MORE: "Learn More",
  FIND_OUT_MORE: "Find Out More",
  SUBMIT: "Submit",
  TAKE_ME_THERE: "Take Me There",
  VW_SING_IN: "VW Sign In",
  PROFILE: "Profile",
  PREVIOUS: "Previous",
};

// Common card CONSTANT
export const CARD_CONSTANT = {
  HOME_CARD_LIST: [
    {
      image: InnovationAndTech,
      heading: "Innovation & Technology",
      description:
        "At Volkswagen, innovation is more than a concept; it's ingrained in our DNA. Our cars are made smart, so you can drive smart.",
      link: "https://www.vw.co.za/en/innovation-technology.html",
    },

    {
      image: AdvancedDriving,
      heading: "Advanced Driving",
      description:
        " Volkswagen Advanced Driving offers a full range of advanced driving courses designed to help ensure your personal safety on the road.",
      link: "https://www.vw.co.za/en/volkswagen-experience/driving-academy.html",
    },

    {
      image: Ability,
      heading: "Ability",
      description:
        "IQ.DRIVE is all the intelligent driver assistance systems found in Volkswagen vehicles. It encompasses all of the impressive technologies already found in today’s Volkswagen for everyday situations such as parking (Park Assist), or lane changing (Lane Assist), and help prevent accidents (Front Assist with City Emergency Brake function) as well as the future technologies that will steer us to autonomous driving.",
      link: "https://www.vw.co.za/en/innovation-technology/driver-assistance-systems.html",
    },
  ],

  ENROLL_SMALL_CARD_LIST: [
    {
      image: EarnCertificate,
      title: "Earn Certificates",
      description:
        "Complete and pass the test at the end of each chapter to earn your Night Driver Certificate after completing the whole course.",
    },
    {
      image: LearnFromExpert,
      title: "Learn From Experts",
      description:
        "Get expert tips, lessons, and techniques from Matthew Merton (Volkswagen Advanced Driving) and Jonathan Mogotsi (Volkswagen Motorsport).",
    },
    {
      image: StaySafe,
      title: "Stay Safe On The Road",
      description:
        "Understand how to navigate roads safely after dark to ensure the safety of yourself and other road users.",
    },
  ],

  ABOUT_ENROLL_SMALL_CARD_LIST: [
    {
      image: icon1,
      title: "Reduced",
      title2: "visibility",
      description:
        "Poor vision and visibility are one of the leading causes of accidents on South African roads, especially at night.",
    },
    {
      image: icon2,
      title: "Feeling",
      title2: "Fatigued",
      description:
        "A vast number of accidents happen because of fatigue, resulting in 25%-30%* of fatal crashes after dark.",
    },
    {
      image: icon3,
      title: "Warning Other Drivers",
      description:
        "Driving at night can be challenging, so it's important to stay alert to warnings from other drivers about possible obstructions or hazards ahead.",
    },
  ],
};

// Common video CONSTANT
export const VIDEO_TRANSCRIPT = {
  NIGHT_SCHOOL_TRANSCRIPT_DATA: [
    {
      title: "Donovan:",
      description:
        "Hello, it's me Donavon Goliath, quintessential funny guy but also a major car lover. Here with me is Matthew Merton who sometimes thinks he's funnier than me....",
    },

    {
      title: "Matthew:",
      description:
        "Argh thanks, I try. Yes, I'm Matthew the chief instructor here at the Volkswagen Advanced Driving Academy. And welcome to VW Night School.",
    },

    {
      title: "Donovan:",
      description:
        "Not the night school you're thinking of this is about safe driving at night.",
    },

    {
      title: "Matthew:",
      description:
        "Correct! In this driving course you will go through lessons from a variety of chapters. Giving you safety tips that you need to be aware of when driving, especially at night.",
    },

    {
      title: "Donovan:",
      description: "Oh nice, sounds super informative and empowering.",
    },
  ],

  BEFORE_WE_DRIVE_TRANSCRIPT_DATA: [
    {
      speaker: "Donovan",
      text: [
        "Welcome to chapter 1, before we drive.",
        "Before going on a long journey, there are a few things to check on your vehicle.",
        "Make sure your vehicle goes for regular service and maintenance before a long journey at night.",
      ],
    },
    {
      speaker: "Matthew",
      text: [
        "Yes, it's important to go to a petrol station and get the following checked:",
        "• Engine oil",
        "• Brake fluid",
        "• And water levels. Then top up if you need to.",
        "Other vehicle checks include:",
        "• Making sure your windscreen is clean and your wiper blades are functioning properly.",
        "• Checking that your tyre treading is not worn out.",
        "• As well as checking that your lights are all working.",
      ],
    },
  ],
};

// Common before we drive CONSTANT
export const BEFORE_WE_DRIVE_WARNING = {
  QUIZ_SUMMARY: [
    {
      question: "Question 1: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 2: Incorrect",
      correctAnswer: "Correct answer",
      answer:
        "True: Cleaning your headlights can improve your visibility at night.",
    },

    {
      question: "Question 3: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 4: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 5: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 6: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 7: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 8: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 9: Correct",
      correctAnswer: "",
      answer: "",
    },

    {
      question: "Question 10: Incorrect",
      correctAnswer: "Correct answer",
      answer:
        "True: Cleaning your headlights can improve your visibility at night.",
    },
  ],
};

// Progress
export const ProgressAccordionData = {
  progressData: [
    {
      title: "Before We Drive",
      description:
        "Driving at night presents a unique set of challenges and considerations that differ significantly from daytime driving.",
      subDescription:
        "Chapter One of this guide aims to provide essential insights into the art of driving during the night, focusing on safety measures, visibility concerns, and strategies to manage potential risks.",
      value: 100,
    },

    {
      title: "Visions",
      description:
        "Approximately 90% of our daily activities require vision, and when it comes to driving, vision is clearly the most important of our senses.",
      subDescription:
        "Poor vision and visibility are among the leading causes of accidents on South African roads. It’s your ability to see and be seen that could make the difference. In this chapter, you’ll learn about some of the common conditions that affect vision and eyesight while you drive at night, how lights affect your vision when you drive at night, and useful tips on how to overcome these.",
      value: 100,
    },

    // {
    //   title: "Safety",
    //   description:
    //     "The content provided in the Night School is for educational purposes only. Any unauthorized copying or distribution is strictly prohibited.",
    //   subDescription:
    //     "The majority of these hijackings occur at night. This chapter will give you insights into hijacking and provide you with tips on how to avoid it. For practical guidance on managing a hijacking scenario, sign up to the Volkswagen Advanced Driving Academy Night School Anti-Hijacking training course.",
    //   value: 0,
    // },

    {
      title: "Safety",
      description:
        "There Were 134 000 Hijacking Victims In The 2021/2022 Period.",
      subDescription:
        "The majority of these hijackings occur at night. This chapter will give you insights into hijacking and provide you with tips on how to avoid it. For practical guidance on managing a hijacking scenario, sign up to the Volkswagen Advanced Driving Academy Night School Anti-Hijacking training course.",
      value: 0,
    },

    {
      title: "Ability",
      description:
        "The effects of excessive speeding contribute a high number of fatalities on the roads yearly.",
      subDescription:
        "There was a total of 1089 reported speed arrests in 2022. And in the 2023 Easter period (6 to 10 April) 22% of fatal crashes were due to speeding. Put simply, speed kills. In this chapter, you will learn about the dangers of speeding and tips on how to avoid speeding and collisions when driving at night. Always remember, the faster you go the less time you have to respond, especially at night when visibility is poor.",
      value: 0,
    },

    {
      title: "Conditions",
      description:
        "Bad weather conditions play a huge part in road safety, so it’s important to understand how these impact driving at night when visibility is already low.",
      subDescription:
        "This chapter will teach you about driving in various weather conditions like rain, fog, mist, and icy roads. You’ll also learn about using your fog lights and to be cautious when driving in rain. To learn more about driving in bad weather conditions – sign up for the in-person advanced driving Night School course.",
      value: 0,
    },

    {
      title: "Other Road Users",
      description:
        "When driving on the road at night, you share the road with other road users.",
      subDescription:
        "Like pedestrians, trucks, cyclists, bikers, wildlife, and livestock. A growing concern is pedestrian fatalities, with 41.5% of pedestrian accidents happening between the hours of 18:00 and 00:00.",
      value: 0,
    },

    // {
    //   title: "Wrap Up Video",
    //   description:
    //     "VW reserves the right to change, modify, or revise these Terms and Conditions at any time. Continued use of the site after any such changes constitutes acceptance of the new Terms.",
    //   // subDescription:
    //   //   "Like pedestrians, trucks, cyclists, bikers, wildlife, and livestock. A growing concern is pedestrian fatalities, with 41.5% of pedestrian accidents happening between the hours of 18:00 and 00:00.",
    //   value: 0,
    // },
  ],
};

// Common vertical stepper CONSTANT
export const BEFORE_WE_DRIVE_COLLAPSE = {
  YOUR_PROGRESS: [
    {
      title: "Chapter 1",
      subTitle: "Before we drive",
      questions: [
        { step: "Checks" },
        { step: "Check Lights" },
        { step: "Locking Doors" },
        { step: "Turn Off Lights" },
        { step: "Emergency Items" },
        { step: "Map Your Route" },
        { step: "Tyre Tread" },
        { step: "Warning Lights" },
      ],
    },
    { title: "Chapter 2", subTitle: "Vision" },
    { title: "Chapter 3", subTitle: "Safety" },
    { title: "Chapter 4", subTitle: "Ability" },
    { title: "Chapter 5", subTitle: "Conditions" },
    { title: "Chapter 6", subTitle: "Other road users" },
    { title: "Wrap up video" },
  ],
};

// New Figma Constant

// Chapters
export const HOME_CHAPTERS_BUTTON = {
  CHAPTERS: [
    { title: "Chapter 1" },
    { title: "Chapter 2" },
    { title: "Chapter 3" },
    { title: "Chapter 4" },
    { title: "Chapter 5" },
    { title: "Chapter 6" },
  ],
};

// Common terms of conditions CONSTANT
export const TERMS_CONDITIONS = {
  TERMS: [
    {
      title: "Introduction",

      description:
        "This page outlines the terms, conditions, and rules (“Terms and Conditions”) for taking part in the Night School Curriculum (“The Curriculum”).",

      sentenceOne:
        "1. These Terms and Conditions govern the Night School Curriculum hosted by Volkswagen South Africa (Pty) Ltd 1946/023458/07 (“VWSA”) and managed by Ogilvy Cape Town.",

      sentenceTwo:
        "2. By participating in the Curriculum, you agree to these Terms and Conditions.",

      sentenceThree:
        "3. Participation in the Curriculum provides the entrants an opportunity to be eligible to be incentivised with exclusive rewards from partner brands.",

      sentenceFour:
        "4. In addition, your access to any website or application required for participation will be subject to the terms and conditions imposed by the respective service providers.",
    },

    // {
    //   title: "Partners",
    //   sentenceOne:
    //     "5. Spec-Savers South Africa. The offer and terms are as follows: ",

    //   subSentencePartners: [
    //     {
    //       subSentenceOne:
    //         "a. Get 20% off on Anti-reflective Coating from any Spec-Savers nationwide.",
    //       subSentenceTwo:
    //         "b. This offer is only available to those who have completed the driving school online or the physical course.",
    //       subSentenceThree:
    //         "c. This offer excludes eye test, frame, and single vision lenses.",
    //       subSentenceFour:
    //         "d. Voucher needs to be presented in-store and code redeemed.",
    //       subSentenceFive: "e. Valid until 25 April 2024.",
    //     },
    //   ],

    //   sentenceTwo: "6. Vida e Caffe - Valid until 25 April 2024.",

    //   description:
    //     "The offer and terms are that you get 10% off discount voucher on limited coffee from Vida e Caffé stores nationwide.",
    // },

    {
      title: "Eligibility",
      sentenceOne:
        "5. In order to participate in the Curriculum, entrants must be 18 years or older and of full legal capacity, and in possession of a valid driver’s license (for the in-person night driving course) and legal residents of South Africa.",

      sentenceTwo:
        "6. Successful entry into the Curriculum does not amount to eligibility.",

      sentenceThree:
        "7. Announcement of a winner does not amount to compliance with the Curriculum Terms and Conditions. VWSA reserves the right to disqualify any entrant who breaches any of the Curriculum Terms and Conditions or engages in any unlawful or dishonest conduct in relation to the Curriculum. In this regard, VWSA shall be entitled to recover any loss or damage from the responsible party arising from such conduct.",

      sentenceFour:
        "8. All participants of the Curriculum (unless they request to retain their anonymity) agree to:",

      subSentenceEligibility: [
        {
          subSentenceOne:
            "a. if required by VWSA, participate in a photo, video, film and/or radio session, or any other form of publicity for VWSA in relation to the Curriculum.",
          subSentenceTwo:
            "b. the broadcasting or other publication of an entrant's name, character, statements, voice recordings or any other information regarding the entrant, in any advertising or promotional activities concerning a Curriculum or promotions of VWSA in general. This includes the use of the entrant’s name to disclose that they have won a Prize; and",
          subSentenceThree:
            "c. allow VWSA to use, in any reasonable manner as they see fit, any entrant’s materials submitted in connection with the Curriculum, whether in written, electronic, visual, audio and/or any other form). Upon such submission, such materials (and all copyright in them) become the property of VWSA and the entrant waives all moral rights, rights of publicity, and any other intellectual property rights he/she may have in such materials.",
        },
      ],

      sentenceFive:
        "9. The participant may withdraw the consent in paragraph 11 by contacting VWSA at 129 Patricia Road, Sandton, 2031, Tel: 011 911 2700 or email Support@vwconnect.co.za and facilitated by VWSA’s marketing agency Ogilvy Cape Town, 41 Sir Lowry Road, Woodstock, 7925, Tel: 021 467 1000 or opting out from any electronic marketing message.",

      sentenceSix:
        "10. Unless you consent otherwise, neither VWSA nor any of its Dealers will use the personal information you give us for any purpose other than facilitation of the Competition or compliance with applicable legislation.",
    },

    {
      title: "Curriculum Costs",
      sentenceOne:
        "11. Entry and participation in the online curriculum is free. Entrants shall, however, be responsible for the costs imposed by their internet service providers.",
      sentenceTwo:
        "12. If participants sign up for the in-person advanced night driving safety course, there will be a fee charged for these courses. Enquire at",
    },

    {
      title: "Reward Information",
      // sentenceOne:
      //   "15. To be eligible to win the rewards, participants must complete the entire Curriculum.",

      // sentenceTwo:
      //   "16. Failure to claim the prize or a refusal or inability to supply required documentation or comply with any of the curriculum requirements within 2 months will disqualify the Winner and a new Winner will be drawn in terms of these rules.",

      // sentenceThree:
      //   "17. The prize is not exchangeable for cash and not transferable and VWSA is not liable for any defect in the prize. VWSA reserves the right to substitute the prize with any other prize of comparable commercial value.",

      // sentenceFour:
      //   "18. VWSA reserve the right to withdraw the prize should the winner fail to adhere to these Terms and Conditions.",

      // description: "Data Protection:",

      sentenceFive:
        "13. The processing of your personal information is necessary to carry out actions for the conclusion and performance of the Curriculum, to which you have registered. The personal information collected for this purpose includes:",

      subSentenceReward: [
        {
          subSentenceOne: "a. Name",
          subSentenceTwo: "b. Surname",
          subSentenceFour: "c. Telephone Number",
          subSentenceFive: "d. E-mail Address",
        },
      ],

      sentenceSix:
        "14. VWSA does not sell your information to third parties. VWSA may from time to time disclose and jointly process customer information with its agents, dealer partners, holding company and affiliates including, but not limited to, Volkswagen Aktiengesellscahft in Germany and Volkswagen Financial Services of South Africa.",

      sentenceSeven:
        "15. You have a right to access any of your personal information that VWSA holds and make corrections if necessary and the right to object to the processing of your personal information. You also have the right to withdraw any consent that you have previously given to VWSA and ask VWSA to erase or delete your personal information that is held. VWSA shall have a right to continue to process your information where a legitimate purpose exists.",

      sentenceEight:
        "16. Should you be dissatisfied with the manner in which your personal information is being processed, and wish to lodge a complaint you may do so with either VWSA’s Information Officer at info.officer@vwsa.co.za or alternatively with the Information Regulator. The contact details for the Information Regulator are:",

      sentenceEightDescription1:
        "Physical address: JD House, 27 Stiemens Street, Braamfontein, Johannesburg, 2001",

      sentenceEightDescription2:
        "Postal address: P.O Box 31533, Braamfontein, Johannesburg, 2017",

      sentenceEightDescription3:
        "General Enquiries - enquiries@inforegulator.org.za Complaints - POPIAComplaints@inforegulator.org.za",

      sentenceNine:
        "17. VWSA conducts surveys to improve their service and product offerings and may occasionally contact you to request your participation. You may withdraw your consent to the processing of your personal information for this purpose at any time.",
    },

    {
      title: "General Terms",
      sentenceOne:
        "18. No warranty, guarantee or representation, express or implied, is made by VWSA relating to the information contained in the Curriculum material. VWSA, its employees, service providers and agents assume no liability or responsibility for any loss, damage, injury, cost or expense sustained by the Participant arising from the Curriculum material. The Participant Indemnifies VWSA, its employees, service providers and agents accordingly.",

      sentenceTwo:
        "19. All video content or illustrations is purely for demonstrative purposes only.",

      sentenceThree:
        "20. Participants will have no claim for death, injury, permanent disablement or any claim whatsoever, against VWSA, its agents, suppliers, their employees, and/or directors.",

      sentenceFour:
        "21. VWSA shall not be responsible for any lost, damaged, delayed, incorrect or incomplete entries for any reason whatsoever. Proof of sending will not be accepted as proof of receipt.",

      sentenceFive:
        "22. VWSA or its agent’s decision is final, and no correspondence will be entered into.",

      sentenceSix:
        "23. If VWSA is legally required to alter any aspect of the Curriculum or to terminate the Curriculum, VWSA will have the right to do so without prior notice. Neither VWSA nor its Dealers or agents shall be liable for such alteration or cancellation.",

      sentenceSeven:
        "24. VWSA reserves the right to postpone this Curriculum at any time in its sole discretion.",

      sentenceEight:
        "25. Personal information collected directly between VWSA and winning entrants will be used for the purposes of communications between both parties.",

      sentenceNine:
        "26. These Terms and Conditions are available on the following website",
    },
  ],
};

export const HOME_CHAPTERS_CARDS_HEADING = {
  CHAPTERS1: [
    {
      heading: "one",
      boldInfo:
        "Driving at night presents a unique set of challenges and considerations that differ significantly from daytime driving.",
      info: " Chapter One of this guide aims to provide essential insights into the art of driving during the night, focusing on safety measures, visibility concerns, and strategies to manage potential risks.",
    },
  ],

  CHAPTERS2: [
    {
      heading: "two",
      boldInfo:
        "Approximately 90% of our daily activities require vision, and when it comes to driving, vision is clearly the most important of our senses.",
      info: "Poor vision and visibility are among the leading causes of accidents on South African roads. It’s your ability to see and be seen that could make the difference. In this chapter, you’ll learn about some of the common conditions that affect vision and eyesight while you drive at night, how lights affect your vision when you drive at night, and useful tips on how to overcome these.",
    },
  ],

  CHAPTERS3: [
    {
      heading: "three",
      boldInfo:
        "There were 134 000 hijacking victims in the 2021/2022 period. ",
      info: "The majority of these hijackings occur at night. This chapter will give you insights into hijacking and provide you with tips on how to avoid it. For practical guidance on managing a hijacking scenario, sign up to the Volkswagen Advanced Driving Academy Night School Anti-Hijacking training course.",
    },
  ],

  CHAPTERS4: [
    {
      heading: "four",
      boldInfo:
        "The effects of excessive speeding contribute a high number of fatalities on the roads yearly.",
      info: "There was a total of 1089 reported speed arrests in 2022. And in the 2023 Easter period (6 to 10 April) 22% of fatal crashes were due to speeding. Put simply, speed kills. In this chapter, you will learn about the dangers of speeding and tips on how to avoid speeding and collisions when driving at night. Always remember, the faster you go the less time you have to respond, especially at night when visibility is poor.",
    },
  ],

  CHAPTERS5: [
    {
      heading: "five",
      boldInfo:
        "Bad weather conditions play a huge part in road safety, so it’s important to understand how these impact driving at night when visibility is already low.",
      info: "This chapter will teach you about driving in various weather conditions like rain, fog, mist, and icy roads. You’ll also learn about using your fog lights and to be cautious when driving in rain. To learn more about driving in bad weather conditions – sign up for the in-person advanced driving Night School course.",
    },
  ],

  CHAPTERS6: [
    {
      heading: "six",
      boldInfo:
        "When driving on the road at night, you share the road with other road users.",
      info: "Like pedestrians, trucks, cyclists, bikers, wildlife, and livestock. A growing concern is pedestrian fatalities, with 41.5% of pedestrian accidents happening between the hours of 18:00 and 00:00.",
    },
  ],
};

export const HOME_CHAPTERS_CARDS = {
  CHAPTERS1: [
    {
      image: Chapter1Lesson1,
      number: "1.0",
      lesson: "Lesson 1",
      totalLesson: "of 9",
      title: "Introduction",
      name: "By: VW Night School",
      description:
        "Before you embark on a long journey at night, do a comprehensive vehicle check on your car.",
      basics: "Basics",
      min: "5 min",
      video: "1 Video",
    },
    {
      image: Chapter1Lesson2,
      number: "1.1",
      lesson: "Lesson 2",
      totalLesson: "of 9",
      title: "Tips: Engine Oil",
      name: "By: VW Night School",
      description:
        "Before a long journey, go to a petrol station to check your engine oil, brake fluid.",
      basics: "Basics",
      min: "2 min",
      video: "1 Video",
    },
    {
      image: Chapter1Lesson3,
      number: "1.2",
      lesson: "Lesson 3",
      totalLesson: "of 9",
      title: "Tips: Windshield",
      name: "By: VW Night School",
      description:
        "Make sure your windshield is clean and the wipers are functioning properly.",
      basics: "Basics",
      min: "1 min",
    },
    {
      image: Chapter1Lesson4,
      number: "1.3",
      lesson: "Lesson 4",
      totalLesson: "of 9",
      title: "Tips: Check your tyres",
      name: "By: VW Night School",
      description: "Inflate your tyres to the recommended pressure.  ",
      basics: "Basics",
      min: "2 min",
      video: "1 Video",
    },
  ],

  CHAPTERS2: [
    {
      image: Chapter2Lesson1,
      number: "2.0",
      lesson: "Lesson 1",
      totalLesson: "of 18",
      title: "Introduction",
      name: "By: VW Night School",
      description:
        "Approximately 90% of our daily activities require vision, and when it comes to driving, vision is clearly the most important of our senses.",
      basics: "Basics",
      min: "5 min",
    },
    {
      image: Chapter2Lesson2,
      number: "2.1",
      lesson: "Lesson 2",
      totalLesson: "of 18",
      title: "Your vision",
      name: "By: VW Night School",
      description:
        "Your eyes are the most important factor when it comes to vision and safely driving at night",
      basics: "Basics",
      min: "2 min",
    },
    {
      image: Chapter2Lesson3,
      number: "2.2",
      lesson: "Lesson 3",
      totalLesson: "of 18",
      title: "Tips: Common conditions ",
      name: "By: VW Night School",
      description:
        "Driving at night already reduces visibility, but there are common conditions that do affect vision and eyesight including Astigmatism, Motion Induced Blindness and Night Blindness.",
      basics: "Basics",
      min: "1 min",
    },
    {
      image: Chapter2Lesson4,
      number: "2.3",
      lesson: "Lesson 4",
      totalLesson: "of 12",
      title: "Tips: Astigmatism",
      name: "By: VW Night School",
      description:
        "Astigmatism is a defect in the eye that makes light distort.",
      basics: "Basics",
      min: "2 min",
      video: "1 Gif",
    },
  ],

  CHAPTERS3: [
    {
      image: Chapter3Lesson1,
      number: "3.1",
      lesson: "Lesson 1",
      totalLesson: "of 7",
      title: "Hijacking Introduction",
      name: "By: VW Night School",
      description:
        "The majority of these hijackings occur at night. This chapter will give you insights into hijacking.",
      basics: "Basics",
      min: "5 min",
      video: "1 Video",
    },
    {
      image: Chapter3Lesson2,
      number: "3.1.1",
      lesson: "Lesson 2",
      totalLesson: "of 7",
      title: "Tips: Stay alert",
      name: "By: VW Night School",
      description:
        "Stay alert on the road, especially when driving at night to ensure your safety, and that of your passengers.",
      basics: "Basics",
      min: "2 min",
    },
    {
      image: Chapter3Lesson3,
      number: "3.1.2",
      lesson: "Lesson 3",
      totalLesson: "of 7",
      title: "Tips: Vehicle jamming",
      name: "By: VW Night School",
      description:
        " Vehicle jamming is when a remote device is used to block or jam the locking device of a vehicle.",
      basics: "Basics",
      min: "1 min",
    },
    {
      image: Chapter3Lesson4,
      number: "3.1.3",
      lesson: "Lesson 4",
      totalLesson: "of 7",
      title: "Tips: Hijacking hotspots",
      name: "By: VW Night School",
      description:
        "Stay alert of high-risk areas at night and during peak hours.",
      basics: "Basics",
      min: "2 min",
    },
  ],

  CHAPTERS4: [
    {
      image: Chapter4Lesson1,
      number: "4.1",
      lesson: "Lesson 1",
      totalLesson: "of 8",
      title: "Speed Introduction",
      name: "By: VW Night School",
      description:
        "The effects of excessive speeding contribute a high number of fatalities on the roads yearly.",
      basics: "Basics",
      min: "5 min",
      video: "1 Video",
    },
    {
      image: Chapter4Lesson2,
      number: "4.1.1",
      lesson: "Lesson 2",
      totalLesson: "of 8",
      title: "Tips: Speed limits",
      name: "By: VW Night School",
      description:
        "Always drive at 60 km/h on public roads in urban areas: that means any road that has high pedestrian traffic.",
      basics: "Basics",
      min: "2 min",
    },
    {
      image: Chapter4Lesson3,
      number: "4.1.2",
      lesson: "Lesson 3",
      totalLesson: "of 8",
      title: "Tips: Speeding & braking ",
      name: "By: VW Night School",
      description:
        "It’s more difficult to avoid an accident if you’re driving at a high speed at night.",
      basics: "Basics",
      min: "5 min",
      video: "1 Video",
    },
    {
      image: Chapter4Lesson4,
      number: "4.1.3",
      lesson: "Lesson 4",
      totalLesson: "of 8",
      title: "Tips: Skids",
      name: "By: VW Night School",
      description:
        "Excessive speeding causes your car to skid – there are three types of skids: Understeer, Oversteer, and Hydroplaning.",
      basics: "Basics",
      min: "2 min",
      video: "1 Video",
    },
  ],

  CHAPTERS5: [
    {
      image: Chapter5Lesson1,
      number: "5.1",
      lesson: "Lesson 1",
      totalLesson: "of 5",
      title: "Weather Introduction",
      name: "By: VW Night School",
      description:
        "Bad weather conditions play a huge part in road safety, so it’s important to understand how these impact driving at night.",
      basics: "Basics",
      min: "1 min",
    },
    {
      image: Chapter5Lesson2,
      number: "5.1.1",
      lesson: "Lesson 2",
      totalLesson: "of 5",
      title: "Tips: Vehicle ventilation",
      name: "By: VW Night School",
      description:
        "Keep your vehicle well-ventilated when driving at night in extreme weather conditions.",
      basics: "Basics",
      min: "2 min",
    },
    {
      image: Chapter5Lesson3,
      number: "5.1.2",
      lesson: "Lesson 3",
      totalLesson: "of 5",
      title: "Tips: Fog lights",
      name: "By: VW Night School",
      description:
        "When it’s foggy at night, never use your headlights; always opt to use your fog lights for better vision",
      basics: "Basics",
      min: "5 min",
      video: "1 Video",
    },
    {
      image: Chapter5Lesson4,
      number: "5.1.3",
      lesson: "Lesson 4",
      totalLesson: "of 5",
      title: "Tips: Rain",
      name: "By: VW Night School",
      description:
        "When it rains, several things occur that make driving more dangerous at night.",
      basics: "Basics",
      min: "4 min",
    },
  ],

  CHAPTERS6: [
    {
      image: Chapter6Lesson1,
      number: "6.1",
      lesson: "Lesson 1",
      totalLesson: "of 7",
      title: "Introduction",
      name: "By: VW Night School",
      description:
        "When driving on the road at night, you share the road with other road users.",
      basics: "Basics",
      min: "1 min",
    },
    {
      image: Chapter6Lesson2,
      number: "6.1.1",
      lesson: "Lesson 2",
      totalLesson: "of 5",
      title: "Tips: Other road users",
      name: "By: VW Night School",
      description:
        "Always stay alert on the roads for other road users – this could be partygoers at night, nightshift workers, cyclists etc.",
      basics: "Basics",
      min: "2 min",
    },
    {
      image: Chapter6Lesson3,
      number: "6.1.2",
      lesson: "Lesson 3",
      totalLesson: "of 5",
      title: "Tips: Trucks ",
      name: "By: VW Night School",
      description:
        "When sharing the road with long-distance trucks, be cautious and patient – do not overtake when it’s not safe to do so. ",
      basics: "Basics",
      min: "5 min",
    },
    {
      image: Chapter6Lesson4,
      number: "6.1.3",
      lesson: "Lesson 4",
      totalLesson: "of 5",
      title: "Tips: Blind spot  ",
      name: "By: VW Night School",
      description: "Always check your blind spots when driving on the road.",
      basics: "Basics",
      min: "1 min",
    },
  ],
};

// Common page CONSTANT
export const HOME_PAGE_REDIRECT = {
  HOME: "Home > Night School",
  HOME_ABOUT: "Home > About",
};

// Common EmbarkCard CONSTANT
export const EMBARK_CARD = {
  CARDS: [
    {
      image: Book,
      title: "Curriculum Overview",
      description:
        "Our comprehensive course includes six enriching chapters, covering everything from preparation to interacting with other road users.",
    },

    {
      image: Media,
      title: "Multimedia Learning",
      description:
        "Engage with videos, photographs, gifs, icons, and animations for an immersive learning experience.",
    },

    {
      image: Book,
      title: "Test & Certificate",
      description:
        "Test your knowledge with test at the end of each chapter. Pass the test to earn certificates that mark your achievements.",
    },

    {
      image: progress,
      title: "Progress Tracking",
      description:
        "Your progress will be saved as you advance, allowing you to pick up right where you left off.",
    },

    {
      image: Book,
      title: "Curriculum Overview",
      description:
        "Our comprehensive course includes six enriching chapters, covering everything from preparation to interacting with other road users.",
    },

    {
      image: Media,
      title: "Multimedia Learning",
      description:
        "Engage with videos, photographs, gifs, icons, and animations for an immersive learning experience.",
    },

    {
      image: Book,
      title: "Test & Certificate",
      description:
        "Test your knowledge with test at the end of each chapter. Pass the test to earn certificates that mark your achievements.",
    },

    {
      image: progress,
      title: "Progress Tracking",
      description:
        "Your progress will be saved as you advance, allowing you to pick up right where you left off.",
    },
  ],

  MOBILE_CARD: [
    {
      image: Book,
      title: "Curriculum Overview",
      description:
        "Our comprehensive course includes six enriching chapters, covering everything from preparation to interacting with other road users.",
    },

    {
      image: Media,
      title: "Multimedia Learning",
      description:
        "Engage with videos, photographs, gifs, icons, and animations for an immersive learning experience.",
    },

    {
      image: Book,
      title: "Test & Certificate",
      description:
        "Test your knowledge with test at the end of each chapter. Pass the test to earn certificates that mark your achievements.",
    },

    {
      image: progress,
      title: "Progress Tracking",
      description:
        "Your progress will be saved as you advance, allowing you to pick up right where you left off.",
    },
  ],
};

export const DARK_MODE_PAGE = [
  "/",
  "/home",
  "/about",
  // "/chapters/1",
  // "/chapters/2",
  // "/chapters/3",
  // "/chapters/4",
  // "/chapters/5",
  // "/chapters/6",
  "/chapters/wrap-up",
  "/embark",
  "/user-profile",
  "/terms-conditions",
  "/404",
  "/500",
];

export const LIGHT_MODE_PAGE = [
  "/sign-in",
  "/signup",
  "/reset-password",
  "/reset-password-confirm-mail",
  "/confirm-mail",
  "/reset-password-success",
  "/confirm-reset-password",
  "/signup",
  "/reset-password-verify",
];

export const FOOTER_LINKS = {
  INTEGRITY_COMPLIANCE:
    "https://www.vw.co.za/en/volkswagen-experience/corporate-information/compliance.html",
  VW_SOUTH_AFRICA_TRANSFORMATION:
    "https://www.vw.co.za/en/volkswagen-experience/corporate-information/transformation.html",
  LEGAL:
    "https://www.vw.co.za/en/volkswagen-experience/corporate-information/legal.html",
  MANAGE_COOKIES:
    "https://www.vw.co.za/en/volkswagen-experience/corporate-information/legal.html?open=true",
  PRIVACY:
    "https://www.vw.co.za/en/volkswagen-experience/corporate-information/privacy.html",
};
