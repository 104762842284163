import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ChapterHero from "../../Components/Chapter/ChapterHero";
import apiService from "../../Utils/Services/ApiService";
import Chapter1 from "../../Components/Chapter/Chapter1";
import Chapter2 from "../../Components/Chapter/Chapter2";
import Chapter3 from "../../Components/Chapter/Chapter3";
import Chapter4 from "../../Components/Chapter/Chapter4";
import Chapter5 from "../../Components/Chapter/Chapter5";
import Chapter6 from "../../Components/Chapter/Chapter6";
import ChapterIncomplete from "../../Components/Chapter/ChapterIncomplete";
import { Navigate, Link } from "react-router-dom";
import ChapterBreadcrumbs from "../../Components/Chapter/ChapterBreadcrumbs";
import styleChapterSection from "../../Components/Chapter/ChapterSection.module.scss";
import {
  AppColorButton,
  AppBorderButton,
} from "../../Components/AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import WrapUp from "../../Components/Chapter/WrapUp";
import ErrorMessages from "../../Components/Messages/ErrorMessages";
import { ThemeContext } from "../../Context/ThemeContext";

// Component: Chapter
export default function Chapter() {
  //chapter number from URL param
  let { chapterNumber } = useParams();
  const { theme } = useContext(ThemeContext);

  //test data
  let [data, setData] = useState(null);

  //progress data
  let [progress, setProgress] = useState(null);

  //current question number
  let [questionNumber, setQuestionNumber] = useState(1);

  //multiple choice answers
  let [formValues, setFormValues] = useState({});

  //results after the form submission
  let [results, setResults] = useState(null);

  const [errorsInfo, setErrorsInfo] = useState(null);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    //every new route must start at the top
    window.scrollTo(0, 0);
  }, [chapterNumber]);

  useEffect(() => {
    //fetch test data based on URL param
    if (!isNaN(Number(String(chapterNumber)))) {
      apiService
        .fetchChapter(chapterNumber)
        .then((res) => {
          setData(res.status === 200 ? res : null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //fetch progress
    apiService
      .fetchProgress()
      .then((res) => {
        setProgress(res.status === 200 ? res : null);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chapterNumber]);

  const onSubmit = () => {
    // submit full test if last question option has been selected
    if (formValues[questionNumber]) {
      setErrorsInfo(null);
      setProcessing(true);
      //reformat the data object to be submitted to the API
      let formData = [];
      for (let i = 1; i <= Object.values(formValues).length; i++) {
        formData = [
          ...formData,
          {
            question_number: i,
            answer: formValues[i],
          },
        ];
      }

      //submit test to the API
      apiService
        .submitChapterQuiz(data.chapter_id, formData)
        .then((res) => {
          if (res.status === 200) {
            // set results object
            setResults(res);
            setProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setProcessing(false);
        });
    } else {
      setErrorsInfo({
        answer: "Please select an answer",
      });
    }
  };

  //go to next question only if an option has been selected
  const onQuestionSubmit = () => {
    if (formValues[questionNumber]) {
      setQuestionNumber(questionNumber + 1);
      setErrorsInfo(null);
    } else {
      setErrorsInfo({
        answer: "Please select an answer",
      });
    }
  };

  const previousQuestionSubmit = () => {
    setErrorsInfo(null);
    setQuestionNumber(questionNumber - 1);
  };

  // if the current question is the last one of the chapter, button is Submit otherwise it's a Next
  const renderSubmit = () => {
    if (questionNumber === data.questions.length) {
      return (
        <>
          <div className="d-sm-flex">
            {/* <AppColorButton
              handleClick={() => onSubmit()}
              buttonName={BUTTON_CONSTANT.SUBMIT}
              className={'mb-4 ms-sm-4 order-sm-2'}
            /> */}
            <AppColorButton
              handleClick={() => onSubmit()}
              buttonName={
                processing ? BUTTON_CONSTANT.PROCESSING : BUTTON_CONSTANT.SUBMIT
              }
              disabled={processing}
              className={"mb-4 ms-sm-4 order-sm-2"}
            />
            {questionNumber > 1 ? (
              <AppBorderButton
                handleClick={() => previousQuestionSubmit()}
                buttonName={BUTTON_CONSTANT.PREVIOUS}
                className={"mb-4"}
              />
            ) : (
              <></>
            )}
          </div>
          
        </>
      );
    } else {
      return (
        <>
          <div className="d-sm-flex">
            <AppColorButton
              handleClick={() => onQuestionSubmit()}
              buttonName={BUTTON_CONSTANT.NEXT}
              className={"mb-4 ms-sm-4 order-sm-2"}
            />
            {questionNumber > 1 ? (
              <AppBorderButton
                handleClick={() => previousQuestionSubmit()}
                buttonName={BUTTON_CONSTANT.PREVIOUS}
                className={"mb-4"}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      );
    }
  };

  //map through all the multiple choice options of the current question
  const renderChoices = () => {
    let { choices } = data?.questions[questionNumber - 1];
    let letters = Object.keys(choices);

    return letters.map((letter) => {
      return (
        <label
          key={letter}
          className={`d-block ${styleChapterSection.checkbox} mb-3`}
        >
          <input
            type="radio"
            name="choice"
            value={letter}
            checked={formValues[questionNumber] === letter}
            onChange={() =>
              setFormValues({
                ...formValues,
                [parseInt(questionNumber)]: letter,
              })
            }
            className={`${styleChapterSection.answer} ${
              theme === "light" && styleChapterSection.darkBorder
            }`}
          />
          <span className={`${styleChapterSection.small24} ms-4`}>
            {choices[letter]}
          </span>
        </label>
      );
    });
  };

  //question changes based on question number
  const renderQuiz = () => {
    if (!data) {
      return <></>;
    }

    let { question } = data?.questions[questionNumber - 1];

    return (
      <>
        <p className={`${styleChapterSection.small} mb-3`}>
          {data?.instructions}
        </p>
        <div className="_question">
          <div className="_chart mb-3">
            {questionNumber} / {data?.questions?.length}
          </div>
          <p
            className={`${styleChapterSection.mediumBold} ${styleChapterSection.fontWeight700} mb-3`}
          >
            <strong>Question {questionNumber}</strong>
          </p>

          <p className={`${styleChapterSection.mediumBold} mb-3`}>{question}</p>

          {errorsInfo && (
            <>
              <br />
              <ErrorMessages errors={errorsInfo} />
            </>
          )}

          <div className="mb-5">{renderChoices()}</div>

          {renderSubmit()}
        </div>
      </>
    );
  };

  const renderChapter = () => {
    //cannot go to this chapter if previous chapter is incomplete
    const tmpChapterNumber = isNaN(Number(String(chapterNumber)))
      ? progress?.current_chapter_number || 7
      : chapterNumber;
    if (progress && progress?.current_chapter_number < tmpChapterNumber) {
      return (
        <ChapterIncomplete
          number={progress.current_chapter_number}
          chapterNumber={chapterNumber}
        />
      );
    }

    //return chapter component based on chapter number and pass test as children prop
    switch (String(chapterNumber).toLowerCase()) {
      case "1":
        return <Chapter1>{renderQuiz()}</Chapter1>;
      case "2":
        return <Chapter2>{renderQuiz()}</Chapter2>;
      case "3":
        return <Chapter3>{renderQuiz()}</Chapter3>;
      case "4":
        return <Chapter4>{renderQuiz()}</Chapter4>;
      case "5":
        return <Chapter5>{renderQuiz()}</Chapter5>;
      case "6":
        return <Chapter6>{renderQuiz()}</Chapter6>;
      case "wrap-up":
        return <WrapUp></WrapUp>;

      default:
        return <></>;
    }
  };

  if (results) {
    //if all questions have been completed redirect to results page of this chapter
    return <Navigate to={`/chapters/${chapterNumber}/results`} />;
  }

  return (
    <>
      <ChapterHero
        number={chapterNumber}
        heading={data ? data?.description : ""}
      />
      <ChapterBreadcrumbs>
        <Link to="/home">Home</Link> &gt; <Link to="/embark">Nightschool</Link>{" "}
        &gt;{" "}
        <Link to={`/chapters/${chapterNumber}`}>
          {!isNaN(Number(String(chapterNumber)))
            ? data
              ? "Chapter " + chapterNumber
              : ""
            : "Wrap Up"}
        </Link>
      </ChapterBreadcrumbs>

      {renderChapter()}
    </>
  );
}
