import { TfiAngleRight } from "react-icons/tfi";
import { userTokenMethod } from "../Services/Common.Services";

const isLoggedIn = userTokenMethod("get") ? true : false;

const SideBarList = [
  {
    title: "Home",
    Icon: <TfiAngleRight />,
    path: "/home",
  },

  {
    title: "About",
    Icon: <TfiAngleRight />,
    path: "/about",
  },

  {
    title: "Curriculum",
    Icon: <TfiAngleRight />,
    // path: "/curriculum",
    subNav: true,
    subMenuItems: [
      {
        title: "Chapter 1: Before We Drive",
        path: "/chapters/1",
      },
      {
        title: "Chapter 2: Vision",
        path: "/chapters/2",
      },
      {
        title: "Chapter 3: Safety",
        path: "/chapters/3",
      },
      {
        title: "Chapter 4: Ability ",
        path: "/chapters/4",
      },
      {
        title: "Chapter 5: Conditions",
        path: "/chapters/5",
      },
      {
        title: "Chapter 6: Other Road Users",
        path: "/chapters/6",
      },
      {
        title: "Wrap Up Video",
        path: "/chapters/wrap-up",
      },
    ],
  },

  {
    title: "Start Your Journey",
    Icon: <TfiAngleRight />,
    path: "/embark",
  },

  {
    title: isLoggedIn ? "Profile" : "Log In",
    Icon: <TfiAngleRight />,
    path: isLoggedIn ? "/user-profile" : "/sign-in",
  },
];

export default SideBarList;
