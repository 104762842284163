import { useContext, useEffect, useState } from "react";
import smoothScrollToSection from "../../smoothScrollToSection";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../Context/ThemeContext";

export default function Progress({ data, chapterNumber, sectionRefs }) {
  const { theme } = useContext(ThemeContext);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [open, setOpen] = useState(true)

  const chapters = ["1", "2", "3", "4", "5", "6", "Video"];
  const lastChapter = chapters[chapters.length - 1];

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setScrollPosition(scrollY);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onClick = (id) => {
    smoothScrollToSection(id, 300);
  };

  const toggleOpen = (active) => {

    if(!active){
      return
    }

    setOpen(!open)
  }

  const renderOpenStateIcon = (active) => {

    if(!active){
      return
    }

    return (
      <div className={`_arrow-icon ${open ? '' : '_closed'}`} />
    )
  }

  const renderCircle = (active) => {
    if (active) {
      return <></>;
    }
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11"
          cy="11"
          r="10"
          fill={theme === "light" ? "#FF2F57" : "#00B0F0"}
          fillOpacity="0.22"
          stroke={theme === "light" ? "#FF2F57" : "#00B0F0"}
          strokeWidth="2"
        />
      </svg>
    );
  };

  const renderBullets = () => {

    if(!open){
      return
    }

    let sections = sectionRefs.current;

    if (!sections) {
      return <></>;
    }

    let lastItem = sections[sections.length - 1];

    return sections.map(({ current }, index) => {
      if (!current?.id) {
        return <></>;
      }

      let currentPos = current?.offsetTop - 200;
      let nextPos = sections[index + 1]?.current?.offsetTop - 200;
      let active = isNaN(nextPos)
        ? scrollPosition >= currentPos
        : scrollPosition >= currentPos && scrollPosition < nextPos;
      let done = scrollPosition > nextPos;

      return (
        <>
          <div
            className={`_item-body-section ${active ? "_active" : ""}`}
            key={index}
            onClick={() => onClick(current?.id)}
          >
            <div
              className={`${
                theme === "light" && "_circle-small-dark"
              } _circle-small`}
            >
              {done ? (
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="10"
                    fill={theme === "light" ? "#FF2F57" : "#00B0F0"}
                    stroke={theme === "light" ? "#FF2F57" : "#00B0F0"}
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                renderCircle(active)
              )}
            </div>
            <div
              className={`_section-number ${
                theme === "light" && "_section-number-dark"
              }`}
            >
              {current?.id}
            </div>
          </div>
          {lastItem.current?.id !== current?.id && (
            <div
              className={`${
                theme === "light" && "_rect-small-dark"
              } _rect-small`}
            />
          )}
        </>
      );
    });
  };

  const renderChapters = () => {
    let prev = true;

    return chapters.map((chapter) => {
      if (chapter === chapterNumber) {
        prev = false;
      }

      let active = chapter === chapterNumber;

      return (
        <div
          className={`_item ${chapter === lastChapter ? "_last-chapter" : ""} position-relative`}
          key={chapter}
        >
          {renderOpenStateIcon(active)}
          <Link
            className={`_item-head ${active ? "_active" : ""}`}
            to={
              chapter === "Video" ? "/chapters/wrap-up" : `/chapters/${chapter}`
            }
            onClick={() => toggleOpen(active)}
          >
            <div className="_circle">
              {active || prev ? (
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="21"
                    cy="21"
                    r="20"
                    fill={theme === "light" ? "#FF2F57" : "#00B0F0"}
                    stroke={theme === "light" ? "#FF2F57" : "#00B0F0"}
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="21"
                    cy="21"
                    r="20"
                    fill="#ACACAC"
                    fillOpacity="0.3"
                    stroke="#6C6C6C"
                    strokeWidth="2"
                  />
                </svg>
              )}
            </div>
            <div
              className={`_chapter-number ${
                theme === "light" && "_chapter-number-dark"
              }`}
            >
              {chapter === "Video" ? chapter : "Ch " + chapter}
            </div>
          </Link>
          <div className={`_item-body ${active ? "_active-rect" : ""}`}>
            <div
              className={`${
                theme === "light" && "_rect-dark _react-main"
              } _rect`}
            />
            {chapter === chapterNumber && renderBullets(chapter)}
            <div
              className={`${
                theme === "light" && "_rect-dark _react-main"
              } _rect`}
            />
          </div>
        </div>
      );
    });
  };

  if (!data || !sectionRefs) {
    return <></>;
  }

  return <div className="_progress-bar">{renderChapters()}</div>;
}
