import React, { useContext } from "react";
import "./ToggleSwitch.scss";
import { ThemeContext } from "../../../Context/ThemeContext";

export const ToggleSwitch = (props) => {
  const { chapter } = props;
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = () => {
    const isCurrentDark = theme === "dark";
    setTheme(isCurrentDark ? "light" : "dark");
    localStorage.setItem("default-theme", isCurrentDark ? "light" : "dark");
  };
  return (
    <>
      <svg display="none">
        <symbol id="light" viewBox="0 0 24 24">
          <g stroke="currentColor" strokeWidth="2" strokeLinecap="round">
            <line x1="12" y1="17" x2="12" y2="20" transform="rotate(0,12,12)" />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(45,12,12)"
            />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(90,12,12)"
            />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(135,12,12)"
            />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(180,12,12)"
            />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(225,12,12)"
            />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(270,12,12)"
            />
            <line
              x1="12"
              y1="17"
              x2="12"
              y2="20"
              transform="rotate(315,12,12)"
            />
          </g>
          <circle fill="currentColor" cx="12" cy="12" r="5" />
        </symbol>
        <symbol id="dark" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M15.1,14.9c-3-0.5-5.5-3-6-6C8.8,7.1,9.1,5.4,9.9,4c0.4-0.8-0.4-1.7-1.2-1.4C4.6,4,1.8,7.9,2,12.5c0.2,5.1,4.4,9.3,9.5,9.5c4.5,0.2,8.5-2.6,9.9-6.6c0.3-0.8-0.6-1.7-1.4-1.2C18.6,14.9,16.9,15.2,15.1,14.9z"
          />
        </symbol>
      </svg>

      <div className="main">
        <div className="mainContainer">
          <svg display="none">
            <symbol id="light" viewBox="0 0 24 24">
              <g stroke="currentColor" strokeWidth="2" strokeLinecap="round">
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(0,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(45,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(90,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(135,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(180,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(225,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(270,12,12)"
                />
                <line
                  x1="12"
                  y1="17"
                  x2="12"
                  y2="20"
                  transform="rotate(315,12,12)"
                />
              </g>
              <circle fill="currentColor" cx="12" cy="12" r="5" />
            </symbol>
            <symbol id="dark" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M15.1,14.9c-3-0.5-5.5-3-6-6C8.8,7.1,9.1,5.4,9.9,4c0.4-0.8-0.4-1.7-1.2-1.4C4.6,4,1.8,7.9,2,12.5c0.2,5.1,4.4,9.3,9.5,9.5c4.5,0.2,8.5-2.6,9.9-6.6c0.3-0.8-0.6-1.7-1.4-1.2C18.6,14.9,16.9,15.2,15.1,14.9z"
              />
            </symbol>
          </svg>

          <label className="switch">
            <input
              className={`${
                chapter && theme === "light" && "switch__input_light"
              } switch__input`}
              type="checkbox"
              role="switch"
              name="dark"
              onChange={handleThemeChange}
              checked={theme === "dark"}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="68"
              height="68"
              viewBox="0 0 68 68"
              fill="none"
              className="switch__icon"
            >
              <circle
                cx="33.9999"
                cy="33.9999"
                r="10.9091"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                x1="33.333"
                y1="14"
                x2="33.333"
                y2="23.0909"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                x1="33.333"
                y1="44.9092"
                x2="33.333"
                y2="54.0001"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                x1="14"
                y1="33.9092"
                x2="23.0909"
                y2="33.9092"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                x1="44.9092"
                y1="33.9092"
                x2="54.0001"
                y2="33.9092"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                x1="41.1708"
                y1="26.3625"
                x2="47.5991"
                y2="19.9342"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                y1="-1"
                x2="9.09091"
                y2="-1"
                transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 25.8828 26.7917)"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                x1="19.6562"
                y1="47.903"
                x2="26.0844"
                y2="41.4748"
                stroke="#010D44"
                strokeWidth="2"
              />
              <line
                y1="-1"
                x2="9.09091"
                y2="-1"
                transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 47.7012 48.6101)"
                stroke="#010D44"
                strokeWidth="2"
              />
            </svg>

            {!chapter && theme === "light" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="82"
                height="81"
                viewBox="0 0 82 81"
                fill="none"
                className="switch__icon"
              >
                <g filter="url(#filter0_dd_1284_328)">
                  <mask id="path-1-inside-1_1284_328" fill="white">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M61.4768 46.3585C61.97 45.5276 61.0131 44.6435 60.1214 45.0156C57.7369 46.0104 55.1095 46.5614 52.3494 46.5614C41.4725 46.5614 32.655 38.0047 32.655 27.4495C32.655 23.9627 33.6172 20.694 35.298 17.8797C35.7946 17.0484 35.0545 15.9614 34.1557 16.3217C26.2371 19.4963 20.667 27.0453 20.667 35.8527C20.667 47.5242 30.4488 56.9859 42.5153 56.9859C50.6281 56.9859 57.7082 52.7087 61.4768 46.3585Z"
                    />
                  </mask>
                  <path
                    d="M60.1214 45.0156L59.3513 43.1698L60.1214 45.0156ZM59.3513 43.1698C57.2075 44.0642 54.8415 44.5614 52.3494 44.5614V48.5614C55.3774 48.5614 58.2664 47.9566 60.8915 46.8614L59.3513 43.1698ZM52.3494 44.5614C42.52 44.5614 34.655 36.844 34.655 27.4495H30.655C30.655 39.1655 40.4249 48.5614 52.3494 48.5614V44.5614ZM34.655 27.4495C34.655 24.3363 35.5129 21.4204 37.0151 18.9052L33.581 16.8542C31.7215 19.9675 30.655 23.5891 30.655 27.4495H34.655ZM22.667 35.8527C22.667 27.9214 27.6838 21.0711 34.8999 18.1781L33.4115 14.4653C24.7904 17.9216 18.667 26.1693 18.667 35.8527H22.667ZM42.5153 54.9859C31.4901 54.9859 22.667 46.3574 22.667 35.8527H18.667C18.667 48.691 29.4075 58.9859 42.5153 58.9859V54.9859ZM59.7569 45.3378C56.3451 51.0868 49.9153 54.9859 42.5153 54.9859V58.9859C51.3409 58.9859 59.0713 54.3306 63.1968 47.3792L59.7569 45.3378ZM37.0151 18.9052C37.7082 17.7448 37.5305 16.4089 36.8796 15.4942C36.1971 14.5352 34.8518 13.8879 33.4115 14.4653L34.8999 18.1781C34.6903 18.2622 34.4117 18.2828 34.1367 18.1889C33.8834 18.1023 33.7167 17.9485 33.6207 17.8135C33.4403 17.5601 33.3844 17.1833 33.581 16.8542L37.0151 18.9052ZM60.8915 46.8614C60.54 47.008 60.1665 46.9032 59.931 46.6765C59.8068 46.5568 59.6766 46.3631 59.629 46.0941C59.5775 45.8029 59.643 45.5298 59.7569 45.3378L63.1968 47.3792C63.9859 46.0494 63.561 44.619 62.7058 43.7954C61.8923 43.0119 60.5944 42.6511 59.3513 43.1698L60.8915 46.8614Z"
                    fill="#00B0F0"
                    mask="url(#path-1-inside-1_1284_328)"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_dd_1284_328"
                    x="0.666992"
                    y="0.250732"
                    width="80.9443"
                    height="80.7351"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.662745 0 0 0 0 0.894118 0 0 0 0 1 0 0 0 0.8 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1284_328"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.662745 0 0 0 0 0.894118 0 0 0 0 1 0 0 0 0.8 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect1_dropShadow_1284_328"
                      result="effect2_dropShadow_1284_328"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect2_dropShadow_1284_328"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            )}

            <span
              className={`${
                chapter && theme === "light" && "switch__inner_light"
              } switch__inner`}
            ></span>
            <span
              className={`switch__inner-icons ${
                chapter && theme === "light"
                  ? "switch__inner-icons theme-chapter"
                  : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="68"
                height="68"
                viewBox="0 0 68 68"
                fill="none"
                className="switch__icon"
              >
                <circle
                  cx="33.9999"
                  cy="33.9999"
                  r="10.9091"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  x1="33.333"
                  y1="14"
                  x2="33.333"
                  y2="23.0909"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  x1="33.333"
                  y1="44.9092"
                  x2="33.333"
                  y2="54.0001"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  x1="14"
                  y1="33.9092"
                  x2="23.0909"
                  y2="33.9092"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  x1="44.9092"
                  y1="33.9092"
                  x2="54.0001"
                  y2="33.9092"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  x1="41.1708"
                  y1="26.3625"
                  x2="47.5991"
                  y2="19.9342"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  y1="-1"
                  x2="9.09091"
                  y2="-1"
                  transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 25.8828 26.7917)"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  x1="19.6562"
                  y1="47.903"
                  x2="26.0844"
                  y2="41.4748"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
                <line
                  y1="-1"
                  x2="9.09091"
                  y2="-1"
                  transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 47.7012 48.6101)"
                  stroke={chapter && theme === "light" ? "#FF335C" : "#fff"}
                  strokeWidth="2"
                />
              </svg>
              {chapter && theme === "light" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="67"
                  height="68"
                  viewBox="0 0 67 68"
                  fill="none"
                  className="switch__icon ew"
                >
                  <g filter="url(#filter0_d_146_2350)">
                    <mask id="path-1-inside-1_146_2350" fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M53.8942 43.3629C54.386 42.531 53.4268 41.6478 52.5353 42.0208C50.1649 43.0123 47.5537 43.5614 44.8109 43.5614C33.9835 43.5614 25.2061 35.0047 25.2061 24.4496C25.2061 20.9622 26.1643 17.693 27.8379 14.8784C28.3325 14.0467 27.5916 12.9623 26.6941 13.3241C18.8145 16.5 13.2725 24.0475 13.2725 32.8527C13.2725 44.5242 23.0098 53.9859 35.0214 53.9859C43.0955 53.9859 50.142 49.7107 53.8942 43.3629Z"
                      />
                    </mask>
                    <path
                      d="M27.8379 14.8784L26.1189 13.8562L27.8379 14.8784ZM53.8942 43.3629L52.1725 42.3452L53.8942 43.3629ZM51.7634 40.1757C49.6341 41.0665 47.2849 41.5614 44.8109 41.5614V45.5614C47.8225 45.5614 50.6958 44.9582 53.3071 43.8658L51.7634 40.1757ZM44.8109 41.5614C35.0397 41.5614 27.2061 33.8525 27.2061 24.4496H23.2061C23.2061 36.157 32.9272 45.5614 44.8109 45.5614V41.5614ZM27.2061 24.4496C27.2061 21.334 28.0609 18.4164 29.557 15.9006L26.1189 13.8562C24.2676 16.9695 23.2061 20.5903 23.2061 24.4496H27.2061ZM15.2725 32.8527C15.2725 24.9179 20.2671 18.0709 27.4417 15.1791L25.9464 11.4691C17.3619 14.9292 11.2725 23.1771 11.2725 32.8527H15.2725ZM35.0214 51.9859C24.0598 51.9859 15.2725 43.3659 15.2725 32.8527H11.2725C11.2725 45.6826 21.9598 55.9859 35.0214 55.9859V51.9859ZM52.1725 42.3452C48.7752 48.0927 42.3785 51.9859 35.0214 51.9859V55.9859C43.8125 55.9859 51.5089 51.3287 55.6159 44.3806L52.1725 42.3452ZM29.557 15.9006C30.2472 14.7398 30.0681 13.4048 29.4155 12.4912C28.7311 11.533 27.3847 10.8893 25.9464 11.4691L27.4417 15.1791C27.2324 15.2634 26.9538 15.2848 26.6783 15.1913C26.4245 15.1051 26.2572 14.9514 26.1606 14.8161C25.9792 14.5622 25.9232 14.1852 26.1189 13.8562L29.557 15.9006ZM53.3071 43.8658C52.9559 44.0128 52.5832 43.9082 52.3484 43.6828C52.2244 43.5637 52.094 43.3707 52.0459 43.102C51.9939 42.811 52.0587 42.5377 52.1725 42.3452L55.6159 44.3806C56.4032 43.0487 55.9745 41.619 55.1185 40.7972C54.3042 40.0155 53.0062 39.6558 51.7634 40.1757L53.3071 43.8658Z"
                      fill="white"
                      mask="url(#path-1-inside-1_146_2350)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_146_2350"
                      x="8.27246"
                      y="12.2524"
                      width="50.7549"
                      height="50.7334"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.00392157 0 0 0 0 0.0235294 0 0 0 0 0.12549 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_146_2350"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_146_2350"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="82"
                  height="81"
                  viewBox="0 0 82 81"
                  fill="none"
                  className="switch__icon"
                >
                  <g filter="url(#filter0_dd_1284_328)">
                    <mask id="path-1-inside-1_1284_328" fill="white">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M61.4768 46.3585C61.97 45.5276 61.0131 44.6435 60.1214 45.0156C57.7369 46.0104 55.1095 46.5614 52.3494 46.5614C41.4725 46.5614 32.655 38.0047 32.655 27.4495C32.655 23.9627 33.6172 20.694 35.298 17.8797C35.7946 17.0484 35.0545 15.9614 34.1557 16.3217C26.2371 19.4963 20.667 27.0453 20.667 35.8527C20.667 47.5242 30.4488 56.9859 42.5153 56.9859C50.6281 56.9859 57.7082 52.7087 61.4768 46.3585Z"
                      />
                    </mask>
                    <path
                      d="M60.1214 45.0156L59.3513 43.1698L60.1214 45.0156ZM59.3513 43.1698C57.2075 44.0642 54.8415 44.5614 52.3494 44.5614V48.5614C55.3774 48.5614 58.2664 47.9566 60.8915 46.8614L59.3513 43.1698ZM52.3494 44.5614C42.52 44.5614 34.655 36.844 34.655 27.4495H30.655C30.655 39.1655 40.4249 48.5614 52.3494 48.5614V44.5614ZM34.655 27.4495C34.655 24.3363 35.5129 21.4204 37.0151 18.9052L33.581 16.8542C31.7215 19.9675 30.655 23.5891 30.655 27.4495H34.655ZM22.667 35.8527C22.667 27.9214 27.6838 21.0711 34.8999 18.1781L33.4115 14.4653C24.7904 17.9216 18.667 26.1693 18.667 35.8527H22.667ZM42.5153 54.9859C31.4901 54.9859 22.667 46.3574 22.667 35.8527H18.667C18.667 48.691 29.4075 58.9859 42.5153 58.9859V54.9859ZM59.7569 45.3378C56.3451 51.0868 49.9153 54.9859 42.5153 54.9859V58.9859C51.3409 58.9859 59.0713 54.3306 63.1968 47.3792L59.7569 45.3378ZM37.0151 18.9052C37.7082 17.7448 37.5305 16.4089 36.8796 15.4942C36.1971 14.5352 34.8518 13.8879 33.4115 14.4653L34.8999 18.1781C34.6903 18.2622 34.4117 18.2828 34.1367 18.1889C33.8834 18.1023 33.7167 17.9485 33.6207 17.8135C33.4403 17.5601 33.3844 17.1833 33.581 16.8542L37.0151 18.9052ZM60.8915 46.8614C60.54 47.008 60.1665 46.9032 59.931 46.6765C59.8068 46.5568 59.6766 46.3631 59.629 46.0941C59.5775 45.8029 59.643 45.5298 59.7569 45.3378L63.1968 47.3792C63.9859 46.0494 63.561 44.619 62.7058 43.7954C61.8923 43.0119 60.5944 42.6511 59.3513 43.1698L60.8915 46.8614Z"
                      fill="#00B0F0"
                      mask="url(#path-1-inside-1_1284_328)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_dd_1284_328"
                      x="0.666992"
                      y="0.250732"
                      width="80.9443"
                      height="80.7351"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.662745 0 0 0 0 0.894118 0 0 0 0 1 0 0 0 0.8 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1284_328"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="10" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.662745 0 0 0 0 0.894118 0 0 0 0 1 0 0 0 0.8 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_1284_328"
                        result="effect2_dropShadow_1284_328"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1284_328"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              )}
            </span>
            <span className="switch__sr">Dark Mode</span>
          </label>
        </div>
      </div>
    </>
  );
};
