import React from "react";
import style from "./CustomProgressBar.module.scss";
export const ProgressType = { flat: 0, rounded: 1 };

export default function CustomProgressBar({
  type,
  progressValue,
  min,
  max,
  progressValuePostFix,
}) {
  return (
    <React.Fragment>
      {ProgressType.flat === type && (
        <div className={`progress ${style.progress}`}>
          <div
            className={`progress-bar  ${style.progressBar}`}
            style={{ width: `${progressValue}%` }}
            aria-valuenow={progressValue}
            aria-valuemin={min || "0"}
            aria-valuemax={max || "100"}
          >
            <span className="ps-3">
              {progressValue}
              {progressValuePostFix === undefined
                ? "%"
                : progressValuePostFix || ""}
            </span>
          </div>
        </div>
      )}
      {ProgressType?.rounded === type && (
        <div
          className={style.progressSpinner}
          style={{
            background:
              "conic-gradient(rgba(87, 156, 220, 1) " +
              progressValue +
              "%, #6C6C6C " +
              progressValue +
              "%)",
          }}
        >
          <div className={style.middleCircle}>
            {progressValue}
            {progressValuePostFix === undefined
              ? "%"
              : progressValuePostFix || ""}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
