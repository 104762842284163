import React, { useEffect } from "react";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { Link, useNavigate } from "react-router-dom";
import style from "./PasswordResetSuccessfully.module.scss";
import Logo from "../../Assets/General/vw-logo.svg";

// Component: PasswordResetSuccessfully
const PasswordResetSuccessfully = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/sign-in");
    }, 5000);
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${style.forgotPasswordContainer} text-left`}>
          <div>
            <img
              src={Logo}
              alt="VW-logo"
              className={`${style.logoContainer}`}
            />
          </div>
          <div>
            <p className={`${style.header}`}>Password Reset!</p>
          </div>

          <div className={`${style.forgotPasswordSentence}`}>
            <p className={`${style.description1}`}>
              Your password has been successfully reset! You can sign in again,
              or wait to be redirected.
            </p>

            <p className={`${style.description2}`}>
              Redirecting you to the sign in page in: 5 Seconds
            </p>
          </div>

          <div>
            <Link to="/sign-in">
              <AppColorButton buttonName={BUTTON_CONSTANT.SKIP_TO_SIGN_IN} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetSuccessfully;
