import React, { useEffect, useState } from "react";
import style from "./ConfirmEmail.module.scss";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { AppColorButton, AppBorderButton } from "../AppButton/AppButton";
import { Link, Navigate } from "react-router-dom";
import Logo from "../../Assets/General/vw-logo.svg";
import apiService from "../../Utils/Services/ApiService";
import ErrorMessages from "../Messages/ErrorMessages";
import SuccessMessage from "../Messages/SuccessMessage";

// Component ConfirmEmail
const ConfirmEmail = () => {
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const [verified, setVerified] = useState(false);
  const [errorsInfo, setErrorsInfo] = useState(null);
  const [successInfo, setSuccessInfo] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("email")) {
      setEmail(searchParams.get("email"));
    }
    if (searchParams.has("token")) {
      setToken(searchParams.get("token"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      setProcessing(true);
      apiService
        .verifyAccount(email, token)
        .then((res) => {
          const { status } = res;
          if (status === 200) {
            setVerified(true);
            setProcessing(false);
          } else {
            setProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setProcessing(false);
        });
    }
  }, token);

  const resendEmail = () => {
    setProcessing(true);

    apiService
      .resendEmailVerificationEmail(email)
      .then((res) => {
        if (res.status === 200) {
          setSuccessInfo(res.message);
          setErrorsInfo(null);
          setProcessing(false);
        } else {
          setSuccessInfo(null);
          setErrorsInfo([res.message]);
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  if (verified) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <>
      <div className="container">
        <div className={`${style.confirmMailContainer}`}>
          <div>
            <img
              src={Logo}
              alt="VW-logo"
              className={`${style.logoContainer}`}
            />
          </div>
          <div className={`${style.header}`}>
            <p>Confirm</p>
            <p> Your E-Mail</p>
          </div>

          <div className={`${style.confirmMailSentence} d-flex `}>
            <div className={`${style.section1} `}>
              <p className={`${style.description1}`}>
                Thank you for joining Volkswagen Night School.
              </p>

              <p className={`${style.description2}`}>
                Your registration is almost complete, please check your email
                (including your spam folder) for a confirmation message from us.
              </p>
              <div className={`${style.resendBtn}`}>
                <AppColorButton
                  buttonName={
                    processing
                      ? BUTTON_CONSTANT.PROCESSING
                      : BUTTON_CONSTANT.RESEND
                  }
                  handleClick={resendEmail}
                  disabled={processing ? true : false}
                />
              </div>
              {errorsInfo && (
                <>
                  <br />
                  <ErrorMessages errors={errorsInfo} />
                </>
              )}
              {successInfo && (
                <>
                  <br />
                  <SuccessMessage message={successInfo} />
                </>
              )}
            </div>
            <div className={`${style.section2} `}>
              <p className={`${style.description3}`}>
                Need help? Our team is here for you.
              </p>
              <div className={`${style.contactBtn}`}>
                <Link to="https://www.vw.co.za/en/help-and-support.html">
                  <AppBorderButton buttonName={BUTTON_CONSTANT.CONTACT_US} />
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="d-flex justify-content-center">
            <div>
              <div className="pt-30 pb-30">
                <p className="text-center">Email has been resend.</p>

                <Link to="/reset-password-success">
                  <AppColorButton buttonName={BUTTON_CONSTANT.RESEND_EMAIL} />
                </Link>
              </div>

              <div className="pt-30 pb-30">
                <p>
                  If you have any questions or concerns, please don't hesitate
                  to reach out to us.
                </p>
              </div>

              <div>
                <AppWhiteButton
                  buttonName={BUTTON_CONSTANT.CONTACT_US}
                ></AppWhiteButton>
              </div>

              <div className="pt-30 pb-30">
                <p>See you on the track!</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default ConfirmEmail;
