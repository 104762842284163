import React from "react";
import style from "./HomeHeader.module.scss";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import ScrollMore from "../../Assets/General/scroll-more.svg";
import HeaderImage from "../../Assets/Home/HeaderImage_Updated_22Nov.png";
import HeaderImageMobile from "../../Assets/Home/main-header-mobile-image.png";
import { useNavigate } from "react-router-dom";
import smoothScrollToSection from "../../smoothScrollToSection";

// Component: HomeHeader
const HomeHeader = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem(btoa("token"));

  return (
    <>
      <div className={style.headerContainer}>
        <div className={style.headerImageContainer}>
          <img
            src={HeaderImage}
            className={style.homeHeaderImage}
            alt="mainHeaderImage"
          />
        </div>

        <div className={style.headerImageContainerMobile}>
          <img
            src={HeaderImageMobile}
            className={style.homeHeaderMobileImage}
            alt="HeaderImageMobile"
          />
        </div>

        <div className="container">
          <div className={style.nightSchoolTitle}>
            <div>
              <div className={`${style.heading} mb-1`}>
                <h1 className="m-0 text-center">
                  <span className={`${style.firstTitle} mb-1 d-block`}>Introducing</span>
                  <span className={`${style.secondTitle}  d-block`}>Volkswagen Night School</span>
                </h1>
              </div>

              <div className="d-flex justify-content-center">
                <div className={style.descriptionContainer}>
                  <p className={style.description}>
                    An exclusive open-source course to teach you the skills you
                    need to become a confident night-time driver.
                  </p>
                </div>
              </div>

              <div
                className="text-center"
                style={{
                  position: "relative",
                  zIndex: "1000",
                }}
              >
                <AppColorButton
                  buttonName={BUTTON_CONSTANT.ENROLL_NOW}
                  handleClick={() => {
                    navigate(token ? "/chapters/1" : "/sign-in");
                  }}
                />
                <div className="pt-30 pb-30">
                  <img 
                    src={ScrollMore} 
                    alt="ScrollMore" 
                    onClick={() => smoothScrollToSection("section-2", 100)}
                    style={{cursor: 'pointer'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeHeader;
