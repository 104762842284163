import React from "react";
import style from "./AboutVolkswagen.module.scss";
import { BUTTON_CONSTANT, CARD_CONSTANT } from "../../Utils/Helper/Constants";
import AboutHeader from "./AboutHeader";
import DottedImage from "../../Assets/General/dotted-grid.png";
import CarLeftSeatImg from "../../Assets/About/car-left-seat.jpg";
import GetCertified from "../../Assets/About/get-certified.jpg";
import AviRichardsUnsplashMobile from "../../Assets/About/avi-richards-mobile.png";
import { AppColorButton } from "../AppButton/AppButton";
import { useNavigate, Link } from "react-router-dom";

// Component AboutVolkswagen
const AboutVolkswagen = () => {
  const token = localStorage.getItem(btoa("token"));
  const navigate = useNavigate();

  return (
    <div className="overflow-hidden">
      <AboutHeader />
      <div className={style.dottedImageContainer}>
        <div>
          <img
            src={DottedImage}
            alt="DottedImage"
            className={style.dottedImage}
          />
        </div>

        <div>
          <p className={style.homeRedirect}>
            <Link to="/home">Home</Link> &gt; <Link to="/about">About</Link>
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-center container" id="section-2">
        <div className={`${style.mainContainer}`}>
          <div>
            <div>
              <div className="text-center mt-4 mt-sm-0">
                <h2 className={style.containerTitle}>
                  The{" "}
                  <span className={style.containerTitleBold}>
                    Night School Experience
                  </span>
                </h2>
              </div>

              <div>
                <p className={`${style.experienceSubTitle} my-5 my-sm-4`}>
                  Learn about the world of safe night-time driving through our
                  experts. Our curriculum covers a variety of chapters that will
                  assist you to navigate South African roads at night.
                </p>
              </div>
            </div>

            <div>
              {/* <iframe
                className={`videoYT ${style.introVideo}`}
                title="youtube"
                width="100%"
                src="https://www.youtube.com/embed/86Hfgt-inLk?si=rQceVX-b7tCjYl0G"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe> */}
              <video
                  className={`videoYT ${style.introVideo}`}
                  width="100%"
                  loop
                  controls
                  // autoPlay={"autoplay"}
                  preload="auto"
                >
                  <source src="https://nightschool-api.vw.co.za/videos/VW Night School – Intro.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="row m-0 p-0 ">
            <div className={`${style.aboutSection} col-12`}>
              <div className={`${style.containerTitle}`}>
                About{" "}
                <span className={style.containerTitleBold}>Night School</span>
              </div>
            </div>
            <div className="row m-0 p-0 justify-content-between">
              <div className="col-12 col-md-6  order-1 order-md-12">
                <div className={style.containerSubTitleAbout}>
                  Volkswagen Night School is South Africa’s very first
                  interactive night-time driving curriculum that is aimed at
                  educating South Africans on how to drive safely at night.
                  <br />
                  <br />
                  The course consists of 6 chapters covering factors such as
                  visibility, fatigue, safety, ability, weather conditions and
                  other factors that will empower you to be a better night-time
                  driver.
                  <br />
                  <br />
                  At the end of each chapter, you will get a test, when you pass
                  the test, you will receive incentives. Once you complete the
                  course, you will be rewarded with a certified night-time
                  driver certificate.
                  <br />
                  <br />
                  Ready to drive like a pro at night?
                </div>

                <div className="mt-30">
                  <AppColorButton
                    buttonName={BUTTON_CONSTANT.ENROLL_NOW}
                    handleClick={() => {
                      navigate(token ? "/chapters/1" : "/signup");
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 order-12 order-md-1 mb-4">
                <img
                  src={CarLeftSeatImg}
                  alt="WelcomeSectionImg"
                  className="w-100 rounded-5"
                />
              </div>
            </div>
          </div>

          <div>
            <div className={`pt-4 ${style.factSection} text-center`}>
              <div className={`${style.containerTitle} p-0 m-0`}>
                Interesting Facts
                <br className="d-block d-md-none" /> About
                <br />{" "}
                <span className={style.containerTitleBold}>
                  Driving At Night
                </span>
              </div>

              <div>
                <p className={`${style.curriculumSubTitle} py-4 py-sm-5`}>
                  Learn the difference in driving dynamics between day and night
                  and understand why a specialised approach to night-time
                  driving is essential in making South African roads safer for
                  all. In South Africa, many factors contribute to challenges
                  and risks on our roads during the night, such as:
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div className={`${style.enrollIconSection} row m-0 `}>
                {CARD_CONSTANT.ABOUT_ENROLL_SMALL_CARD_LIST.map(
                  (item, index) => (
                    <div
                      className={`${style.EnrollSmallCard} m-0 p-3 col-12 col-md-4`}
                      key={item.title + index}
                    >
                      <div className={style.enrollIcons}>
                        <img
                          className={
                            index === 2
                              ? `${style.icon3}`
                              : index === 0
                              ? `${style.icon1}`
                              : index === 1
                              ? `${style.icon2}`
                              : `${style.icons} `
                          }
                          src={item?.image}
                          alt="Icons"
                        />
                      </div>
                      <div>
                        <div className="pt-0 pb-3 pt-sm-2 pb-sm-4">
                          <h5 className={style.enrollIconTitle}>
                            {item?.title}
                          </h5>
                          <h5 className={style.enrollIconTitle}>
                            {item?.title2}
                          </h5>
                        </div>

                        <p className={`${style.enrollIconDescription}`}>
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="mt-50 mt-md-100 text-center">
              <AppColorButton
                buttonName={BUTTON_CONSTANT.ENROLL_NOW}
                handleClick={() => {
                  navigate(token ? "/chapters/1" : "/signup");
                }}
              />
            </div>
          </div>

          <div className="mt-md-100">
            <div className="mt-50">
              <h2 className={`${style.containerTitle} pt-30 text-center`}>
                The <span className={style.containerTitleBold}>Curriculum</span>
              </h2>
            </div>

            <div className="my-5 my-sm-4">
              <p className={style.curriculumSubTitle}>
                Our open-source curriculum is carefully crafted by Volkswagen’s
                experienced defensive driving experts. Learn practical tips and
                techniques to give you the confidence and skills you need to
                navigate South African roads at night.
              </p>
            </div>
          </div>

        </div>{" "}
      </div>

      <div>
        <div className={`${style.bottomBanner} pb-md-5 mb-md-5`}>
          <div>
            <img
              src={DottedImage}
              alt="DottedImage"
              className={style.dottedBottomImage}
            />
          </div>

          <div className={style.imageContainer}>
            <img
              src={GetCertified}
              alt="GetCertified"
              className={style.TouchPadImage}
            />
          </div>

          <div className={style.imageContainerMobile}>
            <img
              src={AviRichardsUnsplashMobile}
              alt="AviRichardsUnsplashMobile"
              className={style.TouchPadMobileImage}
            />
          </div>

          <div className={style.bottomBannerContainer}>
            <div>
              <h2 className={style.bottomBannerSloganHeading}>Get certified</h2>
            </div>

            <div className={style.bottomBannerSloganDescriptionContainer}>
              <p className={style.bottomBannerSloganDescription}>
                Complete and pass the test at the end of each chapter to earn
                your Night Driver Certificate after completing the whole course.
              </p>
            </div>

            <div className="mt-30">
              <AppColorButton
                buttonName={BUTTON_CONSTANT.ENROLL_NOW}
                arrow={true}
                handleClick={() => {
                  navigate(token ? "/chapters/1" : "/signup");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutVolkswagen;
