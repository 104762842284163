import Home from "./Pages/Home/Home";
import Embark from "./Pages/Embark/Embark";
import About from "./Pages/About/About";
import Login from "./Pages/Login/Login";
import Chapter from "./Pages/Chapters/Chapter";
import Results from "./Pages/Chapters/Results";
import ResetPassword from "./Components/Auth/ResetPassword";
import UserProfile from "./Components/UserProfile/UserProfile";
import ConfirmEmail from "./Components/Auth/ConfirmEmail";
import ResetPasswordResendMail from "./Components/Auth/ResetPasswordResendMail";
import PasswordResetSuccessfully from "./Components/Auth/PasswordResetSuccessfully";
import ConfirmResetPassword from "./Components/Auth/ConfirmResetPassword";
import ResetPasswordCodeCheck from "./Components/Auth/ResetPasswordCodeCheck";
import TermsConditions from "./Components/TermsandConditions/TermsConditions";
import Signup from "./Components/Auth/Signup";
import QuizSummary from "./Components/BeforeWeDrive/QuizSummerry";
import Error404 from "./Components/Error/Error404/Error404";
import Error500 from "./Components/Error/Error500/Error500";

// component routes
const routes = [
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/home",
    element: <Home />,
  },

  {
    path: "/about",
    element: <About />,
  },

  {
    path: "/embark",
    element: <Embark />,
  },

  {
    path: "/sign-in",
    element: <Login />,
  },

  {
    path: "/signup",
    element: <Signup />,
  },

  {
    path: "/reset-password",
    element: <ResetPassword />,
  },

  {
    path: "/reset-password-verify",
    element: <ResetPasswordCodeCheck />,
  },

  {
    path: "/user-profile",
    element: <UserProfile />,
  },

  {
    path: "/confirm-mail",
    element: <ConfirmEmail />,
  },

  {
    path: "/reset-password-confirm-mail",
    element: <ResetPasswordResendMail />,
  },

  {
    path: "/reset-password-success",
    element: <PasswordResetSuccessfully />,
  },

  {
    path: "/confirm-reset-password",
    element: <ConfirmResetPassword />,
  },

  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },

  {
    path: "/test-summary",
    element: <QuizSummary />,
  },
  {
    path: `chapters/:chapterNumber`,
    element: <Chapter />,
  },
  {
    path: `chapters/:chapterNumber/results`,
    element: <Results />,
  },
  {
    path: "/404",
    element: <Error404 />,
  },
  {
    path: "/500",
    element: <Error500 />,
  },
];

export default routes;
