import React, { useState, useEffect, useContext } from "react";
import style from "./ChapterHero.module.scss";
import Logo from "../../Assets/General/night-school-led-logo2.png";
import ScrollMore from "../../Assets/General/scroll-more.svg";
import smoothScrollToSection from "../../smoothScrollToSection";
import { ToggleSwitch } from "../Shared/ToggleSwitch/ToggleSwitch";
import { ThemeContext } from "../../Context/ThemeContext";

// Component: ChapterHero
const ChapterHero = ({ number, heading }) => {
  const [banner, setBanner] = useState(null);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    switch (String(number).toLowerCase()) {
      case "1":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter1/hero-banner-chapter-1-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter1/hero-banner-chapter-1-widescreen.jpg"),
        });
        break;
      case "2":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter2/hero-banner-chapter-2-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter2/hero-banner-chapter-2-widescreen2.jpg"),
        });
        break;
      case "3":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter3/hero-banner-chapter-3-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter3/hero-banner-chapter-3-widescreen.jpg"),
        });
        break;
      case "4":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter4/hero-banner-chapter-4-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter4/hero-banner-chapter-4-widescreen.jpg"),
        });
        break;
      case "5":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter5/hero-banner-chapter-5-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter5/hero-banner-chapter-5-widescreen.jpg"),
        });
        break;
      case "6":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter6/hero-banner-chapter-6-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter6/hero-banner-chapter-6-widescreen.jpg"),
        });
        break;
      // new add
      case "wrap-up":
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter6/hero-banner-chapter-6-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter6/wrapUp-6-header.jpg"),
        });
        break;

      default:
        setBanner({
          mobile: require("../../Assets/Chapters/Chapter1/hero-banner-chapter-1-mobile.jpg"),
          widescreen: require("../../Assets/Chapters/Chapter1/hero-banner-chapter-1-widescreen.jpg"),
        });
        break;
    }
  }, [number]);

  return (
    <>
      <div className={style.headerContainer}>
        <div className={style.headerImageContainer}>
          {banner && (
            <img
              src={banner.widescreen}
              className={style.homeHeaderImage}
              alt="mainHeaderImage"
            />
          )}
        </div>

        <div className={style.headerImageContainerMobile}>
          {banner && (
            <img
              src={banner.mobile}
              className={style.homeHeaderMobileImage}
              alt="HeaderImageMobile"
            />
          )}
        </div>

        <div className="container">
          <div className={style.nightSchoolLedLogo}>
            <img
              src={Logo}
              alt="nightSchoolLedLogo"
              className={style.logoImage}
            />
          </div>

          <div className={style.nightSchoolTitle}>
            <div>
              <div>
                <h2 className={style.firstTitle}>
                  {isNaN(Number(String(number)))
                    ? String(number).toLowerCase() === "wrap-up"
                      ? "Wrap-Up"
                      : number
                    : `CHAPTER ${number}`}
                </h2>
              </div>

              <div className="d-flex justify-content-center">
                <div className={style.descriptionContainer}>
                  <p className={style.description}>
                    {isNaN(Number(String(number)))
                      ? String(number).toLowerCase() === "wrap-up"
                        ? "End of Curriculum"
                        : ""
                      : heading}
                  </p>
                </div>
              </div>

              <div className="text-center">
                <div className={style.scrollMoreIconContainer}>
                  <img
                    src={ScrollMore}
                    alt="ScrollMore"
                    className={style.scrollMoreIcon}
                    onClick={() => smoothScrollToSection("section-2", 100)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.toggleButton}>
          <ToggleSwitch chapter={true} />
        </div>
      </div>
    </>
  );
};
export default ChapterHero;
