import React, { useState } from "react";
import {
  BUTTON_CONSTANT,
  CARD_CONSTANT,
  HOME_CHAPTERS_BUTTON,
  HOME_CHAPTERS_CARDS,
  HOME_CHAPTERS_CARDS_HEADING,
} from "../../Utils/Helper/Constants";
import style from "./HomeWhatYouLearnCard.module.scss";
import { AppColorButton } from "../AppButton/AppButton";
import Slider from "react-slick";
import DottedImage from "../../Assets/General/dotted-grid.png";
import BasicsIcon from "../../Assets/Home/stroke-on-dark-blue-hat.svg";
import VideoIcon from "../../Assets/Home/stroke-on-dark-blue-video.svg";
import ClockIcon from "../../Assets/Home/stroke-on-dark-blue-clock.svg";
import RoundArrow from "../../Assets/General/stroke-on-dark-arrow-right.svg";
import LightRoundArrow from "../../Assets/General/light-arrow.png";
import ConfidentNightTimeDriverImage from "../../Assets/Home/bottom-image.jpg";
import ConfidentNightTimeDriverMobileImage from "../../Assets/Home/bottom-mobile-image.png";
import "../../Style/common.scss";
import { Link, useNavigate } from "react-router-dom";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "30%",
  slidesToShow: 1,
  speed: 500,
  swipeToSlide: true,
  focusOnSelect: true,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        centerPadding: "10%",
      },
    },
  ],
};

const chapterSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  nextArrow: <TfiAngleRight />,
  prevArrow: <TfiAngleLeft />,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
      },
    },
  ],
};

// Component: HomeWhatYouLearnCard
const HomeWhatYouLearnCard = () => {
  const [selectedStep, setSelectedStep] = useState(0);
  const navigate = useNavigate();

  const token = localStorage.getItem(btoa("token"));

  const handleStepClick = (stepIndex) => {
    setSelectedStep(stepIndex);
  };

  const handleLessonClick = (chapter, lesson) => {
    if (token) {
      navigate(`/chapters/${chapter}#${lesson}`);
    } else {
      navigate(`/signup`);
    }
  };

  return (
    <>
      <div className={`${style.whatYouWillLearnContainer} container`}>
        <div className="d-flex justify-content-center">
          <div className={style.whatYouWillLearnHeader}>
            <div>
              <h2 className={style.whatYouWillLearnHeading}>
                What You <br className="d-md-none" />
                Will Learn
              </h2>
            </div>

            <div className="d-flex justify-content-center mt-30 mb-30">
              <div className={style.whatYouWillLearnDesContainer}>
                <p
                  className={`${style.whatYouWillLearnDes} ${style.fontFamilyLigth}`}
                >
                  A comprehensive course with a chapter for every turn that you
                  may take.
                </p>
              </div>
            </div>

            <div className={`vw-chapters-slider ${style.chapters}`}>
              <Slider {...chapterSettings}>
                {HOME_CHAPTERS_BUTTON.CHAPTERS.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center py-4 px-2"
                  >
                    <p
                      className={`${
                        selectedStep === index
                          ? style.buttonBlue
                          : style.buttonWhite
                      } cursor-pointer ${style.chapterButton} fontWeight`}
                      onClick={() => handleStepClick(index)}
                    >
                      {item?.title}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <p className={style.whiteBorder}></p>

      <div
        className={`${style.whatYouWillLearnContainer} pt-0 container ${style.changeChapter}`}
      >
        <div className="d-flex justify-content-center">
          <div className={style.innerContainer}>
            {HOME_CHAPTERS_CARDS_HEADING[`CHAPTERS${selectedStep + 1}`].map(
              (item, index) => (
                <div key={index}>
                  <div className={style.chapterHeading}>
                    <h2 className={style.firstHeading}>
                      Welcome to chapter {item?.heading}
                    </h2>
                    <h2 className={style.secondHeading}>
                      of Volkswagen Night School
                    </h2>
                  </div>

                  <div className={style.chapterHeadingMobile}>
                    <h2 className={style.firstHeading}>
                      Welcome to chapter {item?.heading}
                      <span className={style.secondHeading}>
                        {" "}
                        of Volkswagen Night School
                      </span>
                    </h2>
                  </div>

                  <div>
                    <div className={style.description}>
                      <span
                        className={`${style.boldDes} ${style.fontFamilyBold}`}
                      >
                        {item?.boldInfo}
                      </span>{" "}
                      <span className={style.normalDes}>{item?.info}</span>
                    </div>
                  </div>
                </div>
              )
            )}

            <div className="row gap-5 m-0 justify-content-center">
              {HOME_CHAPTERS_CARDS[`CHAPTERS${selectedStep + 1}`].map(
                (item, index) => (
                  <div
                    className={`${style.chapterIntroCard} col-12`}
                    key={index}
                  >
                    <div>
                      <img
                        src={item?.image}
                        alt="CardImage"
                        className={style.chapterCardImage}
                      />
                    </div>

                    <div className={style.chapterDetails}>
                      <div className={style.cardLesson}>
                        <p>
                          {item?.lesson}{" "}
                          <span className={style.cardTotalLesson}>
                            {item?.totalLesson}
                          </span>
                        </p>
                      </div>

                      <div className={style.cardTitle}>
                        <p>{item?.title}</p>
                      </div>

                      <div className={style.cardName}>
                        <p className="m-0">{item?.name}</p>
                      </div>

                      <div className={style.cardDescription}>
                        <p>{item?.description}</p>
                      </div>

                      <div
                        className={`${style.cardBottomNavigation} d-flex justify-content-between align-items-center pt-10`}
                      >
                        <div>
                          {item?.basics && (
                            <span>
                              <img
                                src={BasicsIcon}
                                alt="BasicsIcon"
                                className={style.cardIcons}
                              />
                              <span className={style.iconName}>
                                {item?.basics}
                              </span>
                            </span>
                          )}

                          {item?.min && (
                            <span className="ml-16">
                              <img
                                src={ClockIcon}
                                alt="BasicsIcon"
                                className={style.cardIcons}
                              />
                              <span className={style.iconName}>
                                {item?.min}
                              </span>
                            </span>
                          )}

                          {item?.video && (
                            <span className="ml-16">
                              <img
                                src={VideoIcon}
                                alt="BasicsIcon"
                                className={style.cardIcons}
                              />
                              <span className={style.iconName}>
                                {item?.video}
                              </span>
                            </span>
                          )}
                        </div>

                        <div
                          onClick={() =>
                            handleLessonClick(selectedStep + 1, item?.number)
                          }
                          className={`${style.imageWrapper} ${style.roundArrowShadow}`}
                        >
                          <img
                            src={RoundArrow}
                            alt="RoundArrow"
                            className={style.roundArrowIcon}
                          />

                          <img
                            src={LightRoundArrow}
                            alt="RoundArrow"
                            className={style.lightArrow}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="text-center pt-50 pb-50">
              <Link to={token ? `/chapters/${selectedStep + 1}` : "/signup"}>
                <AppColorButton buttonName={BUTTON_CONSTANT.ENROLL_NOW} />
              </Link>
            </div>

            <div className="text-center mt-100">
              <div>
                <h2 className={style.volkswagenSafetySlogan}>Volkswagen</h2>
                <h2 className={style.volkswagenYearSafetySlogan}>
                  Year Of Safety
                </h2>
              </div>

              <div className="pt-30 pb-30">
                <p className={style.volkswagenSafetySloganDescription}>
                  Join our commitment to ensuring safety on South African roads
                  by enrolling in Night School.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={style.grayBorderContainer}>
          <hr className={style.grayBorder} />
        </div>

        <div className="d-flex justify-content-center">
          <div className={style.innerContainer}>
            <div className={style.headingContainer}>
              {/* <div className="gradientContainer">
                <p className="gradientBackground"></p>
              </div> */}

              <h2 className={style.VWTechSlogan}>The Future Of Driving With</h2>
              <h2 className={style.VWTechSlogan}>
                {" "}
                <span className={style.VWTechBoldSlogan}>Volkswagen Tech</span>
              </h2>

              <p className={style.VWTechSloganDescription}>
                Discover how Volkswagen is steering the way towards autonomous
                driving, convenience and security with our advance systems and
                intelligent in-car technologies.
              </p>
            </div>
          </div>
        </div>

        <div className="vw-tech-slider">
          <Slider {...settings}>
            {CARD_CONSTANT.HOME_CARD_LIST.map((items, index) => (
              <div key={index}>
                <div
                  className={`mt-20 mb-20 car-tech-card ${style.carTechCard}`}
                >
                  <div
                    className={`car-tech-card-wrap ${style.scrollCardSecond}`}
                  >
                    <div className={style.carTechCardImgWrap}>
                      <img
                        src={items?.image}
                        alt="CardImage"
                        className={style.scrollCardImage}
                      />
                    </div>

                    <div className={`${style.scrollCardDetails} card-details`}>
                      <h3 className={style.scrollCardHeading}>
                        {items?.heading}
                      </h3>
                      <p
                        className={`${style.scrollCardDescription} car-tech-card-text`}
                      >
                        {items?.description}
                      </p>
                    </div>
                    <a href={items?.link} target="_blank" rel="noReferrer">
                      <div
                        className={`car-tech-arrow ${style.scrollCardRoundArrow} ${style.imageWrapper}`}
                      >
                        <img
                          src={RoundArrow}
                          alt="RoundArrow"
                          className={style.roundAndArrowImage}
                        />
                        <img
                          src={LightRoundArrow}
                          alt="RoundArrow"
                          className={style.lightArrow}
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div>
        <img
          src={DottedImage}
          alt="DottedImage"
          className={style.dottedImage}
        />
      </div>

      <div>
        <div className={style.bottomBanner}>
          <div className={style.imageContainer}>
            <img
              src={ConfidentNightTimeDriverImage}
              alt="ConfidentNightTimeDriverImage"
              className={style.bottomBannerImage}
            />
          </div>

          <div className={style.imageContainerMobile}>
            <img
              src={ConfidentNightTimeDriverMobileImage}
              alt="ConfidentNightTimeDriverMobileImage"
              className={`${style.bottomBannerMobileImage} mt-3`}
            />
          </div>

          <div className={style.bottomBannerSlogan}>
            <div>
              <h2 className={style.bottomBannerSloganHeading}>
                Become A Confident <br className="d-md-block d-none" />
                Night-Time Driver
              </h2>

              {/* <h2 className={style.bottomBannerSloganHeading}>
                        Night-Time Driver
                     </h2> */}
            </div>

            <div className={style.bottomBannerSloganDescriptionContainer}>
              <p className={style.bottomBannerSloganDescription}>
                Embark on a journey towards becoming a more skilled and
                confident night-time driver. Take control of the night, learn
                from leading experts, and become a pro at navigating the South
                African driving experience confidently. Put the power back into
                your steering with Night School and let's make the roads safer
                for all.
              </p>
            </div>

            <div className={style.bottomBannerEnrolBtn}>
              <AppColorButton
                buttonName={BUTTON_CONSTANT.ENROLL_NOW}
                handleClick={() => {
                  navigate(token ? "/chapters/1" : "/sign-in");
                  //navigate(token ? "/chapters/1" : `${window.location.replace('https://stage-nightschool.vw.co.za/sign-in')}`);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeWhatYouLearnCard;
