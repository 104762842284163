import React from "react";
import style from "./Error500.module.scss";
import { AppColorButton } from "../../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../../Utils/Helper/Constants";
import E5 from "../../../Assets/Error/500_icon.svg";
import { Link } from "react-router-dom";

const Error500 = () => {
  return (
    <>
      <div className="container">
        <div className={`${style.mainContainer} text-center`}>
          {/* image icon  */}
          <div className={style.iconContainer}>
            <img src={E5} alt="Error500icon" className={style.logo500} />
          </div>
          {/* 500 error text */}
          <div className={`${style.errorContainer} text-center `}>
            <p className={style.firstLine}>500</p>
            <p className={style.secondLine}>ERROR</p>
          </div>
          {/* off-road container */}
          <div className={style.offContainer}>
            <p className={style.offRoad}>Off-Road!</p>

            <div className="d-flex justify-content-center">
              <p className={style.description}>
                You didn't break the internet, but our servers are trying to
                keep up with you. We are working on fixing the problem to get
                you back on track.
              </p>
            </div>

            <div className={`${style.contactBtn} text-center `}>
              <Link to="https://www.vw.co.za/en/help-and-support.html">
                <AppColorButton buttonName={BUTTON_CONSTANT.CONTACT_US} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error500;
