import React from "react";
import { AppColorButton, AppBorderButton } from "../AppButton/AppButton";
import {
  BEFORE_WE_DRIVE_WARNING,
  BUTTON_CONSTANT,
} from "../../Utils/Helper/Constants";
import style from "./QuizSummerry.module.scss";

// Component: QuizSummary
const QuizSummary = () => {
  return (
    <>
      <div className="container">
        <div className="row pt-30 pb-30">
          <div className="col-12 col-lg-4"></div>

          <div className="col-12 col-lg-8">
            <div className="pt-30 pb-30">
              <div className="">
                <h2>Chapter 1: Test summary</h2>
              </div>

              <div>
                <h5 className="pt-30 pb-30">You passed!</h5>
                <p>
                  Your score is 8/10. You passed Chapter 1: Before you drive.
                </p>
              </div>

              <div className={`${style.questionRow} row`}>
                {BEFORE_WE_DRIVE_WARNING.QUIZ_SUMMARY.map((item, index) => (
                  <div className="col-12 col-md-6" key={item.question + index}>
                    <p
                      className={`${
                        item.correctAnswer
                          ? style.quizSummaryRedQuestion
                          : style.quizSummaryGreenQuestion
                      } font-weight-500 font-size-18`}
                    >
                      {item?.question}
                    </p>
                    <p className="font-weight-500">{item?.correctAnswer}</p>
                    <p>{item?.answer}</p>
                  </div>
                ))}
              </div>

              <div className="row row-cols-2 row-cols-lg-4">
                <div className="col-12 col-sm-4">
                  <AppColorButton buttonName={BUTTON_CONSTANT.NEXT_CHAPTER} />
                </div>

                <div className="col-12 col-sm-4">
                  <AppBorderButton buttonName={BUTTON_CONSTANT.RETAKE_TEST} />
                </div>

                <div className="col-12 col-sm-4">
                  <AppBorderButton
                    buttonName={BUTTON_CONSTANT.VIEW_CERTIFICATE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuizSummary;
