import React from "react";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { EmbarkAndAboutHeader } from "../Shared/EmbarkAndAboutHeader/EmbarkAndAboutHeader";
import HeaderImage from "../../Assets/About/main-header-image-about.png";
import HeaderImageMobile from "../../Assets/About/main-header-mobile-image-about.png";

// Component AboutFooterBanner
const AboutHeader = () => {
  const token = localStorage.getItem(btoa("token"));

  return (
    <>
      <EmbarkAndAboutHeader
        image={HeaderImage}
        mobileImage={HeaderImageMobile}
        title={"About Volkswagen"}
        mobileTitle={"About"}
        mobileTitle2={"Volkswagen"}
        description={
          "As the people’s car, we’re on a mission to develop solutions that help make the roads safer for everyone. Get ready to master the road after dark with Volkswagen Night School."
        }
        firstButton={BUTTON_CONSTANT.ENROLL_NOW}
        secondButton={
          token ? BUTTON_CONSTANT.PROFILE : BUTTON_CONSTANT.LOGIN_IN
        }
        token={token}
        flag={true}
      />
    </>
  );
};
export default AboutHeader;
