import React from "react";
import LoginForm from "../../Components/Auth/LoginForm";

// Component: Login
const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};
export default Login;
