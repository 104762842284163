import React from "react";
import style from "./Signup.module.scss";
import Logo from "../../Assets/General/vw-logo.svg";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { AppBorderButton } from "../AppButton/AppButton";
import vwDarkLogo from "../../Assets/General/DarkVector.svg";
import SignupForm from "./SignupForm";
import { userTokenMethod } from "../../Utils/Services/Common.Services";
import {Navigate} from 'react-router-dom'

const Signup = () => {

  if (userTokenMethod("get")) {
    return <Navigate to="/user-profile" />;
  }

  return (
    <div className="container">
      <div className={style.mainContainer}>
        {/* logo */}

        <div
          className={`${style.logoContainer} d-block d-md-flex  justify-content-between `}
        >
          <img src={Logo} alt="VW-Logo" className={style.logoIcon} />
          <div className="pt-30 pt-md-0">
            <AppBorderButton>
              <div className="align-items-center d-flex justify-content-center px-4 ">
                <span className="order-2 order-md-1">
                  {BUTTON_CONSTANT.VW_SING_IN}
                </span>
                <a href="https://www.vw.co.za/en.html">
                  <img
                    src={vwDarkLogo}
                    alt=""
                    className={`${style.btnIcon} order-1 order-md-2`}
                  />
                </a>
              </div>
            </AppBorderButton>
          </div>
        </div>
        
        <SignupForm page="registration" />
      </div>
    </div>
  );
};

export default Signup;
