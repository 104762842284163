import React from "react";
import HeaderImage from "../../Assets/Embark/main-header-image.png";
import HeaderImageMobile from "../../Assets/Embark/main-header-mobile-image.png";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { EmbarkAndAboutHeader } from "../Shared/EmbarkAndAboutHeader/EmbarkAndAboutHeader";

// Component: EmbarkHeader
export const EmbarkHeader = () => {
  const token = localStorage.getItem(btoa("token"));

  return (
    <>
      <EmbarkAndAboutHeader
        image={HeaderImage}
        mobileImage={HeaderImageMobile}
        title={"Start Your Journey With"}
        description={
          " Before you hit the road with Night School, please sign in or create an account."
        }
        firstButton={BUTTON_CONSTANT.ENROLL_NOW}
        secondButton={
          token ? BUTTON_CONSTANT.PROFILE : BUTTON_CONSTANT.LOGIN_IN
        }
        token={token}
      />
    </>
  );
};
