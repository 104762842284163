import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import style from "./SubMenu.module.scss";
import BackArrow from "../../Assets/General/back.svg";
import { Link } from "react-router-dom";
import ActiveBackArrow from "../../Assets/General/on-dark-arrow-right.png";

function SubMenu({
  name,
  icon,
  submenu,
  clickedTitle,
  onClose,
  setSubMenu,
  ...props
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSubMenu(false);
    onClose();
  };

  const handleShow = () => {
    setShow(true);
    setSubMenu(true);
  };

  return (
    <>
      <div
        className={`${show && style.columnSelected} ${style.listMenuWrap}`}
        onClick={submenu && handleShow}
      >
        <div variant="primary" className={style.columnTitle}>
          {name.title}
        </div>

        <div className={style.columnIcon}>
          {show ? (
            <img
              src={ActiveBackArrow}
              alt="arrow-right-active"
              className={style.activeRightArrow}
            />
          ) : (
            icon?.Icon
          )}
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className={style.subMenuMain}
      >
        <Offcanvas.Header className={style.headerSection}>
          <div onClick={() => handleClose()} className={style.backButton}>
            <img
              src={BackArrow}
              alt="BackArrow"
              className={style.backArrowIcon}
            />
            Back
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className={style.subMenuContainer}>
            <div className={style.subMenuTitle}>{clickedTitle}</div>
            {submenu?.map((item, index) => (
              <div className={style.subMenu} key={`sub-menu-${index}`}>
                <Link
                  to={item?.path}
                  onClick={!item?.subMenuItems && handleClose}
                  className={style.linkText}
                >
                  {item?.title}
                </Link>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SubMenu;
