import React from "react";

export const Location = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
      >
        <path
          d="M16.5 8.18191C16.5 11.1554 14.5004 13.986 12.3936 16.1265C11.3517 17.1852 10.3079 18.0505 9.52395 18.6515C9.13238 18.9516 8.80668 19.1849 8.57983 19.3427C8.55167 19.3622 8.52504 19.3806 8.5 19.3979C8.47496 19.3806 8.44833 19.3622 8.42017 19.3427C8.19332 19.1849 7.86762 18.9516 7.47605 18.6515C6.69207 18.0505 5.64829 17.1852 4.60636 16.1265C2.49957 13.986 0.5 11.1554 0.5 8.18191C0.5 6.15083 1.33804 4.19889 2.83634 2.75666C4.3353 1.31379 6.37225 0.5 8.5 0.5C10.6278 0.5 12.6647 1.31379 14.1637 2.75666C15.662 4.19889 16.5 6.15083 16.5 8.18191Z"
          stroke="white"
          strokeOpacity="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1875 8.26744C11.1875 8.77104 11.0325 9.265 10.7396 9.68693C10.4465 10.1091 10.0279 10.441 9.53462 10.6377C9.04118 10.8344 8.49719 10.8861 7.97218 10.7856C7.44724 10.6851 6.96756 10.4375 6.59285 10.0768C6.21834 9.71627 5.96559 9.25933 5.8634 8.76483C5.76125 8.27048 5.81349 7.75785 6.01442 7.29092C6.21545 6.82374 6.55725 6.42134 6.99959 6.13683C7.44213 5.85221 7.96431 5.69922 8.5 5.69922C9.21879 5.69922 9.90449 5.97425 10.4072 6.45811C10.9092 6.94134 11.1875 7.59259 11.1875 8.26744Z"
          stroke="white"
          strokeOpacity="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
