import React, { useContext } from "react";
import style from "./AppButton.module.scss";
import { ThemeContext } from "../../Context/ThemeContext";

// Component: New Figma Button
export const AppColorButton = (props) => {
  const { theme } = useContext(ThemeContext);

  const { buttonName, type, handleClick, arrow, disabled = false } = props;

  return (
    <>
      <button
        className={`${style.commonButton} ${arrow && style.arrowCenter} ${
          theme === "light" && style.commonDarkButton
        } ${props.className ? props.className : ""}`}
        type={type || "button"}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonName}{" "}
        {arrow && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            className={style.buttonRightArrow}
          >
            <path
              d="M0.999999 9.125L17 9.125M17 9.125L8.808 17M17 9.125L8.808 0.999999"
              stroke="#010620"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        )}
      </button>
    </>
  );
};

export const AppBorderButton = (props) => {
  const { theme } = useContext(ThemeContext);

  const { buttonName, handleClick, children, disabled = false } = props;
  return (
    <>
      <button
        className={`${style.commonWhiteBorderButton} ${
          theme === "light" && style.commonDarkBlueBorderButton
        } ${props.className ? props.className : ""}`}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonName}
        {children}
      </button>
    </>
  );
};
