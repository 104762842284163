import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import img4 from "../../Assets/Chapters/Chapter1/check-your-tyres.jpg";
import img5 from "../../Assets/Chapters/Chapter1/chapter-1-image-5.png";
import img6 from "../../Assets/Chapters/Chapter1/chapter-1-image-6.jpg";
import icon1 from "../../Assets/Chapters/Chapter1/BrightsAndEye.svg";
import icon2 from "../../Assets/Chapters/Chapter1/PoliceIcon.svg";
import icon3 from "../../Assets/Chapters/Chapter1/Warning.svg";
import icon4 from "../../Assets/Chapters/Chapter1/WarningLights.svg";
import icon5 from "../../Assets/Chapters/Chapter1/TurnYourLightsOff.svg";
import icon6 from "../../Assets/Chapters/Chapter1/Icon-Book-Light.svg";
import warningLightsGif from '../../Assets/Chapters/Chapter1/WarningLights.gif'
import Progress from "../Progress/Progress";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import {Link} from 'react-router-dom'

const Chapter1 = ({ results, children }) => {
  const sectionRefs = useRef([]);

  let data = null;

  if (results) {
    data = [
      {
        number: "1.9",
        heading: "Chapter 1: Test Summary",
        content: children,
      },
    ];
  } else {
    data = [
      {
        number: "1.0",
        heading: "Introduction",
        content: (
          <>
            <p className={`${style.mediumBold} mb-4`}>
              Welcome to chapter 1, Before we drive.{" "}
            </p>

            <p className={`${style.smallBold} mb-4`}>
              Before you embark on a long journey at night, do a comprehensive
              vehicle check on your car. Make sure your vehicle goes for regular
              service and maintenance before your journey.
            </p>

            <p className={style.smallBold}>
              In this chapter you will learn about all the necessary checks you
              need to do before driving at night to make sure your vehicle is
              ready for the long journey.
            </p>
          </>
        ),
      },

      {
        number: "1.1",
        heading: "Tips: Engine Oil",
        content: (
          <div className="row m-0">
            <div className="align-self-center col-12 col-md-8 order-2 order-md-1">
              <p className={style.small24Bold}>
                Before a long journey, go to a petrol station to check your
                engine oil, brake fluid and water levels, and top up as needed.
              </p>
            </div>
            <div className="col-12 col-md-4 text-left text-sm-center p-0">
              <img src={icon4} alt="" className={style.iconSize} />
            </div>
          </div>
        ),
      },

      {
        number: "1.2",
        heading: "Tips: Windshield",
        content: (
          <>
            <ul
              className={`${style.specialBullets3} ${style.medium} px-4 px-sm-0 row`}
            >
              <li className="col-12 col-sm-6 mb-4 mb-sm-0">
                Make sure your{" "}
                <strong className={style.mediumBold}>
                  windshield is clean and the wipers are functioning{" "}
                </strong>{" "}
                properly.
              </li>

              <li className="col-12 col-sm-6">
                Make sure your{" "}
                <strong className={style.mediumBold}>
                  windshield wiper fluid is filled{" "}
                </strong>{" "}
                and refill if needed.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "1.3",
        heading: "Tips: Check your tyres",
        content: (
          <>
            <div>
              <p className={`${style.mediumBold} mb-4 mb-sm-5 mt-4 mt-md-100`}>
                How To Check Your Tyre Tread:
              </p>

              <div className="text-center text-md-start">
                <img src={img4} alt="" className={style.imgRound} />
              </div>

              <ul className={`${style.specialBullets3} ${style.medium} my-5`}>
                <li className="mb-3">
                  Your{" "}
                  <strong className={style.mediumBold}>
                    tread must be at least
                    <br className="d-md-block d-none" /> 1.6mm depth
                  </strong>{" "}
                  of the tyre.
                </li>

                <li>
                  A trick on{" "}
                  <strong className={style.mediumBold}>
                    checking your <br className="d-md-block d-none" />
                    treading, is using the coin
                  </strong>{" "}
                  <br className="d-md-block d-none" />
                  trick.{" "}
                </li>
              </ul>

              <div className={style.mediumBold}>
                <div className={`${style.specialOrderedListItem} mb-3`}>
                  <p className={style.dottedFont}>1</p>
                  <p> Take a 50c coin and put it in the tread.</p>
                </div>

                <div className={`${style.specialOrderedListItem} mb-3`}>
                  <p className={style.dottedFont}>2</p>
                  <p>
                    If you can see the South African Emblem, replace your tyres
                    immediately.
                  </p>
                </div>

                <div className={style.specialOrderedListItem}>
                  <p className={style.dottedFont}>3</p>
                  <p className="m-0">
                    If the emblem gets hidden in the grove, you do not need to
                    replace them yet.
                  </p>
                </div>
              </div>


              {/* Learn More Button*/}
              <div class="mt-4">
                <Link
                    to="https://www.youtube.com/watch?v=wTxMTyj7wOs"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "1.4",
        heading: "Tips: Check Lights",
        content: (
          <>
          <div className={`${style.iframe_container} row mb-5`}>
            <div className="col-12 col-md-8 mb-4">
              {/* <iframe
                  className={`videoYT ${style.introVideo}`}
                  title="youtube"
                  width="100%"
                  src="https://www.youtube.com/embed/hubndkOrnsY?si=xetKpSO1V0TnGN7h"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>{" "} */}
              <video
                  className={`videoYT ${style.introVideo}`}
                  width="100%"
                  loop
                  controls
                  // autoPlay={"autoplay"}
                  preload="auto"
                >
                  <source src="https://nightschool-api.vw.co.za/videos/VW Night School – 1_ Checking your lights.mp4" type="video/mp4" />
              </video>
              
            </div>

            {/*<div className="align-self-center px-3 px-md-5 col-12 col-md-4">
              <p className={style.small24Bold}>
                Inflate your tyres to the recommended pressure.
              </p>
            </div>*/}
          </div>
            <div className="row mx-0 mx-lg-5">
              <div className="col-12 col-md-4 text-left text-sm-center">
                <img src={icon1} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-8 mt-4 mt-md-0">
                <p className={style.smallBold}>
                  Check that all your car lights are in working order. This
                  includes your indicators, brights, emergency hazard lights,
                  and fog lights.
                </p>
              </div>
            </div>

            <div className="row mx-0 mx-lg-5 mt-4 mt-md-4">
              <div className="col-12 col-md-4 text-left text-sm-center">
                <img src={icon2} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-8 mt-4 mt-md-0">
                <p className={style.smallBold}>
                  <strong className={style.smallsBold}>
                    Checking lights:
                  </strong>{" "}
                  Park in front of a wall and make sure all the lights reflect
                  on the wall unless you have someone who can help you check.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "1.5",
        heading: "Tips: Warning Lights",
        content: (
          <>
            <div className="mx-0 mx-lg-5 d-none d-md-block">
              <div className="row">
                {" "}
                <div className="col-12 col-md-4 text-left text-sm-center p-0">
                  <img src={warningLightsGif} alt="" className={style.iconSize} />
                </div>
                <div className="align-self-center col-12 col-md-8 mt-4 mt-md-0 p-0">
                  <p className={style.mediumBold}>
                    Check for any dashboard warning lights.
                  </p>
                </div>
              </div>
            </div>

            <div className="mx-0 mx-lg-5 d-none d-md-block">
              <div className="row">
                <div className="col-12 col-md-4 text-left text-sm-center p-0">
                  {/* <img src={warningLightsGif} alt="" className={style.iconSize} /> */}
                </div>

                <div className="align-self-center col-12 col-md-8 mt-4 mt-md-0 p-0">
                  <p className={style.smallBold}>
                    If any appear, consult your manual or contact an expert. If
                    it’s a Volkswagen contact your nearest Volkswagen dealer or
                    the VW Emergency Assist line South Africa (Free) 0860 434
                    737.
                  </p>
                </div>
              </div>
            </div>

            <div className="d-block d-md-none">
              <div className="d-flex gap-5">
                <img src={icon3} alt="" className={style.iconSize} />
                <img src={icon4} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center mt-4 mt-md-0">
                <p className={style.mediumBold}>
                  Check for any dashboard warning lights.
                </p>
                <p className={`${style.smallBold} mt-4`}>
                  If any appear, consult your manual or contact an expert. If
                  it’s a Volkswagen contact your nearest Volkswagen dealer or
                  the VW Emergency Assist line South Africa (Free) 0860 434 737.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "1.6",
        heading: "Tips: Turn lights off",
        content: (
          <>
            <div className="row mx-0 mx-lg-5">
              <div className="col-12 col-md-4 text-left text-sm-center p-0">
                <img src={icon5} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-8 mt-4 mt-md-0 p-0">
                <p className={style.mediumBold}>
                  Remember to always turn off your lights when you park your
                  car.{" "}
                </p>

                <p className={`${style.smallBold} mt-4`}>
                  Not all car lights automatically turn off.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "1.7",
        heading: "Tips: Emergency Items",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-6 order-2 order-md-1">
                <ul className={`${style.specialBullets} ${style.small}`}>
                  <li className="mb-3">
                    Make sure you have a{" "}
                    <span className={style.smallBold}>jumper cable{" "}</span>
                    in your car.
                  </li>

                  <li className="mb-3">
                    <span className={style.smallBold}>
                      Make sure you have a torch in case of an unexpected
                      breakdown
                    </span>{" "}
                    – preferably a head torch so it’s easier to use your hands.
                  </li>

                  <li className="mb-3">
                    Make sure you have an{" "}
                    <span className={style.smallBold}>illumination vest</span>{" "}
                    in case of a breakdown at night.
                  </li>

                  <li>
                    <span className={style.smallBold}>Charge your phone</span>,
                    always have a charging cable or a fully charged power bank.{" "}
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-6 align-self-center order-1 order-md-2 mb-4 mb-md-0 text-center text-md-start">
                <img src={img5} alt="" className={style.imgRound} />
              </div>
            </div>
          </>
        ),
      },

      {
        number: "1.8",
        heading: "Tips: Map your route",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-6 mb-4 mb-md-0 align-self-center text-center text-md-start">
                <img src={img6} alt="" className={style.imgRound} />
              </div>

              <div className="align-self-center col-12 col-md-6">
                <ul className={`${style.specialBullets} ${style.small}`}>
                  <li className="mb-3">
                    Always take time to{" "}
                    <span className={style.smallBold}>
                      familiarise yourself with your route
                    </span>{" "}
                    before driving at night.
                  </li>
                  <li>
                    Making note of{" "}
                    <span className={style.smallBold}>rest stops</span>, and to
                    check if the suggested route is a safe one and doesn’t take
                    you through a dangerous area.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center">
                <img src={icon6} alt="" className={style.iconSize} />
              </div>
              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.large} ${style.fontWeight700}`}>
                  Now that you’re ready,
                </p>

                <p className={style.large}>let’s begin…</p>
              </div>
            </div>
          </>
        ),
      },

      {
        id: 'test',
        number: "1.9",
        heading: "Take The Chapter Test",
        content: children,
      },
    ];
  }

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`} id="section-2">
      <div className="row">
        <div className="col-md-3">
          <Progress data={data} chapterNumber="1" sectionRefs={sectionRefs} />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default Chapter1;
