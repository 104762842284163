import {useEffect} from 'react'
import smoothScrollToSection from '../../smoothScrollToSection'

const SuccessMessage = ({message, id}) => {

    useEffect(() => {
        smoothScrollToSection(id ? id : 'success-messages', 100, 200)
    }, [message])

    if(!message){
        return <></>
    }

    if (typeof message === 'string') {
        return (
            <div id={id ? id : 'success-messages'} className="alert alert-success" role="alert">
                {message}
            </div>
        )
    }

} 

export default SuccessMessage