import React, { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { useNavigate } from "react-router-dom";
import smoothScrollToSection from "../../smoothScrollToSection";
import Progress from "../Progress/Progress";

const WrapUp = ({ children }) => {
  const navigate = useNavigate();
  const sectionRefs = useRef([]);

  const handleTakeMeThereBtn = () => {
    navigate("/user-profile#progress");
    smoothScrollToSection("certificate", 1000);
  };

  const data = [
    {
      number: "",
      heading: "",
      content: (
        <>
          <p className={`${style.heading}  mb-24`}>End Of Curriculum</p>
          <p className={`${style.small} mb-24`}>
            Congratulations. You've reached the end of the Volkswagen Night
            School Curriculum. We hope that this course has not only empowered
            you but also geared you to be a safer driver at night. In doing so,
            you are helping to make the roads safer for everyone.
          </p>
          <p className={`${style.mediumBold} mt-30 mb-30`}>
            Thank you for doing your part in creating safer journeys for all.
          </p>
          {/* <img className={style.imageWidth} src={img1} alt="" /> */}
          {/* <iframe
            className={`videoYT ${style.introVideo}`}
            title="youtube"
            width="100%"
            src="https://www.youtube.com/embed/Fkwht4kxjlE?si=VTXuycLAO2UwuT54"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
           <video
                className={`videoYT ${style.introVideo}`}
                width="100%"
                loop
                controls
                // autoPlay={"autoplay"}
                preload="auto"
              >
                <source src="https://nightschool-api.vw.co.za/videos/VW Night School – Outro.mp4" type="video/mp4" />
            </video>
          {/* <div
                        className={`${style.medium} d-flex justify-content-between align-items-center mt-10`}
                    >
                        <p className={style.videoTranscriptTitle}>Video Transcript</p>
                        <TfiAngleDown className={style.videoTranscriptTitle} />
                    </div> */}
        </>
      ),
    },
    {
      content: (
        <>
          <div className="row m-0">
            <div className="align-self-center p-0 col-12 col-md-9">
              <p className={`${style.medium} mt-4 mt-md-0`}>
                Celebrate your success for completing the Night School
                curriculum. Go to your progress to{" "}
                <strong className={style.mediumBold}>
                  view, share, or download{" "}
                </strong>{" "}
                your certificate.
              </p>
              <div className="mt-30 mb-20">
                <AppColorButton
                  handleClick={handleTakeMeThereBtn}
                  buttonName={BUTTON_CONSTANT.TAKE_ME_THERE}
                />
              </div>{" "}
            </div>
          </div>
        </>
      ),
    },
  ];

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`}>
      <div className="row">
        <div className="col-md-3">
          <Progress
            data={data}
            chapterNumber="Video"
            sectionRefs={sectionRefs}
          />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default WrapUp;
