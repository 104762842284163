import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SideBar from "./SideBar";
import style from "./Header.module.scss";
import vwWhiteLogo from "../../Assets/General/Vector.svg";
import vwDarkLogo from "../../Assets/General/DarkVector.svg";
import { useScrollDirection } from "../../Utils/Services/Common.Services";
import { ThemeContext } from "../../Context/ThemeContext";
// import searchIcon from "../../Assets/Header/search.svg";
// import darkSearchIcon from "../../Assets/Header/darkSearch.svg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// Component: Header
function Header() {
  const { theme } = useContext(ThemeContext);

  const scrollDirection = useScrollDirection();
  const [showSidebar, setShowSidebar] = useState(false);
  // const [inputSearch, setInputSearch] = useState(false);
  const [isPathName, setPathName] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let location = useLocation();

  useEffect(() => {
    if (location?.pathname?.includes("/user-profile")) {
      setPathName(true);
    } else {
      setPathName(false);
    }
  }, [location]);

  // const handleInputShow = () => {
  //   if (inputSearch) {
  //     setInputSearch(false);
  //   } else {
  //     setInputSearch(true);
  //   }
  // };

  const changeMenuColor = () => {
    if (isPathName && scrollTop >= 0 && scrollTop < 150) {
      return 0;
    } else {
      return 1;
    }
  };

  return (
    <>
      <div className={style.navHeader}>
        <Navbar className={style.Navbar}>
          <Container fluid className={style.navContainer}>
            <Navbar.Brand className={style.menuName} onClick={toggleSidebar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
                className={style.sidebarIcon}
              >
                <path
                  d="M0 16V14H20V16H0ZM0 9V7H14V9H0ZM0 2V0H24V2H0Z"
                  fill={
                    theme === "light" || changeMenuColor() === 0
                      ? "black"
                      : "white"
                  }
                />
              </svg>

              <span
                className={`${style.menuName}  ${
                  (theme === "light" || changeMenuColor() === 0) &&
                  style.darkMenuName
                }`}
              >
                Menu
              </span>
            </Navbar.Brand>

            <Nav
              className={`${style.searchNav} d-flex align-items-center justify-content-center gap-2`}
              href="#action1"
            >
              {/* {inputSearch && (
                <input
                  type="text"
                  placeholder="Type to Search..."
                  className={style.searchInput}
                  autofocus
                />
              )} */}

              {/* <Form
                className="text-white cursor-pointer"
                onClick={handleInputShow}
              >
                 <img
                  src={
                    theme === "light" || changeMenuColor() == 0
                      ? darkSearchIcon
                      : searchIcon
                  }
                  alt="searchIcon"
                  className={`${style.searchIcon} ${
                    changeMenuColor() == 0 ? `${style.black}` : `${style.white}`
                  }`}
                /> 
              </Form> */}
            </Nav>
          </Container>
        </Navbar>

        <div
          className={`${
            scrollDirection === "down" ? style.downHeader : style.upHeader
          } ${style.headerWrapper}`}
        >
          <div className={style.navLineContainer}>
            <div
              className={`${
                theme === "light" || changeMenuColor() === 0
                  ? style.brandLeftDark
                  : style.brandLeft
              }`}
            ></div>

            <div>
              <a href="https://www.vw.co.za/en.html">
                <img
                  src={
                    theme === "light" || changeMenuColor() === 0
                      ? vwDarkLogo
                      : vwWhiteLogo
                  }
                  alt="vwWhiteLogo"
                  className={style.vwHeaderLogo}
                />
              </a>
            </div>

            <div
              className={
                theme === "light" || changeMenuColor() === 0
                  ? style.brandRightDark
                  : style.brandRight
              }
            ></div>
          </div>
        </div>
      </div>
      <SideBar show={showSidebar} onClose={() => setShowSidebar(false)} />
    </>
  );
}

export default Header;
