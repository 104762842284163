import React from "react";
import style from "./EmbarkCard.module.scss";
import EmbarkVideo from "./EmbarkVideo";
import DottedImage from "../../Assets/General/dotted-grid.png";
import { EMBARK_CARD } from "../../Utils/Helper/Constants";
import Slider from "react-slick";
import rightArrow from "../../Assets/General/stroke-on-dark-arrow-right.svg";
import leftArrow from "../../Assets/General/stroke-on-dark-arrow-left.svg";
import { Link } from "react-router-dom";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  nextArrow: <img src={rightArrow} alt="rightArrow" />,
  prevArrow: <img src={leftArrow} alt="leftArrow" />,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        initialSlide: 0,
      },
    },
  ],
};

const EmbarkCard = () => {
  return (
    <>
      <div className={style.dottedImageContainer}>
        <div>
          <img
            src={DottedImage}
            alt="DottedImage"
            className={style.dottedImage}
          />
        </div>

        <div>
          <p className={style.homeRedirect}>
            <Link to="/home">Home</Link> &gt; <Link to="/embark">Nightschool</Link>
          </p>
        </div>
      </div>

      <div className="position-relative overflow-hidden" id="section-2">
        <div className="container">
          <div className={style.cardHeadingContainer}>
            <h2 className={style.cardHeading}>
              Process{" "}
              <span className={style.cardLightTextHeading}>
                of getting certified
              </span>
            </h2>
          </div>

          <div className={`${style.desktopCards} embarkCardSlider`}>
            <Slider {...settings}>
              {EMBARK_CARD.CARDS.map((item, index) => (
                <div key={index} className={style.cardContainer}>
                  <div className={style.card}>
                    <img
                      src={item?.image}
                      alt="CertificateIcons"
                      className={style.cardImage}
                    />

                    <div className={style.focusContent}>
                      <p className={style.cardTitle}>{item?.title}</p>
                      <p className={style.description}>{item?.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="gradientContainer top-50 w-25 h-25"></div>

          <div className={style.mobileCards}>
            <div className="position-relative top-0 overflow-hidden">
              <div className="gradientContainer "></div>
            </div>

            <div className="row">
              {EMBARK_CARD.MOBILE_CARD.map((item, index) => (
                <div key={index} className={style.cardContainer}>
                  <div className={style.card}>
                    <img
                      src={item?.image}
                      alt="CertificateIcons"
                      className={style.cardImage}
                    />

                    <div className={style.focusContent}>
                      <div>
                        <p className={style.cardTitle}>{item?.title}</p>
                        <p className={style.description}>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <EmbarkVideo />
    </>
  );
};
export default EmbarkCard;
