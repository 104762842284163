import apiService from "../../Utils/Services/ApiService";
import { userTokenMethod } from "../../Utils/Services/Common.Services";
import "./UserProfile.scss";

const LogoutButton = () => {
  const logout = () => {
    apiService
      .logout()
      .then((res) => {
        userTokenMethod("delete");
        // navigate("/home");
        window.location = `${process.env.PUBLIC_URL}/`; //needs to rerender for logged in state
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <button
      className="_logout _active d-none d-sm-flex align-items-center"
      onClick={() => logout()}
    >
      Logout
    </button>
  );
};

export default LogoutButton;
