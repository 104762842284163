import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineClose } from "react-icons/ai";
import style from "./SideBar.module.scss";
import { Link } from "react-router-dom";
import SideBarList from "../../Utils/Helper/SideMenuList";
import { FooterSlogan } from "../Shared/FooterSlogan";
import { ToggleSwitch } from "../Shared/ToggleSwitch/ToggleSwitch";
import SubMenu from "./SubMenu";

// Component: Sidebar
function SideBar(props) {
  const { show, onClose } = props;
  const [clickedTitle, setClickedTitle] = useState(null);
  const [subMenu, setSubMenu] = useState(false);

  const handleItemClick = (title) => {
    setClickedTitle(title);
  };

  return (
    <>
      <Offcanvas show={show} onHide={onClose} className={style.sidebarMain}>
        <Offcanvas.Header className={style.sideBarHeader}>
          <div className={style.closeMenu} onClick={onClose}>
            <AiOutlineClose className={style.closeIcon} />
            Close menu
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div
            className={`${subMenu && style.activeSubMenu} ${
              style.sideBarMenuList
            }`}
          >
            <div>
              {SideBarList.map((item, index) => (
                <div key={item.path + index}>
                  <Link
                    to={item?.path}
                    onClick={!item?.subMenuItems && onClose}
                    className={style.linkText}
                  >
                    <div className="cursor-pointer pb-14 pt-14">
                      <div
                        onClick={() =>
                          handleItemClick(item?.subMenuItems && item?.title)
                        }
                      >
                        {[item].map((placement, index) => {
                          return (
                            <>
                              <SubMenu
                                key={`sub-${index}`}
                                placement="end"
                                name={placement}
                                icon={placement}
                                setSubMenu={setSubMenu}
                                submenu={placement?.subMenuItems}
                                clickedTitle={
                                  item?.subMenuItems && clickedTitle
                                }
                                onClose={onClose}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>

            <div>
              {/* <div>
                <ToggleSwitch />
              </div> */}

              <div
                className={`${subMenu && style.activeSubMenuFooter} ${
                  style.footerSentence
                }`}
              >
                <div className={style.footerSentence}>
                  <FooterSlogan
                    FooterStyle={style.whiteFooterSlogan}
                    FooterCompanyName={style.companyWhiteText}
                  />
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>

        <div className="data"></div>
      </Offcanvas>
    </>
  );
}

export default SideBar;
