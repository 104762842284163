import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import img2 from "../../Assets/Chapters/Chapter3/Tips Vehicle jamming.jpg";
import icon1 from "../../Assets/Chapters/Chapter3/icon1.svg";
import icon2 from "../../Assets/Chapters/Chapter3/icon2.svg";
import icon3 from "../../Assets/Chapters/Chapter3/icon3.svg";
import icon4 from "../../Assets/Chapters/Chapter3/icon4.svg";
import icon5 from "../../Assets/Chapters/Chapter3/icon5.svg";
import icon6 from "../../Assets/Chapters/Chapter3/icon6.svg";
import icon7 from "../../Assets/Chapters/Chapter3/icon7.svg";
import icon8 from "../../Assets/Chapters/Chapter3/icon8.svg";
import icon9 from "../../Assets/Chapters/Chapter3/icon9.png";
import video1 from "../../Assets/Chapters/Chapter3/Blind Spot 2.mp4";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { Link } from "react-router-dom";
import Progress from "../Progress/Progress";

const Chapter3 = ({ results, children }) => {
  const sectionRefs = useRef([]);

  let data = null;

  if (results) {
    data = [
      {
        number: "3.2.14",
        heading: "Chapter 3: Test Summary",
        content: children,
      },
    ];
  } else {
    data = [
      {
        number: "3.1",
        heading: "Hijacking Introduction",

        content: (
          <>
            <p className={`${style.mediumBold} mb-4 mt-4`}>
              There were 134 000 Hijacking Victims in the 2021/2022 Period.
            </p>
            {/* <p className={`${style.mediumBold} mb-4 mt-4`}>2021/2022 Period.</p> */}
            <p className={`${style.small} mb-4`}>
              The majority of these hijackings occur at night. This chapter will
              give you insights into hijacking and provide you with tips on how
              to avoid it.
            </p>
            <p className={`${style.small} mb-4`}>
              For practical guidance on managing a hijacking scenario, sign up
              to the Volkswagen Advanced Driving Academy Night School
              Anti-Hijacking training course.
            </p>
            {/* <iframe
              className={`videoYT ${style.introVideo}`}
              title="youtube"
              width="100%"
              src="https://www.youtube.com/embed/yIa8OJ6ePIQ?si=lBV9JuKu8v2AGsXM"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <video
                className={`videoYT ${style.introVideo}`}
                width="100%"
                loop
                controls
                // autoPlay={"autoplay"}
                preload="auto"
              >
                <source src="https://nightschool-api.vw.co.za/videos/VW Night School – 3_ Hijacking.mp4" type="video/mp4" />
            </video>
            
            <div className="mt-30">
              <Link
                to="https://www.vw.co.za/en/volkswagen-experience/driving-academy/volkswagen-driving-courses.html"
                target="_blank"
              >
                <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
              </Link>
            </div>{" "}
          </>
        ),
      },

      {
        number: "3.1.1",
        heading: "Tips: Stay alert",
        content: (
          <>
            <ul className={style.specialBullets}>
              <li className={`${style.small} mb-3`}>
                Stay alert on the road, especially{" "}
                <strong className={style.smallBold}>
                  when driving at night
                </strong>{" "}
                to ensure your safety, and that of your passengers.
              </li>

              <li className={`${style.small} mb-3`}>
                Make sure all your{" "}
                <strong className={style.smallBold}>
                  belongings are safely stowed
                </strong>{" "}
                and not visible.
              </li>

              <li className={style.small}>
                When you're at a{" "}
                <strong className={style.smallBold}>
                  standstill in traffic,
                </strong>{" "}
                maintain a gap with the vehicle in front of you and be aware of
                your surroundings, especially pedestrians walking amongst
                traffic.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "3.1.2",
        heading: "Tips: Vehicle jamming",
        content: (
          <>
            <p className={`${style.mediumBold} mt-30 mb-30`}>
              Be aware of vehicle jamming:{" "}
            </p>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-6">
                <img className={style.imageWidth} src={img2} alt="" />
              </div>

              <div className="align-self-center mt-4 mt-sm-5 my-md-0 px-md-5 col-12 col-md-6">
                <ul className={`${style.specialBullets2} mb-0`}>
                  <li className={`${style.small24} mb-3`}>
                    <strong className={style.small24Bold}>
                      Vehicle jamming
                    </strong>{" "}
                    is when a remote device is used to block or jam the locking
                    device of a vehicle.
                  </li>

                  <li className={style.small24}>
                    <strong className={style.small24Bold}>
                      Prevent vehicle jamming
                    </strong>{" "}
                    by double-checking that all your doors are locked after
                    pressing the locking device.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "3.1.3",
        heading: "Tips: Hijacking hotspots",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img src={icon1} alt="" className={`${style.iconSize}`} />
              </div>

              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.mediumBold} my-4 my-md-0`}>
                  Stay alert of high-risk areas at night and during peak hours{" "}
                  This includes areas such as schools, complexes, malls, and traffic lights
                </p>
                {/*<p className={`${style.small}`}>
                  - areas such as schools, complexes, malls, and traffic lights.
                </p>*/}
              </div>
            </div>
          </>
        ),
      },

      {
        number: "3.1.4",
        heading: "Tips: Plan your route",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon2}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Plan your route before your drive at night, and avoid
                  hijacking hotspots and times.{" "}
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "3.1.5",
        heading: "Tips: Hijacking strategies",
        content: (
          <>
            <p className={`${style.mediumBold} mt-30 mb-30`}>
              Know The Strategies Used By Hijackers, Including:{" "}
            </p>

            <div className={style.small}>
              <ul className={`${style.specialBullets} ${style.medium} mb-0`}>
                <li className={`${style.small} mb-3`}>
                  Staging minor collisions, bogus police/traffic cops.
                </li>

                <li className={`${style.small} mb-3`}>
                  Placing objects on your windscreen to make you stop.
                </li>

                <li className={`${style.small} mb-3`}>
                  Placing objects in the road that force you to stop e.g.
                  bricks, and planks with nails in them.{" "}
                </li>

                <li className={`${style.small} mb-3`}>
                  Targeting you at intersections when you’re on your phone and
                  distracted.{" "}
                </li>

                <li className={style.small}>
                  Blocking you in at drive-throughs, as well as your own
                  driveway.{" "}
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "3.1.6",
        heading: "Tips: Stay Vigilant",
        content: (
          <>
            <div className="row m-0">
              <div
                className={`${style.desktopImage} col-12 col-md-3 text-left text-sm-center`}
              >
                <img
                  src={icon3}
                  alt=""
                  className={`${style.iconSize} align-self-center ms-md-3 mb-3`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.mediumBold}`}>
                  Drive with your doors locked and stay vigilant at traffic
                  lights.
                </p>
              </div>

              <div
                className={`${style.mobileImage} col-12 col-md-3 text-left text-sm-center`}
              >
                <img
                  src={icon3}
                  alt=""
                  className={`${style.iconSize} align-self-center ms-md-3 mb-3`}
                />
              </div>
            </div>

            <div>
              <ul className={`${style.specialBullets} mb-0`}>
                <li className={`${style.small} mb-3`}>
                  Note nearby police stations, armed response companies, and
                  emergency apps that can help should you be involved in a{" "}
                  <strong className={style.smallBold}>
                    hijacking situation.
                  </strong>
                </li>

                <li className={style.small}>
                  Have a{" "}
                  <strong className={style.smallBold}>
                    defensive mindset:
                  </strong>{" "}
                  Scan for suspicious vehicles, and drive to the nearest police
                  station if you are followed. Do not go home if you feel like
                  someone is following you.{" "}
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "3.1.7",
        heading: "Tips: Blind Spot monitor",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-6 align-self-center">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={video1} type="video/mp4" />
                </video>
              </div>

              <div className="align-self-center px-md-5 p-0 col-12 col-md-6">
                <ul className={`${style.specialBullets2}`}>
                  <li className={`${style.small24} `}>
                    <strong className={style.small24Bold}>
                      The Volkswagen blind spot monitor
                    </strong>{" "}
                    will detect and alert you of any traffic or people walking
                    around or towards your car.
                  </li>

                  <li className={`${style.small24} mt-20 mt-md-5`}>
                    Find out more about <br />
                    <strong className={style.small24Bold}>
                      blind spot monitor.
                    </strong>
                  </li>
                </ul>
                <div className="mt-4">
                  <Link
                    to="https://www.vw.co.za/en/innovation-technology/vehicle-technology.html"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
                </div>{" "}
              </div>
            </div>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon4}
                  alt=""
                  className={`${style.iconSize} align-self-center ms-md-3`}
                />
              </div>

              <div className="align-self-center p-0 col-12 col-md-9">
                <p className={`${style.medium} my-4 my-md-0`}>
                  This section focussed on{" "}
                  <strong className={style.mediumBold}>
                    awareness and prevention.
                  </strong>{" "}
                  For a practical course on how to manage an actual hijacking
                  situation, sign up to the practical{" "}
                  <strong className={style.mediumBold}>
                    Night School Anti-Hijacking
                  </strong>{" "}
                  training course.
                </p>
                <div className="mt-4">
                  <Link
                    to="https://www.vw.co.za/en/volkswagen-experience/driving-academy/volkswagen-driving-courses.html"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
                </div>{" "}
              </div>
            </div>
          </>
        ),
      },

      {
        number: "3.2",
        heading: "Road Rage Introduction",
        content: (
          <>
            <div>
              <p className={`${style.mediumBold} mt-30 mb-20`}>
                Welcome to chapter 3.2, Road Rage{" "}
              </p>

              <p className={`${style.small} mb-24`}>
                Crime statistics indicate that 6 424 murders reported were due
                to arguments, misunderstanding, provocation, and road rage.
                We’re all on the road together but we never know what people are
                going through. We live in a pressurised society and even the
                smallest act can trigger an event.{" "}
              </p>

              <p className={`${style.small} mb-3`}>
                In South Africa, road rage and aggressive driving are growing
                concerns that can’t be ignored. In this section you’ll learn how
                to identify road rage and aggressive driving, how to avoid road
                rage, how not to be a victim, and how to deal with it from other
                drivers.
              </p>
            </div>

            <div>
              <p className={`${style.mediumBold} mt-30 mb-30`}>
                What Is Road Rage?{" "}
              </p>

              <p className={`${style.small} mb-3`}>
                A range of aggressive or violent behaviours exhibited by drivers
                in response to perceived slights or annoyances on the road.
              </p>
            </div>

            <div>
              <p className={`${style.mediumBold} mt-30 mb-30`}>
                What Is Aggressive Driving?
              </p>

              <p className={style.small}>
                A category of dangerous on-road behaviour characterised by a
                disregard for safety and traffic regulations.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.1",
        heading: "Tips: Avoid reacting negatively",
        content: (
          <>
            <div>
              <p className={`${style.medium} mb-3`}>
                Avoid reacting negatively to an aggressive driver,{" "}
                <strong className={style.mediumBold}>
                  it may escalate the situation.
                </strong>
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.2",
        heading: "Tips: Keep your distance",
        content: (
          <>
            <div>
              <p className={style.medium}>
                Keep your distance:
                <strong className={style.mediumBold}>
                  {" "}
                  If you notice an aggressive driver,
                </strong>{" "}
                keep a distance between your vehicle and theirs.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.3",
        heading: "Tips: Identify an aggressive driver",
        content: (
          <>
            <p className={`${style.mediumBold} mt-30 mb-30`}>
              How to identify An Aggressive Driver:
            </p>

            <div>
              <ul className={`${style.specialBullets} mb-0`}>
                <li className={`${style.small} mb-3`}>Erratic driving </li>

                <li className={`${style.small} mb-3`}>Speeding </li>

                <li className={`${style.small} mb-3`}>Tailgaiting</li>

                <li className={style.small}>
                  Overtaking when it isn’t safe to do so
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "3.2.4",
        heading: "Tips: Don’t engage",
        content: (
          <>
            <div>
              <p className={style.medium}>
                Don’t engage:{" "}
                <strong className={style.mediumBold}>
                  Avoid responding to aggressive drivers’
                </strong>{" "}
                gestures or comments.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.5",
        heading: "Tips: Defensive driving",
        content: (
          <>
            <div>
              <p className={style.medium}>
                Practice
                <strong className={style.mediumBold}>
                  {" "}
                  defensive driving techniques.
                </strong>{" "}
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.6",
        heading: "Tips: If you feel threatened",
        content: (
          <>
            <div>
              <p className={style.medium}>
                If you feel threatened by an aggressive driver or witness an act
                of road rage,{" "}
                <strong className={style.mediumBold}>call for help.</strong>
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.7",
        heading: "Tips: Stay safe",
        content: (
          <>
            <div>
              <p className={style.medium}>
                Stay safe: If an aggressive driver follows you,{" "}
                <strong className={style.mediumBold}>do not drive home;</strong>{" "}
                instead, drive to the nearest police station.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "3.2.8",
        heading: "Tips: Plan your journey",
        content: (
          <>
            <div className="row mt-30 mb-30">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img src={icon5} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.medium}`}>
                  The <strong className={style.mediumBold}>less rushed</strong>{" "}
                  you are, the less likely you are to speed, take unnecessary
                  risks and respond negatively to someone cutting in front of
                  you.
                </p>
              </div>
            </div>

            <div>
              <ul className={`${style.specialBullets3}`}>
                <li className={`${style.medium}`}>
                  Plan your journey and leave well in advance.
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "3.2.9",
        heading: "Tips: Play music",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img src={icon6} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>Playing music softly:</p>
              </div>
            </div>

            <div className="mt-30">
              <ul className={`${style.specialBullets} mb-0`}>
                <li className={`${style.small} mb-3`}>
                  It’s proven that music can{" "}
                  <strong className={style.smallBold}>
                    reduce aggression.
                  </strong>{" "}
                </li>

                <li className={style.small}>
                  75% of people feel{" "}
                  <strong className={style.smallBold}>less stressed</strong>{" "}
                  when they listen to their favorite song.{" "}
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "3.2.10",
        heading: "Tips: Stay calm",
        content: (
          <>
            <div className="mt-30">
              <ul className={`${style.specialBullets} mb-0`}>
                <li className={`${style.small} mb-3`}>
                  Always remember the{" "}
                  <strong className={style.smallBold}>90-second rule</strong>{" "}
                  when you get angry – it takes 90 seconds for your body to
                  stabilise and calm down.{" "}
                </li>

                <li className={`${style.small} mb-3`}>
                  <strong className={style.smallBold}>Be courteous</strong> and
                  stay calm if provoked.
                </li>

                <li className={style.small}>
                  Drive with your{" "}
                  <strong className={style.smallBold}>car doors locked</strong>{" "}
                  and never leave the safety of your vehicle. This will escalate
                  the situation.{" "}
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "3.2.11",
        heading: "Tips: Don’t sound your hooter",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img src={icon7} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.medium}`}>
                  Don't retaliate by{" "}
                  <strong className={style.mediumBold}>
                    sounding your hooter, flashing your lights or gesturing,
                  </strong>{" "}
                  this will only aggravate the situation.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "3.2.12",
        heading: "Tips: Report to police",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img src={icon8} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.medium}`}>
                  If you’re a{" "}
                  <strong className={style.mediumBold}>
                    victim of aggression,
                  </strong>{" "}
                  take the car's registration number and report the incident to
                  the police.
                </p>

                <p className={`${style.smallBold} mt-14`}>
                  Drive courteously: leave plenty of room when you merge, always
                  use your indicators, and don't use your hooter unnecessarily.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon9}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.large} my-4 my-md-0`}>
                  Remember, there are loved ones waiting for you at the end of your journey.
                  <strong className="font-weight-700">Take your time.</strong>{" "}
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        id: "test",
        number: "3.2.13",
        heading: "Take the Chapter Test",
        content: children,
      },
    ];
  }

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`} id="section-2">
      <div className="row">
        <div className="col-md-3">
          <Progress data={data} chapterNumber="3" sectionRefs={sectionRefs} />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default Chapter3;
