import React from "react";
import style from "./ShowPassword.module.scss";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const ShowPassword = ({
  setImageButton,
  passwordType,
  setPasswordType,
  imageButton,
  confirmPasswordType,
  setConfirmPasswordType,
  flag,
  page,
}) => {
  const togglePasswordVisibility = () => {
    if (flag === "confirmPassword") {
      const newConfirmType =
        confirmPasswordType === "password" ? "text" : "password";
      setConfirmPasswordType(newConfirmType);
    } else {
      const newType = passwordType === "password" ? "text" : "password";
      setPasswordType(newType);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <button
        onClick={() => {
          togglePasswordVisibility();
          setImageButton(!imageButton);
        }}
        onMouseDown={handleMouseDownPassword}
        edge="end"
        className={`${style.fieldIcon}`}
        type="button" //so that it doesn't submit the form
      >
        {flag === "confirmPassword" ? (
          confirmPasswordType === "password" ? (
            <BsEye
              className={`${style.showPassword}  ${
                page === "embark" && style.whiteIcon
              }`}
            />
          ) : (
            <BsEyeSlash
              className={`${style.showPassword}  ${
                page === "embark" && style.whiteIcon
              }`}
            />
          )
        ) : passwordType === "password" ? (
          <BsEye
            className={`${style.showPassword}  ${
              page === "embark" && style.whiteIcon
            }`}
          />
        ) : (
          <BsEyeSlash
            className={`${style.showPassword}  ${
              page === "embark" && style.whiteIcon
            }`}
          />
        )}
      </button>
    </>
  );
};

export default ShowPassword;
