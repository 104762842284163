import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import icon3 from "../../Assets/Chapters/Chapter4/Speed_60.png";
import icon4 from "../../Assets/Chapters/Chapter4/Seat_belt_airbag01.png";
import icon5 from "../../Assets/Chapters/Chapter4/DontTextandDrive.png";
import icon6 from "../../Assets/Chapters/Chapter4/Headphones.png";
import icon7 from "../../Assets/Chapters/Chapter4/DontEatAndDrive.png";
import icon8 from "../../Assets/Chapters/Chapter4/DontDoMakeupInCar.png";
import icon9 from "../../Assets/Chapters/Chapter4/Stressed.png";
import icon10 from "../../Assets/Chapters/Chapter4/Alert_eye.png";
import icon11 from "../../Assets/Chapters/Chapter4/Zzz1.png";
import icon12 from "../../Assets/Chapters/Chapter4/AC.png";
import icon13 from "../../Assets/Chapters/Chapter4/Warning.png";
import icon14 from "../../Assets/Chapters/Chapter4/DontGetIntheCarWithADrunkDriver.png";
import icon15 from "../../Assets/Chapters/Chapter4/Taxi.png";
import img2 from "../../Assets/Chapters/Chapter4/img2.png";
import img1 from "../../Assets/Chapters/Chapter4/img1.png";
import img3 from "../../Assets/Chapters/Chapter4/Rod.png";
import Group from "../../Assets/Chapters/Group-1459.svg";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import Progress from "../Progress/Progress";
import { Link } from "react-router-dom";

const Chapter4 = ({ results, children }) => {
  const sectionRefs = useRef([]);

  let data = null;

  if (results) {
    data = [
      {
        number: "4.4.4",
        heading: "Chapter 4: Test Summary",
        content: children,
      },
    ];
  } else {
    data = [
      {
        number: "4.1",
        heading: "Speed Introduction",
        content: (
          <>
            {/* <img className={style.imgRound} src={img1} alt="" /> */}
            <p className={`${style.mediumBold} mb-4 mt-4`}>
              Welcome To Chapter 4.1, Speed.{" "}
            </p>
            <p className={`${style.small} mb-4`}>
              <strong className={style.smallBold}>
                The effects of excessive speeding contribute a high number of
                fatalities on the roads yearly.
              </strong>{" "}
              There were a total of 1089 reported speed arrests in 2022. And in
              the 2023 Easter period (6 to 10 April) 22% of fatal crashes were
              due to speeding.
            </p>
            <p className={`mb-4 ${style.smallBold}`}>
              Put simply, speed kills.
            </p>
            <p className={`${style.small} mb-4`}>
              In this chapter, you will learn about the dangers of speeding and
              tips on how to avoid speeding and collisions when driving at
              night. Always remember, the faster you go the less time you have
              to respond, especially at night when visibility is poor.
            </p>
          </>
        ),
      },

      {
        number: "4.1.1",
        heading: "Tips: Speed Limits",
        content: (
          <>
            <div className="row mb-4 mb-md-5">
              <div className="col-12 col-md-3 align-self-center text-left text-sm-center text-md-end">
                <img src={icon3} alt="" className={style.iconSize} />
              </div>

              <p
                className={`${style.mediumBold} ${style.fontRegular} col-12 col-md-9 align-self-center px-md-5 mt-3 mt-md-0`}
              >
                Always abide by the rules of the road concerning speed limits:
              </p>
            </div>

            <ul className={`${style.specialBullets} ${style.small} mb-0`}>
              <li className={`mb-3`}>
                Always drive at 60 km/h on public roads in urban areas: that
                means any road that has high pedestrian traffic.
              </li>

              <li className={style.small}>
                Do not exceed the 120 km/h speed limit when driving on any
                freeway.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "4.1.2",
        heading: "Tips: Speeding & Braking Distance",
        content: (
          <>
            {/* <iframe
              className={style.Video}
              title="youtube"
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/TDAZiohA39Y?si=iakr8oiZVdTD19Lz"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <video
                className={`videoYT ${style.introVideo}`}
                width="100%"
                loop
                controls
                // autoPlay={"autoplay"}
                preload="auto"
              >
                <source src="https://nightschool-api.vw.co.za/videos/VW Night School – 2_ Speed and braking.mp4" type="video/mp4" />
            </video>
            
            <div className={`${style.termsDesc} ${style.mediumBold} mb-4 mt-4 font-size-26`}>
              It's more difficult to avoid an accident if you're driving at a high speed at night.
            </div>{" "}
            <div
              className={`${style.termsDesc}  ${style.mediumBold}  mb-4 font-size-26`}
            >
              Speed and braking distance:
            </div>
            <ul className={style.specialBullets}>
              <li className={`${style.small} mb-4`}>
                The faster you go, the less time you have to see and react in
                time.
              </li>

              <li className={style.small}>
                Your eyes take time to react when speeding - the average
                person's reaction time is 1.5 seconds, and even longer when they
                are fatigued or stressed.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "4.1.3",
        heading: "Tips: Skids",
        content: (
          <>
            {/* <iframe
              className={style.Video}
              title="youtube"
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/a5aoGA8tHqs?si=Zz8h39QK7oNjrWFB"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <video
                className={`videoYT ${style.introVideo}`}
                width="100%"
                loop
                controls
                // autoPlay={"autoplay"}
                preload="auto"
              >
                <source src="https://nightschool-api.vw.co.za/videos/VW Night School – 4_ Skids.mp4" type="video/mp4" />
            </video>
            <div className={`${style.termsDesc} ${style.mediumBold} mb-4 mt-4 font-size-26`}>
              Excessive speeding causes your car to skid – there are three types of skids: Understeer, Oversteer, and Hydroplaning.
            </div>
            <p className={`${style.small} mt-4 mb-4`}>
              <ul className={`${style.specialBullets} ${style.small} mb-0`}>
                <li className={`mb-3`}>
                  Oversteer and understeer skids are caused by turning into a corner too fast or applying too much power as you exit the corner. You are especially at risk if you are speeding.
                </li>
                <li>
                  Hydroplaning Is when your tyres lose contact with the road because of excess water.
                </li>
              </ul>
            </p>
          </>
        ),
      },

      {
        number: "4.1.4",
        heading: "Tips: Speed And Safety",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={icon4}
                  alt=""
                  className={`${style.iconSize} align-self-center mb-3`}
                />
              </div>
              <p
                className={`${style.medium} ${style.fontRegular} mb-4 p-0 col-12 col-md-9 align-self-center px-md-5`}
              >
                The effectiveness of safety devices such as airbags and seat
                belts are severely compromised when driving at high speeds.
              </p>
            </div>
            <p className={`${style.small} mb-4`}>
              <ul className={`${style.specialBullets} ${style.small} mb-0`}>
                <li className={`mb-3`}>
                  Volkswagens come with (include stability specs like ABS etc)
                  to help manage these.
                </li>
                <li>
                  These too are compromised when speeding, so reduce your speed
                  and always increase your following distance.
                </li>
              </ul>
            </p>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={Group}
                  alt=""
                  className={`${style.iconSize} align-self-center mb-3`}
                />
              </div>
              <div className={`p-0 col-12 col-md-9 px-md-5 ${style.medium}`}>
                Sign up for the{" "}
                <span className={`${style.mediumBold}`}>
                  {" "}
                  Volkswagen Advanced Night Driving course
                </span>{" "}
                to get a practical demonstration on speeding and what to do when
                your car skids.
                <div className="pt-3">
                  <Link
                    to="https://www.vw.co.za/en/volkswagen-experience/driving-academy.html"
                    target="_blank"
                  >
                    <AppColorButton
                      buttonName={BUTTON_CONSTANT.FIND_OUT_MORE}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "4.2",
        heading: "Concentration Introduction",
        content: (
          <>
            <p className={`${style.mediumBold} mb-3`}>
              Welcome To Chapter 4.2, Concentration.
            </p>
            <p className={`${style.small} mb-3`}>
              <strong className={style.smallBold}> Distracted driving</strong>{" "}
              happens when drivers engage in other activities that take their
              attention away from driving. At night it's especially important to
              stay alert and concentrate while driving on the road. Statistics
              show that texting while driving makes you 23% more likely to be
              involved in an accident.
            </p>
            <p className={`${style.small}`}>
              {" "}
              It's always vital to remain focused on the road while driving. In
              this chapter we will unpack the many different types of
              distractions and the solutions for them, like using Volkswagen’s
              App-Connect with to avoid texting while driving.
            </p>
          </>
        ),
      },

      {
        number: "4.2.1",
        heading: "Tips: Don't Text And Drive",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={icon5}
                  alt=""
                  className={`${style.iconSize} align-self-center mb-3`}
                />
              </div>
              <p
                className={`${style.mediumBold} ${style.fontRegular} p-0 col-12 col-md-9 align-self-center`}
              >
                Don't text while driving, only drive.
              </p>
            </div>

            <ul className={`${style.specialBullets} ${style.small} mt-4`}>
              <li className="mb-3">
                When you are distracted by your phone while driving. you are
                prone to drifting out of the lane, and in those few seconds, an
                accident can happen.
              </li>
              <li>
                Stop somewhere safe to use your phone for mapping routes. any
                emergencies, or when updating loved ones. Be careful of your
                surroundings as criminals might be drawn to your phone,
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "4.2.2",
        heading: "Tips: VW App - Connect",
        content: (
          <>
            <p className={`${style.mediumBold} mb-4`}>
              Make sure your phone is connected to your car's bluetooth and use
              it in hands-free mode, like via VW's App-Connect.
            </p>

            <div className="row">
              <div
                className={`${style.medium} col-12 col-md-6 order-2 order-md-1 align-self-center`}
              >
                <ul className={style.specialBullets2}>
                  {" "}
                  <li className={`${style.small24} mb-3`}>
                    Volkswagen App-Connect integrates apps like Apple Media
                    Library and Google Maps onto the infotainment system to give
                    you ease of access.
                  </li>
                  <li className={style.small24}>
                    If your car has the capability of an integrated infotainment
                    system like App-Connect for Volkswagen, always connect your
                    phone to this system.
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-6 order-md-2 order-1 align-self-center mb-4 mb-md-0 text-center">
                <img src={img2} alt="" className={style.imgRound} />
              </div>
            </div>
          </>
        ),
      },

      {
        number: "4.2.3",
        heading: "Tips: Don't Use Headphones",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end">
                <img src={icon6} alt="" className={style.iconSize} />
              </div>

              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.mediumBold} mt-4 mt-md-0`}>
                  Don't use headphones, as you may not hear or pay attention to
                  the outside world when driving.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "4.2.3",
        heading: "Tips: Don't Eat Food While Driving",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-sm-center text-md-end">
                <img
                  src={icon7}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p
                className={`${style.mediumBold} col-12 col-md-9 align-self-center`}
              >
                Don't eat food while driving.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "4.2.3",
        heading: "Tips: Don't Apply Makeup",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={icon8}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p
                className={`${style.mediumBold} col-12 col-md-9 align-self-center`}
              >
                Don't apply makeup while driving.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "4.2.3",
        heading: "Tips: Stay Alert",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={icon9}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p className="col-12 col-md-9 align-self-center">
                <div className={`${style.mediumBold}`}>
                  {" "}
                  Never lose focus on the road and steering wheel.
                </div>
                <div className={`${style.small}`}>
                  Stay alert of other road users when driving.
                </div>
              </p>
            </div>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row">

              <div
                className={`col-12 mt-4 mt-md-0 ${style.medium}`}
              >
                Find out more about{" "}
                <strong className={style.mediumBold}>
                  {" "}
                  Volkswagen IQ.DRIVE{" "}
                </strong>
                for all the intelligent driver assistance technology including{" "}
                <strong className={style.mediumBold}> VW App-Connect.</strong>
                <div className="mt-5">
                  <Link
                    to="https://www.vw.co.za/en/innovation-technology/connect-your-smartphone.html"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
                </div>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "4.3",
        heading: "Fatigue Introduction",
        content: (
          <>
            <p className={`${style.mediumBold} mb-3`}>
              Welcome To Chapter 4.3, Fatigue.
            </p>

            <p className={`${style.small} mb-3`}>
              South Africa is a driving nation and people travel vast distances
              to get to their destinations, sometimes pushing themselves to
              arrive quicker.{" "}
              <strong className={style.smallBold}>
                {" "}
                On South African roads driver fatigue plays a role in 20% of all
                accidents and 25%-30% of fatal crashes.
              </strong>{" "}
              And at night, these numbers are significantly higher.
            </p>

            <p className={style.small}>
              <strong className={style.smallBold}>In this chapter</strong> you
              will learn about the signs of driver fatigue, conditions like,
              Highway Hypnosis, and helpful tips to avoid fatigue. When you
              complete this chapter, take the test and get your certificate, you
              could unlock coffee vouchers from Vida.
            </p>
          </>
        ),
      },

      {
        number: "4.3.1",
        heading: "Tips: Driver Tiredness",
        content: (
          <>
            <p className={`${style.medium}`}>
              It's important to{" "}
              <strong className={style.mediumBold}>
                {" "}
                be aware of the signs of driver tiredness and fatigue.
              </strong>
            </p>
          </>
        ),
      },

      {
        number: "4.3.2",
        heading: "Tips: Highway Hypnosis",
        content: (
          <>
            <p className={`${style.mediumBold} mb-4`}>
              People can experience fatigue through something called highway hypnosis when driving at night.
            </p>

            <div className="row mb-4">
              <div className="col-12 col-md-4 text-center text-md-start">
                <img src={img3} alt="" className={style.imgRound} />
              </div>

              <div
                className={`${style.medium} mb-0 mb-md-3 mt-3 mt-md-0 col-12 col-md-7 align-self-center`}
              >
                <ul className={style.specialBullets2}>
                  <li className={`${style.small24} mb-3`}>
                    Highway hypnosis is when the driver zones out while driving
                    without remembering what occurred in that period, e.g.
                    arriving home without remembering the drive there.
                  </li>
                  <li className={`${style.small24} mb-3`}>
                    You can prevent highway hypnosis by being aware of your
                    surroundings, getting enough sleep, and carefully choosing
                    the meals you eat.
                  </li>
                </ul>
              </div>
            </div>

            <p className={`${style.mediumBold} mb-3`}>
              Driving long distances can cause you to experience fatigue through
              tunnel vision.
            </p>

            <ul className={style.specialBullets}>
              <li className={style.small}>
                Tunnel vision refers to the loss of a person's peripheral vision
                — a condition where you can only see what is straight ahead of
                you and not the sides.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "4.3.3",
        heading: "Tips: Food",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end mb-4 mb-md-0">
                <img
                  src={icon10}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <p
                className={`${style.mediumBold} col-12 col-md-9 align-self-center`}
              >
                What you eat and drink makes a difference.
              </p>
            </div>

            <ul className={style.specialBullets}>
              <li className={`${style.small} mt-3 mt-md-5  mb-3`}>
                To avoid fatigue, never eat food that makes you tired - rather
                eat fresh fruits, vegetables, unprocessed foods, nuts and seeds
              </li>
              <li className={style.small}>Keep hydrated with cold water.</li>
            </ul>
          </>
        ),
      },

      {
        number: "4.3.4",
        heading: "Tips: Caffeine",
        content: (
          <>
            <p className={style.mediumBold}>
              Drink caffeine-based beverages when feeling fatigued
            </p>

            <p className={`${style.mediumBold} my-4`}>How Caffeine Works:</p>

            <div className="row">
              <div className="col-12 col-md-5 order-md-1 order-2 align-self-center mt-4 mt-md-0">
                <ul className={style.specialBullets}>
                  <li className={`${style.small} mb-3`}>
                    Once consumed, caffeine is quickly absorbed and distributed
                    throughout the body, including your brain. It's here where
                    caffeine elicits its classic effect - to help keep you alert
                    and awake.
                  </li>
                  <li className={`${style.small} mb-3`}>
                    Caffeine works by literally blocking sleep-promoting
                    receptors in your brain called adenosine receptors,
                    therefore keeping you awake.
                  </li>
                  <li className={style.small}>
                    While drinking caffeine helps, it is not a substitute for
                    sleep.
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-6 order-md-2 order-1 align-self-center text-center text-md-start">
                <img src={img1} alt="" className={style.imgRound} />
              </div>
            </div>
          </>
        ),
      },

      {
        number: "4.3.5",
        heading: "Tips: Tired Driving",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end align-self-center">
                <img src={icon11} alt="" className={style.iconSize} />
              </div>
              <p
                className={`${style.mediumBold} col-12 col-md-9 align-self-center mt-4 mt-md-0`}
              >
                Always ensure you get at least 8 hours of sleep before you get
                behind the wheel, especially at night.
              </p>
            </div>

            <ul className={`${style.specialBullets} mt-3 mt-md-5`}>
              <li className={`${style.small} mb-3`}>
                Do not drive when tired. Rather take a break or have a
                well-rested passenger take the wheel.
              </li>
              <li className={style.small}>
                Stop more or as often as you can. Plan your journey with this in
                mind.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "4.3.6",
        heading: "Tips: Check Lights",
        content: (
          <>
            <p className={`${style.mediumBold} mb-4`}>
              Signs of driver fatigue are when you struggle to keep your eyes
              open, yawn frequently, or experience slow reactions.
            </p>

            <div className="row">
              <div className="col-12 col-md-4 text-left text-sm-center">
                <img
                  src={icon12}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p
                className={`${style.smallBold} col-12 col-md-8 align-self-center mt-3 mt-md-0`}
              >
                Avoid driver fatigue by keeping your vehicle well- ventilated
                when driving at night.
              </p>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 text-left text-sm-center">
                <img
                  src={icon13}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p
                className={`${style.smallBold} col-12 col-md-8 align-self-center mt-3 mt-md-0`}
              >
                If your vehicle has an alert feature that prompts you to take a
                break, like VW's Fatigue Assist, take notice and stop when safe
                to. Arriving late is better than not arriving at all.
              </p>
            </div>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3">
                <img src={Group} alt="" className={style.iconSize} />
              </div>

              <div
                className={`col-12 col-md-9 px-md-5 mt-4 mt-md-0 ${style.medium}`}
              >
                <p>
                  Find out more about{" "}
                  <strong className={style.mediumBold}>
                    Volkswagen Fatigue Assist,
                  </strong>{" "}
                  the driver assistance system that lets you know when you need
                  to{" "}
                  <strong className={style.mediumBold}>take a break. </strong>{" "}
                </p>

                <div className="mt-5">
                  <Link
                    to="https://www.vw.co.za/en/innovation-technology/connect-your-smartphone.html"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
                </div>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "4.4",
        heading: "Drunk Driving Introduction",
        content: (
          <>
            <p className={style.mediumBold}>
              Welcome to Chapter 4.4, Drunk Driving.
            </p>

            <p className={`${style.small} my-4`}>
              <strong className={style.smallBold}>
                Alcohol is one of the biggest causes of fatalities on the roads
                during the night.
              </strong>{" "}
              Every year thousands of South Africans die because of drunk
              drivers – research from World Statistics shows that 58% of
              alcohol-related road fatalities are from South Africa.
            </p>

            <p className={style.small}>
              <strong className={style.smallBold}>
                {" "}
                And this problem is more prevalent in the night-time.{" "}
              </strong>
              In this chapter you will learn about the implications of driving
              under the influence and the solutions to help counter it. Making
              it safer for you and those you share the road with.
            </p>
          </>
        ),
      },

      {
        number: "4.4.1",
        heading: "Tips: Drunk Driving",
        content: (
          <>
            <p className={style.mediumBold}>
              A total of 11 217 drunken driving arrests were recorded In 2022.
            </p>

            <ul className={`${style.specialBullets2} my-4 my-md-5`}>
              <li className={`${style.small24} mb-3`}>
                Don't drink and drive. Remember, your ability to drive is
                reduced even after I unit of alcohol.
              </li>
              <li className={`${style.small24} mb-3`}>
                The legal limit in South Africa is less than 0.5g per 100ml of
                blood.
              </li>
            </ul>

            <p className={style.medium}>
              Penalties For Driving Under The Influence Include
            </p>

            <ul className={`${style.specialBullets} mt-4 mt-md-5`}>
              <li className={`${style.small} mb-3`}>
                A minimum fine of R2000.
              </li>
              <li className={`${style.small} mb-3`}>
                Your driver's license may be suspended.
              </li>
              <li className={`${style.small} mb-3`}>Community service.</li>
              <li className={`${style.small} mb-3`}>Up to 6 years in jail.</li>
              <li className={`${style.small} mb-3`}>
                And you will have a criminal record for up to 10 years.
              </li>
            </ul>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={icon14}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p
                className={`${style.mediumBold} col-12 col-md-9 align-self-center`}
              >
                Be cautious when on medication. Even one unit of alcohol can
                impair your driving ability when mixed with medication.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "4.4.2",
        heading: "Tips: Use a Different Mode of Transport",
        content: (
          <>
            <p className={`${style.mediumBold} mb-4`}>
              When you're drinking, use a different mode of transport like an
              e-hailing service or have a designated driver.
            </p>

            <p className={style.smallBold}>
              If you’ve invited friends over for a social gathering (braai, to
              watch sports, or at a party) where alcohol consumption is likely,
              then always suggest they leave their cars at home, and use an
              e-hailing service to travel that evening.
            </p>
          </>
        ),
      },

      {
        number: "4.4.3",
        heading: "Tips: Use E-Hailing Services",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={icon15}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <p
                className={`${style.mediumBold} ${style.fontRegular} col-12 col-md-9 align-self-center mt-4 mt-md-0`}
              >
                Ensure you use reputable E-hailing services like Uber, Bolt, and
                DiDi to name a few.
              </p>
            </div>

            <ul className={`${style.specialBullets} mt-4 mt-md-5`}>
              <li className={`${style.small} mb-3`}>
                Share your live location with a friend when taking these
                services to ensure peace of mind.
              </li>

              <li className={`${style.small} mb-3`}>
                If you do drink and plan on driving the next day, ensure you
                give yourself enough time for alcohol to leave your system. It
                takes roughly 12 hours for alcohol to leave your blood. A few
                hours of sleep does not remove alcohol from your system.
              </li>

              <li className={style.small}>
                Responsible drinking can save many lives of the people around
                you. Know your limits. If you need assistance, there are
                helplines readily available like: Alcoholics Anonymous South
                Africa (AA). Visit www.aasouthafrica.org.za or contact them on
                086 143 5722.
              </li>
            </ul>
          </>
        ),
      },

      {
        id: "test",
        number: "4.4.4",
        heading: "Take The Chapter Test",
        content: children,
      },
    ];
  }

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`} id="section-2">
      <div className="row">
        <div className="col-md-3">
          <Progress data={data} chapterNumber="4" sectionRefs={sectionRefs} />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default Chapter4;
