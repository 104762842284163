import React, { useState } from "react";
import "./UserProfile.scss";
import MyDetails from "./MyDetails";
import Progress from "./Progress";
import LogoutButton from "./LogoutButton";

// Component: UserProfile
function UserProfile() {
  let anchorTag = document.URL.split("#")[1];
  const [activeTab, setActiveTab] = useState(anchorTag ? anchorTag : "profile");

  const renderContent = () => {
    switch (activeTab) {
      case "progress":
        return <Progress />;

      default:
        return <MyDetails />;
    }
  };

  return (
    <>
      {/* <div className="_user-profile-sphere"></div> */}
      <section className="_user-profile-tabs-section">
        <div className="mr-30 ml-30">
          <div className="_tabs d-flex justify-content-between row-gap-3 column-gap-3">
            <div className="d-flex w-100 justify-content-between justify-content-sm-start flex-wrap flex-sm-nowrap row-gap-3 column-gap-3">
              <a
                href="#profile"
                className={`_tab ${activeTab === "profile" && "_active"}`}
                onClick={() => setActiveTab("profile")}
              >
                Profile
              </a>
              <a
                href="#progress"
                className={`_tab ${activeTab === "progress" && "_active"}`}
                onClick={() => setActiveTab("progress")}
              >
                Progress
              </a>
            </div>
            <LogoutButton />
          </div>
        </div>
      </section>
      <section className="_user-profile-display-section">
        <div className="container">{renderContent()}</div>
      </section>
    </>
  );
}

export default UserProfile;
