import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import Cycle from "../../Assets/Chapters/Chapter6/DontGetIntheCarWithADrunkDriver.png";
import Truck from "../../Assets/Chapters/Chapter6/Truck.png";
import Blind from "../../Assets/Chapters/Chapter6/BlindspotAssist.png";
import Animal from "../../Assets/Chapters/Chapter6/Animal.png";
import Group from "../../Assets/Chapters/Chapter6/Group 1650.svg";
import { AppColorButton } from "../AppButton/AppButton";
import Progress from "../Progress/Progress";
import { Link } from "react-router-dom";
import video1 from "../../Assets/Chapters/Chapter6/Communicating brights 1.mp4";
import video2 from "../../Assets/Chapters/Chapter6/Communicating hazards 1.mp4";

import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";

const Chapter6 = ({ results, children }) => {
  const sectionRefs = useRef([]);

  let data = null;

  if (results) {
    data = [
      {
        number: "6.2.2",
        heading: "Chapter 6: Test Summary",
        content: children,
      },
    ];
  } else {
    data = [
      {
        number: "6.1",
        heading: "Pedestrians, other vehicles and wildlife Introduction",
        content: (
          <p className={`${style.small}`}>
            When driving on the road at night, you share the road with other
            road users. Like pedestrians, trucks, cyclists, bikers, wildlife,
            and livestock. A growing concern is pedestrian fatalities, with
            41.5% of pedestrian accidents happening between the hours of 18:00
            and 00:00.
          </p>
        ),
      },

      {
        number: "6.1.1",
        heading: "Tips: Other road users",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center text-md-end">
                <img
                  src={Cycle}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-sm-3 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Always stay alert on the roads for other road users – this
                  could be partygoers at night, nightshift workers, cyclists
                  etc.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "6.1.2",
        heading: "Tips: Trucks",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center">
                <img
                  src={Truck}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-sm-3 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  10.2% of fatal crashes involving trucks happen between 18:00
                  and 22:00 on Saturdays and Sundays.
                </p>
              </div>
            </div>

            <ul className={`${style.specialBullets} mt-4 mt-md-5`}>
              <li className={`${style.small} mb-3`}>
                When sharing the road with long-distance trucks, be cautious and
                patient – do not overtake when it’s not safe to do so.
              </li>

              <li className={style.small}>
                When going on holiday, avoid travelling at the most dangerous
                time (18:00 to 22:00) because long-distance trucks and tired
                travellers are on the road at the same time.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "6.1.3",
        heading: "Tips: Blind spot",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center">
                <img
                  src={Blind}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <div className="align-self-center col-12 col-md-9 mt-sm-3 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Always check your blind spots when driving on the road.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "6.1.4",
        heading: "Tips: Animals",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left text-sm-center">
                <img
                  src={Animal}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>
              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.mediumBold}`}>
                  Beware of any livestock that might be roaming around in the
                  streets at night.
                </p>
                <p className={`${style.small} mt-4`}>
                  -Take notice of warning signs   
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "6.2",
        heading: "Helping other drivers Introduction",
        content: (
          <p className={style.small}>
            We’re all on this road together, so let’s all try help each other
            where we can by alerting others to possible dangers on the road. In
            this chapter, we will share some key tricks you can do when helping
            other drivers on the roads at night.
          </p>
        ),
      },

      {
        number: "6.2.1",
        heading: "Tips: Flashing brights for hazards",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-6 text-center text-md-start">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={video1} type="video/mp4" />
                </video>
              </div>

              <div className="col-12 col-md-6 mt-5 mt-md-0 text-center text-md-end">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={video2} type="video/mp4" />
                </video>
              </div>
            </div>

            <ul className={`${style.specialBullets} mt-4 mt-md-5`}>
              <li className={`${style.small} mb-3`}>
                There will always be hazards on the road. Whether it’s an
                accident, broken down vehicle or animals in the road. If you are
                the hazard (for whatever reason), put your hazard lights on so
                other drivers can be aware and start slowing down.
              </li>
              <li className={`${style.small} mb-3`}>
                If you find yourself approaching a hazard, gently tap your
                brakes a few times to warn drivers behind you of a possible
                obstruction ahead.
              </li>
              <li className={`${style.small} mb-3`}>
                If there’s been a crash, give a slight flash to someone to warn
                them that they are heading towards a crash. But be aware of
                temporarily blinding other drivers with your flashes.
              </li>
              <li className={style.small}>
                Some traffic apps, like Waze, use crowd-sourced information to
                help give accurate updates. Where possible, safely provide
                information to aid other drivers.
              </li>
            </ul>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row">

              <div className="col-12 align-self-center">
                <h3 className={style.medium}>
                  Find out more about{" "}
                  <strong className={style.mediumBold}>
                    {" "}
                    Volkswagen’s IQ.DRIVE{" "}
                  </strong>
                  technology.
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-3"></div>
              <div className="pt-3 col-12 ">
                <Link
                  to="https://www.vw.co.za/en/innovation-technology/driver-assistance-systems.html"
                  target="_blank"
                >
                  <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                </Link>
              </div>
            </div>
          </>
        ),
      },

      {
        id: "test",
        number: "6.2.2",
        heading: "Take The Chapter Test",
        content: children,
      },
    ];
  }

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`} id="section-2">
      <div className="row">
        <div className="col-md-3">
          <Progress data={data} chapterNumber="6" sectionRefs={sectionRefs} />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default Chapter6;
