import React, { useContext, useState } from "react";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import style from "./ResetPassword.module.scss";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/General/vw-logo.svg";
import mailIcon from "../../Assets/General/email-icon.svg";
import { ThemeContext } from "../../Context/ThemeContext";
import apiService from "../../Utils/Services/ApiService";

// Component: ResetPassword
const ResetPassword = () => {
  const { theme } = useContext(ThemeContext);
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = () => {
    const data = {
      email,
    };

    apiService
      .resetPasswordEmail(data)
      .then((res) => {
        if (res.status === 200) {
          navigate("/reset-password-confirm-mail");
        } else {
          setResponse(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="container">
        <div className={`${style.forgotPasswordContainer} text-left`}>
          <div>
            <img
              src={Logo}
              alt="VW-logo"
              className={`${style.logoContainer}`}
            />
          </div>

          <div>
            <p className={`${style.header}`}>Forgot your password?</p>
          </div>

          <div className={`${style.forgotPasswordSentence}`}>
            <p className={`${style.description1} `}>
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </p>
          </div>

          <div className="d-flex align-item-center">
            <div className="w-100">
              <div>
                <p className={`${style.mandatoryFields}`}>*Mandatory Fields</p>
              </div>

              <div className="underLineInputGroup field col-12 col-md-6 mb-10">
                <div className={style.inputMainContainer}>
                  <input
                    type="email"
                    className={`${
                      theme === "light" && style.darkBorder
                    } underLineInputField pr-40`}
                    placeholder="Email Address*"
                    name="email"
                    required
                    onChange={handleOnChange}
                  />
                  <label className="underLineInputLabel">
                    Email Address<span className="redStar">*</span>
                  </label>

                  <div className={style.inputIconContainer}>
                    <img
                      src={mailIcon}
                      className={style.inputIcon}
                      alt="mailIcon"
                    />
                  </div>
                </div>
              </div>

              <div className={`${style.resetBtn}`}>
                <AppColorButton
                  buttonName={BUTTON_CONSTANT.RESEND_EMAIL}
                  handleClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
