import React, { useState, useEffect } from "react";
import style from "./MyDetails.module.scss";
import { AppBorderButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import apiService from "../../Utils/Services/ApiService";
import "../../Style/ui.scss";
import DatePicker from "react-datepicker";
import { BorderPencil } from "../Icons/BorderPencil";
import { Location } from "../Icons/Location";
import ErrorMessages from "../Messages/ErrorMessages";
import SuccessMessage from "../Messages/SuccessMessage";
import { Link, useNavigate } from "react-router-dom";
import logOutIcon from "../../Assets/Progress/logout-icon-small.svg";
import { userTokenMethod } from "../../Utils/Services/Common.Services";
import { OpenEye } from "../Icons/OpenEye";
import { ClosedEye } from "../Icons/ClosedEye";

// Component: MyDetails
const MyDetails = () => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(null);
  const [data, setData] = useState(null);
  const [errorsInfo, setErrorsInfo] = useState(null);
  const [successInfo, setSuccessInfo] = useState(null);
  const [errorsAccount, setErrorsAccount] = useState(null);
  const [successAccount, setSuccessAccount] = useState(null);
  const [showLabel, setShowLabel] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processingAccount, setProcessingAccount] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    apiService
      .fetchUserDetails()
      .then((res) => {
        setFormValues(res);
        setData(res);
        if (res && res.date_of_birth) {
          setShowLabel(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setErrorsInfo(null);
  //     setSuccessInfo(null);
  //     setErrorsAccount(null);
  //     setSuccessAccount(null);
  //   }, 5000);
  // }, [errorsInfo, successInfo, errorsAccount, successAccount]);

  const onSubmitInfo = () => {
    setProcessing(true);

    apiService
      .updateUserDetails(formValues)
      .then((res) => {
        if (res.status === 200) {
          setSuccessInfo("Personal information saved");
          setErrorsInfo(null);
          setErrorsAccount(null);
          setSuccessAccount(null);
          setData(res.details);
          setProcessing(false);
        } else if (res.status === 403) {
          setSuccessInfo(null);
          setErrorsInfo(res.errors);
          setErrorsAccount(null);
          setSuccessAccount(null);
          setData(res.details);
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  useEffect(() => {
    if (formValues && formValues.date_of_birth) {
      setShowLabel(true);
    }
  }, [showLabel, formValues]);

  // const onSubmitAccount = () => {
  //   setProcessing(true);
  //   if (!formValues.password || formValues.password === "........") {
  //     setErrorsInfo("please check your password");
  //     setSuccessInfo("please check your password");
  //     setProcessing(false);
  //   } else {
  //     apiService
  //       .updateUserDetails(formValues)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setSuccessInfo(null);
  //           setErrorsInfo(null);
  //           setErrorsAccount(null);
  //           setSuccessAccount("Account details saved");
  //           setProcessing(false);
  //           setData(res.details);
  //         } else if (res.status === 403) {
  //           setSuccessInfo(null);
  //           setErrorsInfo(null);
  //           setErrorsAccount(res.errors);
  //           setSuccessAccount(null);
  //           setData(res.details);
  //           setProcessing(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setProcessing(false);
  //       });
  //   }
  // };

  const onSubmitAccount = () => {
    setProcessingAccount(true);
    apiService
      .updateUserDetails(formValues)
      .then((res) => {
        if (res.status === 200) {
          setSuccessInfo(null);
          setErrorsInfo(null);
          setErrorsAccount(null);
          setSuccessAccount("Account details saved");
          setProcessingAccount(false);
          setData(res.details);
        } else if (res.status === 403) {
          setSuccessInfo(null);
          setErrorsInfo(null);
          setErrorsAccount(res.errors);
          setSuccessAccount(null);
          setData(res.details);
          setProcessingAccount(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setProcessingAccount(false);
      });
  };

  const handleChange = (e, type) => {
    if (type === "datepicker") {
      setFormValues({
        ...formValues,
        date_of_birth: e,
      });
    } else {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const HandleLogout = () => {
    apiService
      .logout()
      .then((res) => {
        userTokenMethod("delete");
        navigate("/home");
        window.location = `${process.env.PUBLIC_URL}/`; //needs to rerender for logged in state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <>
      <div className="">
        <div className={style.profileNameContainer}>
          <div
            className={`${style.profileImage} d-flex align-items-center justify-content-center`}
          >
            <h2 className={style.userShortName}>
              {data ? `${data.firstname[0]}.${data.surname[0]}` : ""}
            </h2>
          </div>

          <div>
            <h2 className={`${style.userName} pt-10 pb-10`}>
              Hello {data ? data.firstname : ""},
            </h2>
            <p className={`${style.userDescription} pt-10 pb-10`}>
              Welcome to your profile. You can view your profile details,
              progress on lessons, and your certificate.
            </p>
          </div>
        </div>

        <div className={style.headingContainer}>
          <h2 className={`${style.heading} mt-10 mb-10`}>
            Your Personal Information
          </h2>
          <p className={style.headingDescription}>
            Easily review and update your personal details.
          </p>
        </div>

        <div>
          <p className={style.mandatoryFieldsTitle}>*Mandatory Fields</p>
          <SuccessMessage message={successInfo} />
          <ErrorMessages errors={errorsInfo} />
          <div className="row g-2">
            <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
              <div className={style.inputMainContainer}>
                <input
                  type="text"
                  className="underLineInputField"
                  placeholder="First Name*"
                  name="firstname"
                  id="FirstName"
                  value={formValues ? formValues.firstname : ""}
                  onChange={handleChange}
                  required
                />
                <label className={`${style.labelColor} underLineInputLabel`}>
                  First Name<span className="redStar">*</span>
                </label>
              </div>
            </div>

            <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
              <div className={style.inputMainContainer}>
                <input
                  type="text"
                  className="underLineInputField"
                  placeholder="SurName*"
                  name="surname"
                  id="SurName"
                  value={formValues ? formValues.surname : ""}
                  onChange={handleChange}
                  required
                />
                <label className={`${style.labelColor} underLineInputLabel`}>
                  Surname<span className="redStar">*</span>
                </label>
              </div>
            </div>

            <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
              <div className={style.inputMainContainer}>
                <div className="customized-date-picker">
                  <DatePicker
                    type="date"
                    showIcon
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="22"
                        viewBox="0 0 26 22"
                        fill="none"
                      >
                        <path
                          d="M3.667 3.25005H22.333C22.6808 3.24817 23.0257 3.29771 23.348 3.39583C23.6703 3.49395 23.9637 3.63873 24.2114 3.8219C24.459 4.00507 24.6561 4.22305 24.7915 4.46337C24.9268 4.70369 24.9976 4.96166 25 5.22252V19.0276C24.9976 19.2884 24.9268 19.5464 24.7915 19.7867C24.6561 20.027 24.459 20.245 24.2114 20.4282C23.9637 20.6114 23.6703 20.7561 23.348 20.8543C23.0257 20.9524 22.6808 21.0019 22.333 21H3.667C3.31917 21.0019 2.97426 20.9524 2.65197 20.8543C2.32967 20.7561 2.03631 20.6114 1.78864 20.4282C1.54098 20.245 1.34385 20.027 1.20853 19.7867C1.07322 19.5464 1.00236 19.2884 1 19.0276V5.22252C1.00236 4.96166 1.07322 4.70369 1.20853 4.46337C1.34385 4.22305 1.54098 4.00507 1.78864 3.8219C2.03631 3.63873 2.32967 3.49395 2.65197 3.39583C2.97426 3.29771 3.31917 3.24817 3.667 3.25005Z"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.3335 1V5.49993"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.66675 1V5.49993"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 9H25"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    name="date_of_birth"
                    dateFormat="yyyy-MM-dd"
                    className="underLineInputField"
                    placeholder="Date Of Birth*"
                    onBlur={(e) => {
                      if (formValues && !formValues.date_of_birth) {
                        setShowLabel(false);
                      }
                    }}
                    onFocus={(e) => setShowLabel(true)}
                    onChange={(e) => handleChange(e, "datepicker")}
                    selected={
                      formValues && formValues.date_of_birth
                        ? new Date(formValues.date_of_birth)
                        : ""
                    }
                    maxDate={new Date()}
                  />
                </div>

                <label
                  className={`customizeUnderLineInputLabel underLineInputLabel ${
                    showLabel ? "top" : ""
                  }`}
                >
                  Date Of Birth<span className="redStar">*</span>
                </label>
              </div>
            </div>

            <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
              <div className={style.inputMainContainer}>
                <input
                  type="text"
                  className="underLineInputField pr-40"
                  placeholder="Location*"
                  name="location"
                  id="Location"
                  value={formValues ? formValues.location : ""}
                  onChange={handleChange}
                  required
                />
                <label className={`${style.labelColor} underLineInputLabel`}>
                  Location
                </label>

                <div className={style.inputIconContainer}>
                  <Location />
                </div>
              </div>
            </div>

            <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
              <div className={style.inputMainContainer}>
                <select
                  type="text"
                  className="underLineInputField"
                  name="gender"
                  id="Gender"
                  value={formValues ? formValues.gender : ""}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled selected hidden>
                    Gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <label className={`${style.labelColor} underLineInputLabel`}>
                  Gender
                </label>
              </div>
            </div>

            <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
              <div className={style.inputMainContainer}>
                <input
                  type="text"
                  className="underLineInputField"
                  placeholder="Location*"
                  name="vehicle"
                  id="VehicleOwn"
                  value={formValues ? formValues.vehicle : ""}
                  onChange={handleChange}
                  required
                />
                <label className={`${style.labelColor} underLineInputLabel`}>
                  What vehicle do you own?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className={style.buttonContainer}>
          {/* <div className="btn-group" role="group" aria-label="Basic example"> */}
          <AppBorderButton
            buttonName={
              processing ? BUTTON_CONSTANT.PROCESSING : BUTTON_CONSTANT.SAVE
            }
            handleClick={onSubmitInfo}
            disabled={processing ? true : false}
          />
          {/* </div> */}
        </div>

        <div>
          <h2 className={`${style.manageAccountHeading} pt-30 pb-10`}>
            Manage Your Account
          </h2>
          <p className={style.manageAccountDescription}>
            Easily manage your login credentials for a secure experience.
          </p>
        </div>

        <SuccessMessage message={successAccount} />
        <ErrorMessages errors={errorsAccount} />

        <div className="row">
          <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
            <div className={style.inputMainContainer}>
              <input
                type="email"
                className="underLineInputField pr-40"
                placeholder="Email Address*"
                name="email"
                id="Email"
                value={formValues ? formValues.email : ""}
                onChange={handleChange}
                required
              />
              <label className={`${style.labelColor} underLineInputLabel`}>
                Email Address<span className="redStar">*</span>
              </label>

              <div className={style.inputIconContainer}>
                <BorderPencil />
              </div>
            </div>
          </div>

          <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
            <div className={style.inputMainContainer}>
              <input
                type={showCurrentPassword ? "text" : "password"}
                className="underLineInputField pr-40"
                placeholder="Your Current Password*"
                name="password_current"
                id="Password_current"
                value={formValues ? formValues.password_current : ""}
                onChange={handleChange}
                required
              />
              <label className={`${style.labelColor} underLineInputLabel`}>
                Enter Your Old Password<span className="redStar">*</span>
              </label>

              <div
                className={style.inputIconContainer}
                onClick={toggleShowCurrentPassword}
              >
                {showCurrentPassword ? <OpenEye /> : <ClosedEye />}
              </div>
            </div>
          </div>

          <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
            <div className={style.inputMainContainer}>
              <input
                type={showPassword ? "text" : "password"}
                className="underLineInputField pr-40"
                placeholder="Your New Password*"
                name="password"
                id="Password"
                value={formValues ? formValues.password : ""}
                onChange={handleChange}
                required
              />
              <label className={`${style.labelColor} underLineInputLabel`}>
                Enter New Password<span className="redStar">*</span>
              </label>

              <div
                className={style.inputIconContainer}
                onClick={toggleShowPassword}
              >
                {showPassword ? <OpenEye /> : <ClosedEye />}
              </div>
            </div>
          </div>

          <div className="underLineInputGroup field mb-10 col-12 col-sm-6">
            <div className={style.inputMainContainer}>
              <input
                type={showPasswordConfirmation ? "text" : "password"}
                className="underLineInputField pr-40"
                placeholder="Confirm Your New Password*"
                name="password_confirmation"
                id="Password_confirmation"
                value={formValues ? formValues.password_confirmation : ""}
                onChange={handleChange}
                required
              />
              <label className={`${style.labelColor} underLineInputLabel`}>
                Confirm New Password<span className="redStar">*</span>
              </label>

              <div
                className={style.inputIconContainer}
                onClick={toggleShowPasswordConfirmation}
              >
                {showPasswordConfirmation ? <OpenEye /> : <ClosedEye />}
              </div>
            </div>
          </div>
        </div>

        <div className={style.buttonContainer}>
          <AppBorderButton
            buttonName={
              processingAccount
                ? BUTTON_CONSTANT.PROCESSING
                : BUTTON_CONSTANT.SAVE
            }
            handleClick={onSubmitAccount}
            disabled={processingAccount ? true : false}
          />
        </div>

        <div>
          <h5 className={`${style.deleteAccountHeading} pt-30 pb-10`}>
            Delete your Account
          </h5>
          <p className={style.deleteAccountDescription}>
            We are sad to see you go. If you would like to delete your account,
            please contact our support team to assist you in a few quick easy
            steps.
          </p>
        </div>

        <div className={style.buttonContainer}>
          <Link to="https://www.vw.co.za/en/help-and-support.html">
            <AppBorderButton buttonName={BUTTON_CONSTANT.CONTACT_US} />
          </Link>
          <div className=" d-block d-sm-none col-12 col-md-6 p-md-12 p-md-12 mt-4 mt-md-0 mt-10 mb-30">
            <div className="d-flex align-items-center">
              <button className={style.logOutButton} onClick={HandleLogout}>
                <img className={style.btnIcon} src={logOutIcon} alt="" />
                Log Out
              </button>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};
export default MyDetails;
