import React from "react";
import style from "./EmbarkAndAboutHeader.module.scss";
/*import Logo from "../../../Assets/General/night-school-led-logo.png";*/
import Logo from "../../../Assets/General/night-school-led-logo2.png";
import { AppColorButton, AppBorderButton } from "../../AppButton/AppButton";
import ScrollMore from "../../../Assets/General/scroll-more.svg";
import { useNavigate } from "react-router-dom";
import smoothScrollToSection from "../../../smoothScrollToSection";

// Component: EmbarkAndAboutHeader
export const EmbarkAndAboutHeader = (props) => {
  const {
    image,
    mobileImage,
    title,
    mobileTitle,
    mobileTitle2,
    description,
    firstButton,
    secondButton,
    token,
    flag,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className={style.headerContainer}>
        <div className="_header-overlay" />
        <div className={style.headerImageContainer}>
          <img
            src={image}
            className={style.homeHeaderImage}
            alt="mainHeaderImage"
          />
        </div>

        <div className={style.headerImageContainerMobile}>
          <img
            src={mobileImage}
            className={style.homeHeaderMobileImage}
            alt="HeaderImageMobile"
          />
        </div>

        <div className="container">
          <div className={style.nightSchoolLedLogo}>
            <img
              src={Logo}
              alt="nightSchoolLedLogo"
              className={style.logoImage}
            />
          </div>

          <div className={`${style.nightSchoolTitle} ${style.aboutTitle}`}>
            <div className="me-md-4">
              <div>
                <h2
                  className={`${style.firstTitle} ${
                    mobileTitle && `d-none d-sm-block`
                  }`}
                >
                  {title}
                </h2>

                {mobileTitle && (
                  <>
                    <h2 className={`${style.firstTitle} d-block d-sm-none`}>
                      {mobileTitle}
                    </h2>
                    <h2 className={`${style.firstTitle} d-block d-sm-none`}>
                      {mobileTitle2}
                    </h2>
                  </>
                )}

                <h2 className={style.secondTitle}>Night School</h2>
              </div>

              <div className="d-flex justify-content-center">
                <div className={style.descriptionContainer}>
                  <p
                    className={`${style.description} ${
                      flag && style.aboutWidth
                    }`}
                  >
                    {description}
                  </p>
                </div>
              </div>

              <div className="text-center">
                <div className={style.EnrolAndLoginBtn}>
                  <AppColorButton
                    buttonName={firstButton}
                    handleClick={() => {
                      navigate(token ? "/chapters/1" : "/signup");
                    }}
                  />
                  <AppBorderButton
                    buttonName={secondButton}
                    handleClick={() => {
                      navigate(token ? "/user-profile" : "/sign-in");
                    }}
                  />
                </div>

                <div className={style.scrollMoreIconContainer}>
                  <img
                    src={ScrollMore}
                    alt="ScrollMore"
                    className={style.scrollMoreIcon}
                    onClick={() => smoothScrollToSection("section-2", 100)}
                    style={{cursor: 'pointer'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
