import React, { useContext } from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import style from "./FooterSlogan.module.scss";
import { ThemeContext } from "../../Context/ThemeContext";
import { FOOTER_LINKS } from "../../Utils/Helper/Constants";

export const FooterSlogan = (props) => {
  const { container, FooterStyle, FooterCompanyName } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className={container}>
        <div className={style.fitContent}>
          <p className={style.sentenceContainer}>
            <span className={style.sentence}>
              <a href={FOOTER_LINKS.INTEGRITY_COMPLIANCE}>
                Integrity & Compliance |
              </a>
            </span>{" "}
            <span className={style.sentence}>
              <a href={FOOTER_LINKS.VW_SOUTH_AFRICA_TRANSFORMATION}>
                Volkswagen South Africa Transformation |{" "}
              </a>
            </span>{" "}
            <span className={style.sentence}>
              {" "}
              <a href={FOOTER_LINKS.LEGAL}>Legal | </a>
            </span>{" "}
            <span className={style.sentence}>
              {" "}
              <a href={FOOTER_LINKS.MANAGE_COOKIES}>Manage cookies | </a>
            </span>{" "}
            <span className={style.sentence}>
              {" "}
              <a href={FOOTER_LINKS.PRIVACY}>Privacy </a>
            </span>
          </p>

          <p
            className={`${style.borderBottom} ${FooterStyle} ${
              theme === "light" && style.darkBorderBottom
            }`}
          ></p>

          <div>
            <p
              className={`${style.companyName} ${
                theme === "light" && style.darkTitle
              }`}
            >
              <span className={FooterCompanyName}>V 1.0.1</span>
              <AiOutlineCopyrightCircle
                className={`${style.copyRightLogo} ${FooterCompanyName}`}
              />
              <span className={FooterCompanyName}>
                Volkswagen {new Date().getFullYear()}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
