import { useEffect, useState } from "react";

export const userTokenMethod = (methodType, data) => {
  if (methodType === "set") {
    localStorage.setItem(btoa("token"), data);
  } else if (methodType === "get") {
    return localStorage.getItem(btoa("token"));
  } else if (methodType === "delete") {
    localStorage.removeItem(btoa("token"));
  }
};

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
};

export const capitalizeFirstLetter = (str, defaultValue = "-") => {
  if (!str) {
    return defaultValue;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
