import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import style from "./ConfirmResetPassword.module.scss";
import ShowPassword from "./ShowPassword";
import Logo from "../../Assets/General/vw-logo.svg";
import { ThemeContext } from "../../Context/ThemeContext";
import apiService from "../../Utils/Services/ApiService";
import ErrorMessages from "../Messages/ErrorMessages";

// Component ConfirmResetPassword
const ConfirmResetPassword = () => {
  const { theme } = useContext(ThemeContext);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [imageButton, setImageButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [errorsInfo, setErrorsInfo] = useState(null);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setFormValues({
      code: searchParams.has("code") ? searchParams.get("code") : null,
    });
  }, []);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setProcessing(true);

    apiService
      .resetPassword(formValues)
      .then((res) => {
        if (res.status === 200) {
          navigate("/reset-password-success");
          setProcessing(false);
        } else {
          setErrorsInfo(res.errors);
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  return (
    <>
      <div className="container">
        <div className={`${style.forgotPasswordContainer} `}>
          <div>
            <img
              src={Logo}
              alt="VW-logo"
              className={`${style.logoContainer}`}
            />
          </div>
          <div>
            <p className={`${style.header}`}>Reset Your password</p>
          </div>

          <div className={`${style.forgotPasswordSentence}`}>
            <p className={`${style.description1}`}>
              Please enter a new password below, and confirm it before
              continuing.
            </p>
          </div>

          <div>
            <div>
              <p className={`${style.mandatoryFields}`}>*Mandatory Fields</p>
            </div>

            {errorsInfo && (
              <>
                <br />
                <ErrorMessages errors={errorsInfo} />
              </>
            )}

            <div className={`${style.inputFields}`}>
              <div className="underLineInputGroup field col-12 col-md-6 mb-10">
                <div className={style.inputMainContainer}>
                  <input
                    type={passwordType}
                    placeholder="New Password*"
                    className={`${
                      theme === "light" && style.darkBorder
                    } underLineInputField`}
                    name="password"
                    required
                    onChange={handleChange}
                  />
                  <label className="underLineInputLabel">
                    New Password<span className="redStar">*</span>
                  </label>

                  <ShowPassword
                    setImageButton={setImageButton}
                    passwordType={passwordType}
                    setPasswordType={setPasswordType}
                    imageButton={imageButton}
                    flag="password"
                  />
                </div>
              </div>

              <div>
                <p className={`${style.progressTitle}`}>
                  You can’t use a password you have used before.
                </p>
              </div>

              <div className="underLineInputGroup field col-12 col-md-6 mb-10">
                <div className={style.inputMainContainer}>
                  <input
                    type={confirmPasswordType}
                    // placeholder="Re-enter New Password*"
                    className={`${
                      theme === "light" && style.darkBorder
                    } underLineInputField`}
                    name="password_confirmation"
                    required
                    onChange={handleChange}
                  />
                  <label className="underLineInputLabel">
                    Re-enter New Password<span className="redStar">*</span>
                  </label>

                  <ShowPassword
                    setImageButton={setImageButton}
                    confirmPasswordType={confirmPasswordType}
                    setConfirmPasswordType={setConfirmPasswordType}
                    imageButton={imageButton}
                    flag="confirmPassword"
                  />
                </div>
              </div>

              <div>
                <p className={`${style.progressTitle}`}>
                  You can’t use a password you have used before.
                </p>
              </div>
            </div>
            <div className={`${style.resetPasswordBtn}`}>
              <AppColorButton
                buttonName={
                  processing
                    ? BUTTON_CONSTANT.PROCESSING
                    : BUTTON_CONSTANT.RESET_PASSWORD
                }
                handleClick={onSubmit}
                disabled={processing ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConfirmResetPassword;
