import axios from "axios";
import { BASE_URL } from "./Utils/Helper/ApiConstant";
import "./App.scss";
// import Footer from "./Components/Layout/Footer";
import Header from "./Components/Layout/Header";
import SideBar from "./Components/Layout/SideBar";
import { useRoutes, useLocation } from "react-router-dom";
import routes from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { ThemeContext } from "./Context/ThemeContext";
import { DARK_MODE_PAGE, LIGHT_MODE_PAGE } from "./Utils/Helper/Constants";
import { Helmet } from "react-helmet";
// import ReactDom from "react-dom";

// Component: App
function App() {
  const { pathname } = useLocation();
  const [integrator, setIntegrator] = useState({});
  const allPages = useRoutes(routes);

  useEffect(() => {
    //every new route must start at the top
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}integrator`)
      .then(function (res) {
        if (res.status === 200) {
          setIntegrator(res?.data);
        }
      })
      .catch(function (err) {
        // handle error
        console.log(err);
      });
  }, []);

  const isBrowserDefaulDark = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem("default-theme");
    const browserDefault = isBrowserDefaulDark() ? "dark" : "light";
    return localStorageTheme || browserDefault;
  };

  const [theme, setTheme] = useState(getDefaultTheme());

  useEffect(() => {
    if (theme === "light") {
      if (DARK_MODE_PAGE.includes(allPages?.props?.match?.pathname)) {
        setTheme("dark");
      }
    } else {
      if (LIGHT_MODE_PAGE.includes(allPages?.props?.match?.pathname)) {
        setTheme("light");
      }
    }
  });

  // const renderScripts = () => {
  //   if (!integrator.scripts) {
  //     return <></>;
  //   }
  //   return integrator.scripts.map((item, index) => {
  //     if (item === "") {
  //       return <></>;
  //     }
  //     return <script key={index}>{item}</script>;
  //   });
  // };

  return (
    <>
      {/* {ReactDom.createPortal(
        integrator ? integrator.head : "",
        document.querySelector("head")
      )} */}
      {/* {ReactDom.createPortal(renderScripts(), document.querySelector("body"))} */}
      <Helmet>
        {integrator.styles && integrator.styles[1] && (
          <style>{integrator.styles[1]}</style>
        )}
      </Helmet>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className={`theme-${theme}`}>
          <main>
            <Header />
            <SideBar />
            {allPages}
          </main>
          {/* <Footer /> */}
        </div>
      </ThemeContext.Provider>
      {integrator.footer && (
        <div
          className="_integrated-footer"
          dangerouslySetInnerHTML={{ __html: integrator.footer }}
        />
      )}
    </>
  );
}

export default App;
