import React, { useState, useEffect } from "react";
import style from "./Progress.module.scss";
import apiService from "../../Utils/Services/ApiService";
import { ProgressAccordionData } from "../../Utils/Helper/Constants";
import check from "../../Assets/Progress/check-circle-desk.svg";
import checkMobile from "../../Assets/Progress/circle-check.svg";
import userIcon from "../../Assets/Progress/LockBtn.svg";
import logOutIcon from "../../Assets/Progress/logout-icon-small.svg";
import certificate from "../../Assets/Progress/certificate-preview.png";
import CustomProgressBar, {
  ProgressType,
} from "../CustomProgressBar/CustomProgressBar";
import { useNavigate } from "react-router-dom";
import { userTokenMethod } from "../../Utils/Services/Common.Services";
import Pdf from "../Shared/PDF/Pdf";
import {Link} from 'react-router-dom'

// Component: Progress
const Progress = () => {
  const navigate = useNavigate();

  const [results, setResults] = useState(null);
  const [progress, setProgress] = useState(null);
  const [certificateAvailable, setCertificateAvailable] = useState(false);
  const [certificateData, setCertificateData] = useState({});

  useEffect(() => {
    apiService
      .fetchAllResults()
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    apiService
      .fetchProgress()
      .then((res) => {
        if (res.status === 200) {
          setProgress(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apiService
      .fetchChapterCertificate()
      .then((res) => {
        setCertificateData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (progress) {
      const { completed_chapters, total_chapters } = progress;
      setCertificateAvailable(
        completed_chapters[total_chapters - 1] ? true : false
      );
    }
  }, [progress]);

  const progressPercentage = () => {
    if (!progress) {
      return 0;
    }

    const { highest_completed_chapter_number, total_chapters } = progress;

    if (!highest_completed_chapter_number) {
      return 0;
    }

    return Math.floor(
      (highest_completed_chapter_number / total_chapters) * 100
    );
  };

  const navigation = (id) => {
    navigate(`/chapters/${id}`);
  };

  const HandleLogout = () => {
    apiService
      .logout()
      .then((res) => {
        userTokenMethod("delete");
        navigate("/home");
        window.location = `${process.env.PUBLIC_URL}/`; //needs to rerender for logged in state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderProgressItems = () => {
    if (!results) {
      return <></>;
    }

    if (!progress) {
      return <></>;
    }

    const { completed_chapters } = progress;

    return ProgressAccordionData.progressData.map((items, index) => {
      const completed = completed_chapters[index];

      const percentage = completed
        ? Math.floor((completed.correct / completed.total_questions) * 100)
        : null;

      let wrapUpShow =
        certificateAvailable && index === 6 && items?.title === "Wrap Up Video";

      return (
        <div key={`progress-${index}`}>
          <div className="accordion-item position-relative">
            <div
              className={`${
                style.progressAccordionTitle
              } accordion-button position-relative px-0 py-4 ${
                index === 0 ? "show" : "collapsed"
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#panelsStayOpen-collapseOne-${index}`}
              aria-expanded={index === 0 ? "true" : "false"}
              aria-controls={`panelsStayOpen-collapseOne-${index}`}
            >
              <div className={style.chapterTitle}>
                {wrapUpShow ? (
                  <>
                    <div>
                      <h2 className={style.chapterHeadingLight}>
                        {items?.title}
                      </h2>
                    </div>
                  </>
                ) : (
                  <>
                    {percentage > 99 ? (
                      <div
                        className={`${style.chapterHeadingBold} flex-1`}
                        onClick={() => navigation(index + 1)}
                      >
                        Chapter {index + 1}:&nbsp;
                      </div>
                    ) : (
                      <div
                        className={`${style.chapterHeadingLight} flex-1`}
                        onClick={() => navigation(index + 1)}
                      >
                        Chapter {index + 1}:&nbsp;
                      </div>
                    )}
                    <div>
                      <h2 className={style.chapterHeadingLight}>
                        {items?.title}
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={style.AccordionProgressNumber}>
              {completed ? (
                <>
                  <img
                    className={`mr-10 d-none d-sm-block ${style.imgSize}`}
                    src={check}
                    alt=""
                  />
                  <img
                    className={`mr-10 d-block d-sm-none ${style.imgSize}`}
                    src={checkMobile}
                    alt=""
                  />
                </>
              ) : (
                ""
              )}
              {percentage ? (
                <span
                  className={`d-none d-md-flex ${
                    percentage > 99 ? style.boldPercentage : style.percentage
                  }`}
                >
                  {" "}
                  {percentage}%
                </span>
              ) : (
                <></>
              )}
            </div>
            <div
              id={`panelsStayOpen-collapseOne-${index}`}
              className={`accordion-collapse collapse ${
                percentage || wrapUpShow ? "show" : "collapsed"
              }`}
            >
              {percentage ? (
                <CustomProgressBar
                  type={ProgressType.flat}
                  progressValue={percentage}
                />
              ) : (
                // prevent jump glitch
                <div style={{ height: "1px" }} />
              )}

              <div className={style.progressAccordionDesc}>
                <p className={`${style.description} mb-3 mb-sm-4`}>
                  {" "}
                  {items?.description}
                </p>
                <p className={style.subDescription}>{items?.subDescription}</p>
              </div>
            </div>
          </div>
          <p className={style.stroke}></p>
        </div>
      );
    });
  };

  //use this data for the certificate

  return (
    <>
      <div className={`${style.mainContainer} container p-0`}>
        <div className="d-md-flex align-items-center">
          <div className="mt-4 mt-md-0 pt-2 pt-md-0">
            <CustomProgressBar
              type={ProgressType.rounded}
              // progressValue={Math.round(circulerProgressValue)}
              progressValue={progressPercentage()}
            />
          </div>
          <div className="mt-4 mt-sm-0">
            <div className={`ms-md-5 ${style.progressContainer}`}>
              <div>
                <h2 className={style.progressTitle}>Your Progress</h2>
                <p className={`${style.progressDescription} mt-4`}>
                  Track your progress and review certificate.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion mt-md-2">
          {renderProgressItems()}

          <div className="accordion-item position-relative" id="certificate">
            <div
              className={`${
                style.progressAccordionTitle
              } accordion-button position-relative ${
                certificateAvailable ? "show" : "collapsed"
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#panelsStayOpen-collapseOne-Download`}
              aria-expanded="true"
              aria-controls={`panelsStayOpen-collapseOne-Download`}
            >
              <div className={`${style.chapterHeadingLight} mt-0`}>
                {" "}
                Certificate Download
              </div>
            </div>
            <div
              id={`panelsStayOpen-collapseOne-Download`}
              className={`accordion-collapse collapse ${
                certificateAvailable ? "show" : "collapsed"
              }`}
            >
              <div className={style.termsDesc}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    {certificateAvailable ? (
                      <>
                        <span className={`${style.certificateDownload}`}>
                          Well done! You've completed the Night School Advanced
                          Driving Course.
                        </span>
                        <br />
                        <br />
                        <span className={style.certificateTextDescription}>
                          Download your certificate or share it on your
                          preferred platform.
                        </span>
                      </>
                    ) : (
                      <>
                        <span className={`${style.certificateDownload}`}>
                          Your certificate awaiting!
                        </span>
                        <br />
                        <br />
                        <span className={style.certificateTextDescription}>
                          Please complete the Night School Advanced Driving
                          Course before being awarded with your certificate.
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className={style.certificateDownloadBox}>
                      <div
                        className={`d-flex justify-content-center  d-none d-md-flex ${style.certificateTitle}`}
                      >
                        <h2>Your Certificate</h2>
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          className={`w-md-50 mt-2 ${style.certificateImage}`}
                          src={certificate}
                          alt=""
                        />
                      </div>
                      <div className="row m-0 mt-4 text-center">
                        <div className="col-12 col-md-12 p-md-12 p-md-12 justify-content-md-end">
                          {progress?.total_chapters ===
                          progress?.completed_chapters?.length ? (
                            <Pdf
                              certificateData={certificateData}
                              style={style.downloadButton}
                            />
                          ) : (
                            <button className={style.downloadButton}>
                              <img
                                className={style.btnIcon}
                                src={userIcon}
                                alt=""
                              />
                              Download
                            </button>
                          )}
                        </div>
                        {/* <div className="col-12 col-md-6 p-md-12 p-md-12 mt-4 mt-md-0">
                          <button className={style.downloadButton}>
                            <img
                              className={style.btnIcon}
                              src={userIcon}
                              alt=""
                            />
                            Share Via
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div>
                  <div className={`${style.progressAccordionDesc} mt-0`}>
                    <p className={`${style.subDescription} mb-3 mb-sm-4`}>
                      VW reserves the right to change, modify, or revise these <Link
                          to="/terms-conditions"
                          target="_blank"
                          style={{
                            color: 'inherit',
                            textDecoration: 'none'
                          }}
                        >
                          <strong>terms and conditions</strong>
                        </Link> at any time. Continued use of the site after any such changes constitutes acceptance of the new Terms.
                    </p>
                    <br />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <p className={style.stroke}></p> */}
          <div className=" d-block d-md-none col-12 col-md-6 p-md-12 p-md-12 mt-4 mt-md-0 px-3 mt-10 mb-30">
            <div className="d-flex align-items-center">
              <button className={style.logOutButton} onClick={HandleLogout}>
                <img className={style.btnIcon} src={logOutIcon} alt="" />
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Progress;
