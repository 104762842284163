import React from "react";
import AboutVolkswagen from "../../Components/About/AboutVolkswagen";

// Component: About
const About = () => {
  return (
    <>
      <AboutVolkswagen />
    </>
  );
};
export default About;
