import React, { useContext, useState } from "react";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import style from "./ResetPassword.module.scss";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/General/vw-logo.svg";
import mailIcon from "../../Assets/General/email-icon.svg";
import { ThemeContext } from "../../Context/ThemeContext";
import apiService from "../../Utils/Services/ApiService";
import { AppBorderButton, AppColorButton } from "../AppButton/AppButton";
import ErrorMessages from "../Messages/ErrorMessages";

// Component: ResetPassword
const ResetPassword = () => {
  const { theme } = useContext(ThemeContext);
  const [code, setCode] = useState("");
  const [errorsInfo, setErrorsInfo] = useState(null);
  const navigate = useNavigate();
  const [confirming, setConfirming] = useState(false);
  const [resending, setResending] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const email = searchParams.has("email") ? searchParams.get("email") : null;

  useState(() => {
    if (searchParams.has("code")) {
      setCode(searchParams.get("code"));
    }
  }, []);

  const handleOnChange = (e) => {
    setCode(e.target.value);
  };

  const onSubmit = () => {
    setConfirming(true);

    const data = {
      code,
    };

    apiService
      .resetPasswordCodeCheck(data)
      .then((res) => {
        if (res.status === 200) {
          navigate(`/confirm-reset-password?code=${code}`);
          setConfirming(false);
        } else {
          setErrorsInfo(res.errors);
          setConfirming(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setConfirming(false);
      });
  };

  const resendCode = () => {
    setResending(true);

    const data = {
      email,
    };

    apiService
      .resetPasswordEmail(data)
      .then((res) => {
        if (res.status === 200) {
          setResending(false);
        } else {
          setResending(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setResending(false);
      });
  };

  return (
    <>
      <div className="container">
        <div className={`${style.forgotPasswordContainer} text-left`}>
          <div>
            <img
              src={Logo}
              alt="VW-logo"
              className={`${style.logoContainer}`}
            />
          </div>

          <div>
            <p className={`${style.header}`}>Verify It's You</p>
          </div>

          <div className={`${style.forgotPasswordSentence}`}>
            <p className={`${style.description1} `}>
              We have emailed your password reset code. Please enter it in the
              field below.
            </p>
          </div>

          <div className="d-flex align-item-center">
            <div className="w-100">
              <div>
                <p className={`${style.mandatoryFields}`}>*Mandatory Fields</p>
              </div>

              {errorsInfo && (
                <>
                  <br />
                  <ErrorMessages errors={errorsInfo} />
                </>
              )}

              <div className="underLineInputGroup field col-12 col-md-6 mb-10">
                <div className={style.inputMainContainer}>
                  <input
                    type="text"
                    className={`${
                      theme === "light" && style.darkBorder
                    } underLineInputField pr-40`}
                    placeholder="Code*"
                    name="code"
                    required
                    onChange={handleOnChange}
                    value={code}
                  />
                  <label className="underLineInputLabel">
                    Enter Code<span className="redStar">*</span>
                  </label>

                  <div className={style.inputIconContainer}>
                    <img
                      src={mailIcon}
                      className={style.inputIcon}
                      alt="mailIcon"
                    />
                  </div>
                </div>
              </div>

              <div className={`${style.resetBtn}`}>
                <AppColorButton
                  type={"submit"}
                  handleClick={onSubmit}
                  buttonName={confirming ? "Processing" : "Confirm"}
                  confirming={confirming ? true : false}
                />
                {email && (
                  <AppBorderButton
                    type={"submit"}
                    handleClick={resendCode}
                    buttonName={
                      resending ? "Resending..." : BUTTON_CONSTANT.RESEND_EMAIL
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
