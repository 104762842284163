import React, { useState } from "react";
import style from "./TermsConditions.module.scss";
import { TERMS_CONDITIONS } from "../../Utils/Helper/Constants";
import vwLogo from "../../Assets/General/vw-logoWhite.svg";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
import { Collapse } from "react-bootstrap";

// Terms & Condition
const TermsConditions = () => {
  const [openStates, setOpenStates] = useState(
    TERMS_CONDITIONS.TERMS.map(() => false)
  );

  const toggleCollapse = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  return (
    <>
      <div className={`${style.mains} container`}>
        <img src={vwLogo} alt="" className={style.logo} />

        <div className="">
          <p className={style.heading}>Terms and conditions</p>

          <p className={style.subHeading}>Volkswagen Night School</p>
          <p className={style.subHeading}>Terms and Conditions 2023</p>

          <p className={style.termsSentence}>I, the undersigned:</p>
        </div>

        <div className="accordion my-3 my-md-2">
          {TERMS_CONDITIONS.TERMS.map((items, index) => {
            return (
              <div className={style.termsContainer} key={items.title + index}>
                <div
                  onClick={() => toggleCollapse(index)}
                  aria-controls={`example-collapse-text-${index}`}
                  aria-expanded={openStates[index]}
                  className="d-flex align-items-center justify-content-between cursor-pointer"
                >
                  <p className={style.title}>{items?.title}</p>
                  <div>
                    {openStates[index] ? <TfiAngleUp /> : <TfiAngleDown />}
                  </div>
                </div>
                <Collapse in={openStates[index]}>
                  <div
                    className={style.termsDescription}
                    id={`example-collapse-text-${index}`}
                  >
                    <p className="my-4">
                      {items?.title === "Introduction" && items?.description}
                    </p>

                    <p className="my-4">
                      {items?.sentenceOne && (
                        <>
                          <p>{items?.sentenceOne}</p>

                          {items?.subSentencePartners?.map((sub, j) => (
                            <div key={j}>
                              <p className="my-3 px-4">{sub?.subSentenceOne}</p>
                              <p className="my-3 px-4">{sub?.subSentenceTwo}</p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceThree}
                              </p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceFour}
                              </p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceFive}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </p>

                    <p className="my-4">
                      {items?.sentenceTwo}{" "}
                      {items?.title === "Curriculum Costs" && (
                        <a href="https://www.vw.co.za/en/volkswagen-experience/driving-academy.html">
                          https://www.vw.co.za/en/volkswagen-experience/driving-academy.html
                        </a>
                      )}
                    </p>

                    <p className="my-3">
                      {items?.title === "Partners" && items?.description}
                    </p>

                    <p className="my-4">{items?.sentenceThree}</p>

                    <p className="my-4">
                      {items?.sentenceFour && (
                        <>
                          <p>{items?.sentenceFour}</p>

                          {items?.subSentenceEligibility?.map((sub, j) => (
                            <div key={j}>
                              <p className="my-3 px-4">{sub?.subSentenceOne}</p>
                              <p className="my-3 px-4">{sub?.subSentenceTwo}</p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceThree}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </p>
                    <p className="my-4">
                      {items?.title === "Reward Information" &&
                        items?.description}
                    </p>

                    <p className="my-4">
                      {items?.sentenceFive && (
                        <>
                          <p>{items?.sentenceFive}</p>

                          {items?.subSentenceReward?.map((sub, j) => (
                            <div key={j}>
                              <p className="my-3 px-4">{sub?.subSentenceOne}</p>
                              <p className="my-3 px-4">{sub?.subSentenceTwo}</p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceThree}
                              </p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceFour}
                              </p>
                              <p className="my-3 px-4">
                                {sub?.subSentenceFive}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </p>

                    <p className="my-4">{items?.sentenceSix}</p>
                    <p className="my-4">{items?.sentenceSeven}</p>
                    <p className="my-4">{items?.sentenceEight}</p>
                    {items?.title === "Reward Information" && (
                      <>
                        <p className="my-4">
                          {items?.sentenceEightDescription1}
                        </p>
                        <p className="my-4">
                          {items?.sentenceEightDescription2}
                        </p>
                        <p className="my-4">
                          {items?.sentenceEightDescription3}
                        </p>
                      </>
                    )}

                    <p className="my-4">
                      {items?.sentenceNine}{" "}
                      {items?.title === "General Terms" && (
                        <a href="https://nightschool.vw.co.za">
                          https://nightschool.vw.co.za
                        </a>
                      )}
                    </p>
                  </div>
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
