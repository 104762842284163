import React from "react";

export const BorderPencil = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M19.3877 4.20689L19.3872 4.2073L18.0624 5.53301C17.8281 5.76744 17.4481 5.7675 17.2137 5.53314L14.4627 2.78212C14.2284 2.54786 14.2284 2.16806 14.4625 1.93372L15.7879 0.607579L15.7881 0.607343C15.8537 0.541618 15.9316 0.489472 16.0173 0.453893C16.1031 0.418314 16.195 0.4 16.2879 0.4C16.3807 0.4 16.4727 0.418314 16.5584 0.453893C16.6442 0.489472 16.7221 0.541618 16.7876 0.607344L16.788 0.607666L19.3873 3.20703L19.3877 3.20735C19.4534 3.27293 19.5055 3.35083 19.5411 3.43658C19.5767 3.52234 19.595 3.61427 19.595 3.70712C19.595 3.79996 19.5767 3.8919 19.5411 3.97765C19.5055 4.06341 19.4534 4.14131 19.3877 4.20689ZM0.4 16.244C0.4 16.0849 0.463223 15.9323 0.575758 15.8197L11.8599 4.53674C12.0943 4.30237 12.4743 4.30247 12.7086 4.53696L15.4603 7.29112C15.6944 7.52547 15.6943 7.90522 15.4601 8.13946L4.17528 19.4243C4.06276 19.5368 3.91015 19.6 3.75102 19.6H1C0.668629 19.6 0.4 19.3314 0.4 19V16.244Z"
          stroke="white"
          strokeOpacity="0.8"
          strokeWidth="0.8"
        />
      </svg>
    </>
  );
};
