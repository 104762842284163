import {useEffect} from 'react'
import smoothScrollToSection from '../../smoothScrollToSection'

const ErrorMessages = ({errors, id}) => {

    useEffect(() => {
        smoothScrollToSection(id ? id : 'error-messages', 100, 200)
    }, [errors])

    if(!errors){
        return <></>
    }

    if (typeof errors === 'string') {
        return <></>
    }

    const renderErrorMessages = () => {
        return Object.values(errors).map((error, index) => {
            return (
                <div  className="alert alert-danger" role="alert" key={index}>
                {error}
                </div>
    
            )
        })
    }

    return (
        <div id={id ? id : 'error-messages'}>
            {renderErrorMessages()}
        </div>
    )
} 

export default ErrorMessages