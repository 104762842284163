import React from "react";
import style from "./HomeSkill.module.scss";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT, CARD_CONSTANT } from "../../Utils/Helper/Constants";
import { useNavigate } from "react-router-dom";
import CarLeftTaigo from "../../Assets/Home/car-left-taigo.jpg";
import mainHeaderImage from "../../Assets/Home/Generic_NightSchool.png";
import DottedImage from "../../Assets/General/mask-dotted-grid.png";
import percentage from "../../Assets/Home/icon-55.svg";

// Component: HomeSkill
const HomeSkill = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem(btoa("token"));

  return (
    <>
      <div className={style.neglectedHeader}>
        <div>
          <img
            src={DottedImage}
            alt="DottedImage"
            className={style.dottedImage}
          />
        </div>

        <div className="container" id="section-2">
          <div className="d-flex justify-content-center">
            <div className={`${style.percentageCard} row`}>
              <div className="col-12 col-md-6 ">
                <a href="https://www.rtmc.co.za/images/rtmc/docs/research_dev_rep/Driver intoxication and fatal crashes Report - March_2020.pdf">
                  <img
                    className={`${style.imagePercentage} p-0`}
                    src={percentage}
                    alt="percentage"
                  />
                </a>
              </div>

              <div className={`${style.secondColContainer} col-12 col-md-6`}>
                <p className={style.secondCol}>
                  of fatal car accidents happen at{" "}
                  <span className={style.redText}>night.</span>
                </p>
              </div>

              <div className="col-12 col-md-6">
                <p className={style.thirdCol}>
                  <p>
                    The importance <br className="d-none d-md-block" />
                    of <br className="d-md-none d-block" />
                    <span className={style.thirdColBoldText}>
                      Night-Time Driving
                    </span>
                  </p>
                </p>
              </div>

              <div className="col-12 col-md-6 d-flex align-items-center">
                <p className={style.forthCol}>
                  This stat shows that there is a significant gap in our driving
                  education. With the heightened risk at night, why do we keep
                  our driving behavior the same? As the sun goes down, risks go
                  up as the roads are filled with many challenges and dynamics
                  that drivers are often not equipped to deal with.
                  <p className="mt-10 mb-10">
                    That's why we're offering our driving experience when it
                    matters the most — night-time.
                  </p>
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className={`${style.nightDrivingPro} row`}>
              <div className="col-12 col-lg-6">
                <div className={style.nightDrivingProFirstCol}>
                  <h2 className={style.enrollTitle}>
                    Become A{" "}
                    <span className={style.enrollTitleBoldText}>
                      Night Driving Pro
                    </span>
                  </h2>

                  <p className={`${style.enrollDescription} pt-30`}>
                  Volkswagen’s expert curriculum covers topics ranging from
                    understanding the way your vehicle works, poor vision,
                    driver fatigue, recklessness, weather conditions and other
                    factors that compromise our ability to navigate safely after
                    dark. We cover everything you need to be a confident and
                    responsible night-time driver.
                  </p>
                  <p className={`${style.enrollDescription} pt-8 pb-30`}>
                    Join us on our mission to make South African roads safer,
                    regardless of the time of the day.
                  </p>

                  <div className={`${style.enrolBtn}`}>
                    <AppColorButton
                      buttonName={BUTTON_CONSTANT.ENROLL_NOW}
                      handleClick={() => {
                        navigate(token ? "/chapters/1" : "/signup");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 align-self-center">
                <h2 className={style.enrollTitleMobile}>
                  Become A{" "}
                  <span className={style.enrollTitleBoldText}>
                    Night Driving Pro
                  </span>
                </h2>

                <div className={style.nightDrivingProImage}>
                  <img
                    // src={CarLeftTaigo}
                    src={mainHeaderImage}
                    alt="Enroll"
                    className={style.enrollImage}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-100">
            <div className={`${style.enrollIconSection} row`}>
              {CARD_CONSTANT.ENROLL_SMALL_CARD_LIST.map((item, index) => (
                <div
                  className={`${style.EnrollSmallCard} col-12 col-md-4`}
                  key={item.title + index}
                >
                  <div className={style.enrollIcons}>
                    <img src={item?.image} alt="EnrollIcons" />
                  </div>

                  <div>
                    <h5 className={`${style.enrollIconTitle} pb-20 pt-md-20`}>
                      {item?.title}
                    </h5>
                    <p className={style.enrollIconDescription}>
                      {item?.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSkill;
