import httpCommon from "../../core/http.common.js";
import { API_CONSTANT } from "../Helper/ApiConstant.js";

const apiService = {
  register(data) {
    const url = API_CONSTANT.REGISTER;
    return httpCommon.post(url, data);
  },

  resendEmailVerificationEmail(email) {
    return httpCommon.post(`user/resend-email-verification/${email}`, {});
  },

  verifyAccount(email, token) {
    return httpCommon.post(`user/verify`, { email, token });
  },

  login(data) {
    const url = API_CONSTANT.LOGIN;
    return httpCommon.post(url, data);
  },

  resetPasswordEmail(data) {
    const url = API_CONSTANT.RESET_PASSWORD_EMAIL;
    return httpCommon.post(url, data);
  },

  resetPasswordCodeCheck(data) {
    const url = API_CONSTANT.RESET_PASSWORD_CODE_CHECK;
    return httpCommon.post(url, data);
  },

  resetPassword(data) {
    const url = API_CONSTANT.RESET_PASSWORD;
    return httpCommon.post(url, data);
  },

  logout() {
    const url = API_CONSTANT.LOGOUT;
    return httpCommon.post(url, {});
  },

  fetchUserDetails() {
    return httpCommon.get("user/details");
  },

  updateUserDetails(data) {
    return httpCommon.post("user/update", data);
  },

  fetchChapters() {
    return httpCommon.get("chapters");
  },

  fetchChapter(number) {
    return httpCommon.get(`chapters/${number}/quiz`);
  },

  submitChapterQuiz(number, data) {
    return httpCommon.post(`chapters/${number}/submit`, data);
  },

  fetchChapterResults(number) {
    return httpCommon.get(`chapters/${number}/results`);
  },

  fetchAllResults() {
    return httpCommon.get("chapters/results");
  },

  fetchProgress() {
    return httpCommon.get("progress");
  },

  fetchChapterCertificate() {
    return httpCommon.get(`certificate`);
  },
};

export default apiService;
