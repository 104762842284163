import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import img4 from "../../Assets/Chapters/Chapter2/image-4.jpg";
import img5 from "../../Assets/Chapters/Chapter2/image-5.jpg";
import img6 from "../../Assets/Chapters/Chapter2/image-6.png";
import img8 from "../../Assets/Chapters/Chapter2/image-8.jpg";
import img10 from "../../Assets/Chapters/Chapter2/image-10.png";
import img11 from "../../Assets/Chapters/Chapter2/image-11.png";
import icon2 from "../../Assets/Chapters/Chapter2/icon-2.svg";
import Group from "../../Assets/Chapters/Group-1459.svg";
import motionInducedBlindness from "../../Assets/Chapters/Chapter2/motion induced blindness square with background (1).mp4";
import NightBlindness from "../../Assets/Chapters/Chapter2/Night Blindness.mp4";
import AntiReflectiveLenses from "../../Assets/Chapters/Chapter2/Anti-Reflective-Lenses.mp4";
import video1 from "../../Assets/Chapters/Chapter2/mirror flick option 1 (1).mp4";
import video2 from "../../Assets/Chapters/Chapter2/Dirty Windscreen.mp4";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { Link } from "react-router-dom";
import Progress from "../Progress/Progress";

const Chapter2 = ({ results, children }) => {
  const sectionRefs = useRef([]);

  let data = null;

  if (results) {
    data = [
      {
        number: "2.18",
        heading: "Chapter 2: Test Summary",
        content: children,
      },
    ];
  } else {
    data = [
      {
        number: "2.0",
        heading: "Introduction",
        content: (
          <>
            <p className={`${style.mediumBold} mb-4 mt-4`}>
              Welcome to Chapter 2, Vision.{" "}
            </p>
            <p className={`${style.small} mb-4`}>
              <strong className={style.smallBold}>
                Approximately 90% of our daily activities require vision, and
                when it comes to driving, vision is clearly the most important
                of our senses.
              </strong>{" "}
              Poor vision and visibility are among the leading causes of
              accidents on South African roads. It’s your ability to see and be
              seen that could make the difference.
            </p>
            <p className={style.small}>
              In this chapter, you’ll learn about some of the common conditions
              that affect vision and eyesight while you drive at night, how
              lights affect your vision when you drive at night, and useful tips
              on how to overcome these.
            </p>
          </>
        ),
      },

      {
        number: "2.1",
        heading: "Your vision",
        content: (
          <>
            <p className={style.medium}>
              <strong className={style.mediumBold}>
                Your eyes are the most important factor when it comes to vision
                and safely driving at night.
              </strong>{" "}
              It’s vital for drivers to get their eyes tested regularly.
            </p>
          </>
        ),
      },

      {
        number: "2.2",
        heading: "Tips: Common conditions",
        content: (
          <>
            <p className={`${style.medium} mb-4`}>
              Driving at night already reduces visibility, but there are common
              conditions that do affect vision and eyesight including:
            </p>

            <ul className={`${style.specialBullets3}  ml-sm-30`}>
              <li className={style.medium}>
                Astigmatism, Motion Induced Blindness and Night Blindness.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "2.3",
        heading: "Tips: Astigmatism",
        content: (
          <>
            <p className={style.mediumBold}>
              Astigmatism is a defect in the eye that makes light distort.
            </p>

            <div className="row mt-4 mt-md-5">
              <div className="col-12 col-md-6 align-self-center text-center text-md-start">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                  preload="auto"
                >
                  <source src={AntiReflectiveLenses} type="video/mp4" />
                </video>
              </div>

              <div className="mb-3 align-self-center col-12 col-md-6">
                <ul className={`${style.specialBullets2} mb-0`}>
                  <li className={`${style.small24} mb-3`}>
                    This makes your vision{" "}
                    <strong className={style.small24Bold}>
                      blurry and distorted,
                    </strong>{" "}
                    affecting your{" "}
                    <strong className={style.small24Bold}>
                      depth perception at night.
                    </strong>
                  </li>

                  <li className={`${style.small24} mb-3`}>
                    It gets worse when it{" "}
                    <strong className={style.small24Bold}>rains</strong>, as the
                    wet roads become{" "}
                    <strong className={style.small24Bold}>
                      reflective surfaces.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.4",
        heading: "Tips: Motion induced Blindness ",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-6 align-self-center mb-4 mb-md-0 text-center text-md-start">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={motionInducedBlindness} type="video/mp4" />
                </video>
              </div>

              <div className="mb-3 align-self-center col-12 col-md-6">
                <ul className={`${style.specialBullets2} mb-0`}>
                  <li className={`${style.small24} mb-3`}>
                  Focus on the centre dot
                  </li>
                  <li className={`${style.small24} mb-3`}>
                  Did the cyclist, pedestrian, or child disappear?
                  </li>
                  <li className={`${style.small24} mb-3`}>
                  If so, you’ve just experienced Motion Induced Blindness
                  </li>
                  <li className={`${style.small24} mb-3`}>
                  Don’t let Motion Induced Blindness steer you in the wrong direction. Do not stare at a single object for more than a few seconds while driving
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.5",
        heading: "Tips: Night Blindness",
        content: (
          <>
            <div className="row">
              <div
                className={`${style.small} align-self-center col-12 col-md-6 order-2 order-md-1`}
              >
                <p className={style.medium}>
                  <strong className={style.mediumBold}>Night Blindness</strong>{" "}
                  is when the eye struggles to see in low light, removing colour
                  and definition, as well as depth perception and general
                  visibility.
                </p>
              </div>

              <div className="col-12 col-md-6 align-self-center order-1 order-md-2 mb-4 mb-md-0 text-center text-md-start">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={NightBlindness} type="video/mp4" />
                </video>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.6",
        heading: "Tips: Anti-reflective lenses",
        content: (
          <>
            {/*<p className={style.mediumBold}>
              Astigmatism is a defect in the eye that makes light distort.
            </p>*/}

            <div className="row  mt-4 mt-md-5">
              <div className="col-12 col-md-6 align-self-center text-center text-md-start">
                <img src={img4} alt="" className={style.imgRound} />
              </div>

              <div
                className={`${style.small} align-self-center px-md-5 p-0 mt-4 mt-md-0 col-12 col-md-6`}
              >
                <ul className={style.specialBullets}>
                  <li className={`${style.small} mb-3`}>
                    If you wear{" "}
                    <strong className={style.smallBold}>spectacles</strong> and
                    lights affect your vision, you can get a{" "}
                    <strong>filter</strong> on your glasses that{" "}
                    <strong className={style.smallBold}>
                      protects your eyesight
                    </strong>{" "}
                    from the glare of other lights.
                  </li>

                  <li className={`${style.small} mb-3`}>
                    This protective lens is called{" "}
                    <strong className={style.smallBold}>
                      Anti-reflective coating.
                    </strong>
                  </li>

                  <li className={style.small}>
                    Lights are not only essential for seeing at night but for{" "}
                    <strong className={style.smallBold}>
                      depth perception
                    </strong>{" "}
                    too. Which is a key part of{" "}
                    <strong className={style.smallBold}>
                      judging the distance and speed
                    </strong>{" "}
                    of oncoming vehicles and obstacles.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.7",
        heading: "Tips: Cleaning headlights",
        content: (
          <>
            <p className={style.mediumBold}>
              To ensure your lights are in optimal condition:
            </p>
            <div className="row mt-5">
              <div
                className={`${style.small} align-self-center px-md-5 p-0 col-12 col-md-6 order-2 order-md-1`}
              >
                <ul className={style.specialBullets2}>
                  <li className={`${style.small24} mb-3`}>
                    <strong className={style.small24Bold}>
                      Clean your headlights
                    </strong>{" "}
                    using baking soda and vinegar, toothpaste, or a headlight
                    restoration kit of your choice.
                  </li>

                  <li className={style.small24}>
                    Make sure that the{" "}
                    <strong className={style.small24Bold}>bulbs</strong> are in
                    good working order and replace them if need be.
                  </li>
                </ul>
              </div>

              <div className="p-0 col-12 col-md-6 order-1 order-md-2 align-self-center mb-4 mb-md-0">
                <img src={img5} alt="" className={style.imgRound} />
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.8",
        heading: "Tips: Oncoming headlights",
        content: (
          <>
            <div className="row mb-4 mb-md-5">
              <div className="col-12 col-md-4  text-left text-sm-center text-md-end">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={video1} type="video/mp4" />
                </video>
              </div>

              <div className="col-12 col-md-8 align-self-center mt-4 mt-md-0">
                <p className={style.mediumBold}>
                  Bright lights can distract and temporarily blind you, as well
                  as other drivers.
                </p>
              </div>
            </div>

            <ul className={style.specialBullets}>
              <li className={`${style.small} mb-3`}>
                Avoid looking directly at{" "}
                <strong className={style.smallBold}>
                  oncoming headlights.
                </strong>{" "}
                Take your eyes off a certain point and refocus them to look in
                the direction you want to go.
              </li>

              <li className={`${style.small} mb-3`}>
                If the car behind you has its brights on, and you have this type
                of rear-view mirror click your rear-view mirror to{" "}
                <strong className={style.smallBold}>
                  re-direct the lights
                </strong>{" "}
                and prevent them from distracting and harming your vision.
              </li>

              <li className={style.small}>
                Be conscious of other drivers and{" "}
                <strong className={style.smallBold}>
                  always turn your brights off
                </strong>{" "}
                when approaching another vehicle.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "2.9",
        heading: "Tips: Motorbike vs Car Lights",
        content: (
          <>
            <img className={`${style.imgRound} mb-4`} src={img6} alt="" />

            <p className={`${style.mediumBold} mb-4 mb-md-0`}>
              Lights not only help other vehicles see you but to identify you
              too.
            </p>

            <p className={style.small}>
              When you have two working lights, you appear as a car to other
              drivers. With this in mind,{" "}
              <strong className={style.smallBold}>
                always be careful when overtaking.
              </strong>{" "}
              Sometimes people have a car with one light working and might
              appear as a motorbike – check that you have enough space to
              overtake.
              <br />
              <br />
              When there's one light, you might appear as a motorbike in poor
              conditions.
            </p>
          </>
        ),
      },

      {
        number: "2.10",
        heading: "Tips: Dim dashboard lights",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-6 text-center text-md-start">
                <img src={img8} alt="" className={style.imgRound} />
              </div>
              <div className="align-self-center col-12 col-md-6 mt-4 mt-md-0">
                <p className={style.medium}>
                  <strong className={style.mediumBold}>
                    Dim your dashboard lights,
                  </strong>{" "}
                  as this helps remove any reflections on the windshield that
                  might decrease visibility and cause unnecessary distractions.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.11",
        heading: "Tips: Rest eyes",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-4 text-left text-sm-center">
                <img src={icon2} alt="" className={style.imgRound} />
              </div>

              <div className="align-self-center col-12 col-md-8">
                <p className={`${style.mediumBold} my-4 my-md-0`}>
                  Your eyes work harder and take more strain at night.
                </p>

                <p className={style.smallBold}>
                  To ensure your eyes are not being strained, stop regularly at
                  petrol stations and rest stops.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.12",
        heading: "Your ability to see",
        content: (
          <>
            <p className={style.medium}>
              Your{" "}
              <strong className={style.mediumBold}>
                windscreen/windshield
              </strong>{" "}
              is important because it provides the best visibility for you as a
              driver, enabling you to better observe the road and traffic
              conditions. There are a few vital things to make sure of regarding
              your windscreen when driving at night.
            </p>
          </>
        ),
      },

      {
        number: "2.13",
        heading: "Tips: Your windscreen",
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-6 align-self-center text-center text-md-start">
                <video
                  className={style.Video}
                  loop
                  controls
                  autoPlay={"autoplay"}
                >
                  <source src={video2} type="video/mp4" />
                </video>
              </div>

              <div
                className={`${style.medium} mt-4 mt-md-0 align-self-center col-12 col-md-6`}
              >
                <ul className={`${style.specialBullets} ${style.medium} mb-0`}>
                  <li className={`${style.small} mb-3`}>
                    Check your{" "}
                    <strong className={style.smallBold}>
                      windscreen and wiper blades
                    </strong>{" "}
                    for road worthiness.
                  </li>

                  <li className={`${style.small} mb-3`}>
                    Make sure your windscreen is{" "}
                    <strong className={style.smallBold}>not smudged.</strong>
                  </li>

                  <li className={`${style.small} mb-3`}>
                    Sometimes{" "}
                    <strong className={style.smallBold}>criminals</strong> put
                    something on your windscreen that smudges when you clean it,
                    forcing you out of your car. If you feel this might be the
                    case, seek assistance.
                  </li>

                  <li className={style.small}>
                    <strong className={style.smallBold}>Demist</strong> your
                    windscreen if there is frost or if the windows are fogged
                    up. Do this before you drive.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.14",
        heading: "Tips: Side mirrors",
        content: (
          <>
            <ul className={style.specialBullets3}>
              <li className={`${style.medium} mb-3`}>
                Check that your{" "}
                <strong className={style.mediumBold}>side mirrors</strong> are
                clear for you to see.
              </li>
              <li className={style.medium}>
                Volkswagen vehicles come with{" "}
                <strong className={style.mediumBold}>heated mirrors</strong> -
                simply switch it on and it clears ice or any condensation.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "2.15",
        heading: "Tips: Types of lights",
        content: (
          <>
            <p className={style.mediumBold}>
              Volkswagen IQ.Lights are designed for your safety on the roads.
            </p>

            <div className="row mt-5">
              <div className="align-self-center col-12 col-md-6 order-2 order-md-1 mt-4 mt-md-0">
                <ul className={style.specialBullets2}>
                  <li className={`${style.small24} mb-4`}>
                    They are{" "}
                    <strong className={style.small24Bold}>
                      intelligent lights
                    </strong>{" "}
                    that illuminate the road better than before, thus optimizing
                    the safety and comfort of the driver and passengers.
                  </li>

                  <li className={style.small24}>
                    The{" "}
                    <strong className={style.small24Bold}>
                      interactive LEDs
                    </strong>{" "}
                    ensure that the masking area for oncoming traffic and
                    vehicles driving in front is more precise than ever before.
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-6 order-1 order-md-2 align-self-center text-center text-md-start">
                <img src={img10} alt="" className={style.imgRound} />
              </div>
            </div>
          </>
        ),
      },

      {
        number: "2.16",
        heading: "Tips: Night Vision",
        content: (
          <>
            <div>
              <img className={style.imgRound} src={img11} alt="" />
            </div>

            <p className={`${style.mediumBold} my-4`}>
              The Volkswagen Touareg Comes With Night Vision Functionality.
            </p>

            <p className={style.small24}>
              The{" "}
              <strong className={style.small24Bold}>
                night vision function
              </strong>{" "}
              scans up to 130 metres using the infrared camera and reacts to any
              heat, warning you as you drive. People and animals can thus be
              detected earlier especially at night and in foggy weather.
            </p>

            <p className={`${style.mediumBold} my-4`}>
              Why And When To Use This:
            </p>

            <ul className={style.specialBullets}>
              <li className={`${style.small} mb-3`}>
                You can only see as much as your lights reveal.
              </li>

              <li className={style.small}>
                It comes in handy when you have{" "}
                <strong className={style.smallBold}>low visibility,</strong>{" "}
                like load shedding, in rural areas, and areas with no
                streetlights.
              </li>
            </ul>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row">
            
              <div className="align-self-center col-12  mt-4 mt-md-0">
                <h2 className={`${style.large} ${style.fontWeight700}`}>
                  Volkswagen's IQ.Light Assist{" "}
                </h2>
                <h2 className={`${style.large} mt-3`}>
                  designed for your safety on the road.
                </h2>
                <div className="mt-4">
                  <Link
                    to="https://www.vw.co.za/en/innovation-technology/driver-assistance-systems.html"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
                </div>
              </div>
            </div>
          </>
        ),
      },

      {
        id: "test",
        number: "2.17",
        heading: "Take The Chapter Test",
        content: children,
      },
    ];
  }

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <div key={i}>
          <ChapterSection
            data={obj}
            key={obj.number}
            border={i !== data.length - 1}
            handleRef={handleRef}
          />
        </div>
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`} id="section-2">
      <div className="row">
        <div className="col-md-3">
          <Progress data={data} chapterNumber="2" sectionRefs={sectionRefs} />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default Chapter2;
