import React, { useContext, useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Link, Navigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import style from "./Signup.module.scss";
import ShowPassword from "../../Components/Auth/ShowPassword";
import apiService from "../../Utils/Services/ApiService";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { MESSAGE } from "../../Utils/Helper/Message";
import { ThemeContext } from "../../Context/ThemeContext";
import mailIcon from "../../Assets/General/email-icon.svg";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { AppColorButton } from "../AppButton/AppButton";
import ErrorMessages from "../Messages/ErrorMessages";

const SignupForm = ({ page }) => {
  const recaptcha = useRef();
  const formRef = useRef();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [imageButton, setImageButton] = useState(true);
  const [registerData, setRegisterData] = useState({});
  const { theme } = useContext(ThemeContext);
  const [showLabel, setShowLabel] = useState(false);
  const [errorsInfo, setErrorsInfo] = useState(null);
  const [processing, setProcessing] = useState(false);

  console.log("page", page);

  useEffect(() => {
    if (formRef?.current?.values?.date_of_birth) {
      setShowLabel(true);
    }
  }, [showLabel]);

  const gRecaptchaSiteKey = process.env.REACT_APP_G_RECAPTCHA_SITE_KEY;

  const initialValues = {
    firstname: "",
    surname: "",
    date_of_birth: "",
    // province: "",
    email: "",
    password: "",
    password_confirmation: "",
    volkswagen_owner: "",
    term_and_conditions: "",
    subscribe: "",
    origin: "KMI",
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .trim(MESSAGE.FIRST_NAME)
      .strict(true)
      .required(MESSAGE.FIRST_NAME),
    surname: Yup.string()
      .trim(MESSAGE.FIRST_NAME)
      .strict(true)
      .required(MESSAGE.SUR_NAME),
    // province: Yup.string().required(MESSAGE.STATE),
    date_of_birth: Yup.string().required(MESSAGE.DATE_VALID),
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
    password: Yup.string().required(MESSAGE.PASSWORD_REQUIRED),
    password_confirmation: Yup.string()
      .required(MESSAGE.CONFIRM_PASSWORD_REQUIRED)
      .oneOf([Yup.ref("password")], MESSAGE.PASSWORD_MATCH),
  });

  const handleSubmit = (values) => {
    if (!values.term_and_conditions) {
      setErrorsInfo({
        terms_and_conditions: "Please accept the Terms And Conditions",
      });
    }

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      setErrorsInfo({
        "g-recaptcha": "Please verify the reCAPTCHA!",
      });
      return;
    }

    setProcessing(true);

    const data = {
      ...values,
      g_recaptcha_token: captchaValue,
    };

    apiService
      .register(data)
      .then((res) => {
        if (res.status === 200) {
          setRegisterData(res);
          setProcessing(false);
        } else {
          setRegisterData(res);
          setErrorsInfo(res.errors);
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  const { success, details } = registerData;

  if (success) {
    return (
      <Navigate
        to={`/confirm-mail${details ? "?email=" + details?.email : ""}`}
      />
    );
  }

  return (
    <>
      {/*=========================== Main ============================*/}
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={`${style.rowContainer} row`}>
              <div className={style.signUpContainer}>
                {page === "registration" && (
                  <>
                    <p className={style.sign1}>Registration</p>
                    <div className={`${style.registerNavigation} pt-22`}>
                      <p className={style.account}>
                        Already a NightSchooler?
                        <span className={style.account}>
                          {" "}
                          Log in{" "}
                          <Link to="/sign-in" className={style.here}>
                            here.
                          </Link>
                        </span>
                      </p>
                    </div>
                  </>
                )}
                <div className="row" style={{ paddingTop: "43px" }}>
                  <div className="col-md-12">
                    <div className={style.inputField}>
                      <p className={style.fields}>*Mandatory fields</p>
                    </div>
                  </div>

                  {errorsInfo && (
                      <div className="col-md-12">
                        <br />
                        <ErrorMessages errors={errorsInfo} />
                      </div>
                    )}

                  {/* First Name */}
                  <div className="col-md-6">
                    <div className={style.inputField}>
                      <div className={style.all}>
                        <div className="underLineInputGroup w-100 field mb-10">
                          <div className={style.inputMainContainer}>
                            <input
                              type="text"
                              className={`${
                                theme === "light" && style.darkBorder
                              } underLineInputField`}
                              placeholder="First Name*"
                              name="firstname"
                              required
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values?.firstname}
                            />
                            <label className="underLineInputLabel">
                              First Name<span className="redStar">*</span>
                            </label>

                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className="errorMessage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Surname */}
                  <div className="col-md-6">
                    <div className={style.inputField}>
                      <div className={style.all}>
                        <div className="underLineInputGroup w-100  field mb-10">
                          <div className={style.inputMainContainer}>
                            <input
                              type="text"
                              className={`${
                                theme === "light" && style.darkBorder
                              } underLineInputField`}
                              placeholder="Surname*"
                              name="surname"
                              required
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values?.surname}
                            />
                            <label className="underLineInputLabel">
                              Surname<span className="redStar">*</span>
                            </label>

                            <ErrorMessage
                              name="surname"
                              component="div"
                              className="errorMessage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* email */}
                  <div className="col-md-6">
                    <div className={style.inputField}>
                      <div className={style.all}>
                        <div className="underLineInputGroup w-100  field mb-10">
                          <div className={style.inputMainContainer}>
                            <input
                              type="email"
                              className={`${
                                theme === "light" && style.darkBorder
                              } underLineInputField pr-40`}
                              placeholder="Email Address*"
                              name="email"
                              required
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values?.email}
                            />
                            <label className="underLineInputLabel">
                              Email Address<span className="redStar">*</span>
                            </label>

                            <div className={style.inputIconContainer}>
                              <img
                                src={mailIcon}
                                className={style.inputIcon}
                                alt="mailIcon"
                              />
                            </div>

                            <ErrorMessage
                              name="email"
                              component="div"
                              className="errorMessage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Date Of Birth */}
                  <div className="col-md-6">
                    <div className={style.inputField}>
                      <div className={style.all}>
                        <div className="underLineInputGroup w-100  field mb-10">
                          <div className={style.inputMainContainer}>
                            <div className="customized-date-picker">
                              <DatePicker
                                type="date"
                                showIcon
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="22"
                                    viewBox="0 0 26 22"
                                    fill="none"
                                  >
                                    <path
                                      d="M3.667 3.25005H22.333C22.6808 3.24817 23.0257 3.29771 23.348 3.39583C23.6703 3.49395 23.9637 3.63873 24.2114 3.8219C24.459 4.00507 24.6561 4.22305 24.7915 4.46337C24.9268 4.70369 24.9976 4.96166 25 5.22252V19.0276C24.9976 19.2884 24.9268 19.5464 24.7915 19.7867C24.6561 20.027 24.459 20.245 24.2114 20.4282C23.9637 20.6114 23.6703 20.7561 23.348 20.8543C23.0257 20.9524 22.6808 21.0019 22.333 21H3.667C3.31917 21.0019 2.97426 20.9524 2.65197 20.8543C2.32967 20.7561 2.03631 20.6114 1.78864 20.4282C1.54098 20.245 1.34385 20.027 1.20853 19.7867C1.07322 19.5464 1.00236 19.2884 1 19.0276V5.22252C1.00236 4.96166 1.07322 4.70369 1.20853 4.46337C1.34385 4.22305 1.54098 4.00507 1.78864 3.8219C2.03631 3.63873 2.32967 3.49395 2.65197 3.39583C2.97426 3.29771 3.31917 3.24817 3.667 3.25005Z"
                                      stroke="#010620"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M18.3335 1V5.49993"
                                      stroke="#010620"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.66675 1V5.49993"
                                      stroke="#010620"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M1 9H25"
                                      stroke="#010620"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                }
                                name="date_of_birth"
                                dateFormat="yyyy/MM/dd"
                                className={`${
                                  theme === "light" && style.darkBorder
                                } underLineInputField`}
                                placeholder="Date Of Birth*"
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShowLabel(false);
                                }}
                                onFocus={(e) => setShowLabel(true)}
                                onChange={(e) => {
                                  setFieldValue("date_of_birth", e);
                                }}
                                selected={values?.date_of_birth}
                                maxDate={new Date()}
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                              />
                            </div>

                            <label
                              className={`customizeUnderLineInputLabel underLineInputLabel ${
                                showLabel ? "top" : ""
                              }`}
                            >
                              Date Of Birth<span className="redStar">*</span>
                            </label>

                            <ErrorMessage
                              name="date_of_birth"
                              component="div"
                              className="errorMessage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Password */}
                  <div className="col-md-6">
                    <div className={style.inputField}>
                      <div className={style.all}>
                        <div className="underLineInputGroup w-100  field mb-10">
                          <div className={style.inputMainContainer}>
                            <input
                              type={passwordType}
                              placeholder="Password*"
                              className={`${
                                theme === "light" && style.darkBorder
                              } underLineInputField`}
                              name="password"
                              required
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values?.password}
                            />
                            <label className="underLineInputLabel">
                              Password<span className="redStar">*</span>
                            </label>

                            <ShowPassword
                              setImageButton={setImageButton}
                              passwordType={passwordType}
                              setPasswordType={setPasswordType}
                              imageButton={imageButton}
                              flag="password"
                              page="embark"
                            />

                            <ErrorMessage
                              name="password"
                              component="div"
                              className="errorMessage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Password Confirmation */}
                  <div className="col-md-6">
                    <div className={style.inputField}>
                      <div className={style.all}>
                        <div className="underLineInputGroup w-100  field mb-10">
                          <div className={style.inputMainContainer}>
                            <input
                              type={confirmPasswordType}
                              placeholder="Confirm Password*"
                              className={`${
                                theme === "light" && style.darkBorder
                              } underLineInputField`}
                              name="password_confirmation"
                              required
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values?.password_confirmation}
                            />
                            <label className="underLineInputLabel">
                              Confirm Password
                              <span className="redStar">*</span>
                            </label>

                            <ShowPassword
                              setImageButton={setImageButton}
                              passwordType={confirmPasswordType}
                              setPasswordType={setConfirmPasswordType}
                              imageButton={imageButton}
                              flag="password_confirmation"
                              page="embark"
                            />

                            <ErrorMessage
                              name="password_confirmation"
                              component="div"
                              className="errorMessage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className={`${style.checkboxLabel} mt-4 mb-3`}>
                      <input
                        type="checkbox"
                        name="term_and_conditions"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.term_and_conditions}
                        className={style.roundCheckbox}
                      />
                      <p>
                        I have read and agreed to the{" "}
                        <Link
                          to="/terms-conditions"
                          className={style.termsAndConditionRedirect}
                          target="_blank"
                        >
                          terms and conditions
                        </Link>
                      </p>
                    </label>
                    <ErrorMessage
                      name="term_and_conditions"
                      component="div"
                      className="errorMessage"
                    />

                    <label className={`${style.checkboxLabel} mt-3 mb-4`}>
                      <input
                        type="checkbox"
                        name="subscribe"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.subscribe}
                        className={style.roundCheckbox}
                      />
                      I would like to opt into marketing communication.
                    </label>

                    <div className={`${style.captcha} mt-2 mb-5`}>
                      <ReCAPTCHA
                        className="g-recaptcha"
                        ref={recaptcha}
                        sitekey={
                          gRecaptchaSiteKey
                            ? gRecaptchaSiteKey
                            : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        }
                      />
                    </div>

                    <div className="mt-5">
                      {page === "registration" ? (
                        <AppColorButton
                          buttonName={
                            processing
                              ? BUTTON_CONSTANT.PROCESSING
                              : BUTTON_CONSTANT.REGISTER
                          }
                          type={"submit"}
                          disabled={processing ? true : false}
                        />
                      ) : (
                        <AppColorButton
                          buttonName={
                            processing
                              ? BUTTON_CONSTANT.PROCESSING
                              : BUTTON_CONSTANT.ENROLL_NOW
                          }
                          type={"submit"}
                          disabled={processing ? true : false}
                          arrow={processing ? false : true}
                        />
                      )}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
