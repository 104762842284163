const smoothScrollToSection = (elementId, timeout = 1000, space = 80) => {
    setTimeout(() => {
        const element = document.getElementById(elementId);
        if(element){
            const y = element.getBoundingClientRect().top + window.scrollY + -(space);
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, timeout);
}

export default smoothScrollToSection