import React from "react";
import style from "./Error404.module.scss";
import { AppColorButton } from "../../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../../Utils/Helper/Constants";
import E5 from "../../../Assets/Error/404_icon.svg";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <>
      <div className="container">
        <div className={`${style.mainContainer} text-center`}>
          {/* image icon  */}
          <div className={style.iconContainer}>
            <img src={E5} alt="Error500icon" className={style.logo500} />
          </div>
          {/* 500 error text */}
          <div className={`${style.errorContainer} text-center `}>
            <p className={style.firstLine}>404</p>
            <p className={style.secondLine}>ERROR</p>
          </div>
          <div className={style.offContainer}>
            <p className={style.offRoad}>Off-Road!</p>

            <div className="d-flex justify-content-center">
              <p className={style.description}>
                We are not sure how you got here… Let's get you back on track:
              </p>
            </div>
            <div className={`${style.contactBtn} text-center `}>
              <Link to="/home">
                <AppColorButton buttonName={BUTTON_CONSTANT.VW_NIGHT_SCHOOL} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
