// import React, { useEffect, useRef, useState } from "react";
import style from "./EmbarkVideo.module.scss";
// import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
// import DatePicker from "react-datepicker";
// import { AppColorButton } from "../AppButton/AppButton";
// import mailIcon from "../../Assets/General/mail.svg";
import DottedImage from "../../Assets/General/dotted-grid.png";
import { useNavigate } from "react-router-dom";
// import { ErrorMessage, Formik } from "formik";
// import { MESSAGE } from "../../Utils/Helper/Message";
// import * as Yup from "yup";
import SignupForm from "../../Components/Auth/SignupForm";
import { userTokenMethod } from "../../Utils/Services/Common.Services";
import { AppColorButton } from "../AppButton/AppButton";

// Component: NightSchoolVideo
const EmbarkVideo = () => {
  const navigate = useNavigate();
  // const formRef = useRef();
  // const [showLabel, setShowLabel] = useState(false);
  // const token = localStorage.getItem(btoa("token"));
  // const initialValues = {
  //   firstName: "",
  //   surName: "",
  //   date_of_birth: "",
  //   email: "",
  // };
  // useEffect(() => {
  //   if (formRef.current.values.date_of_birth) {
  //     setShowLabel(true);
  //   }
  // }, [showLabel]);
  // const validationSchema = Yup.object().shape({
  //   firstName: Yup.string()
  //     .trim(MESSAGE.FIRST_NAME)
  //     .strict(true)
  //     .required(MESSAGE.FIRST_NAME),
  //   surName: Yup.string()
  //     .trim(MESSAGE.FIRST_NAME)
  //     .strict(true)
  //     .required(MESSAGE.SUR_NAME),
  //   date_of_birth: Yup.string().required(MESSAGE.DATE_VALID),
  //   email: Yup.string()
  //     .email(MESSAGE.EMAIL_VALID)
  //     .max(255)
  //     .required(MESSAGE.EMAIL_REQUIRED),
  // });
  // const handleSubmit = (values) => {
  //   console.log("values", values);
  //   navigate(token ? "/chapters/1" : "/signup");
  // };

  return (
    <>
      <div className="position-relative overflow-hidden">
        <div className="gradientContainer"></div>
        <div className="container">
          <div className="text-center">
            <div className="pt-30 pb-30">
              <h2 className={style.videoHeading}>
                Let’s Get Night Time Certified With
              </h2>
              <h2 className={style.videoHeadingBoldText}>Night School!</h2>
            </div>
            <div>
              {/* <iframe
                className={`videoYT ${style.introVideo}`}
                title="youtube"
                width="100%"
                src="https://www.youtube.com/embed/86Hfgt-inLk?si=rQceVX-b7tCjYl0G"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe> */}
              <video
                  className={`videoYT ${style.introVideo}`}
                  width="100%"
                  loop
                  controls
                  // autoPlay={"autoplay"}
                  preload="auto"
                >
                  <source src="https://nightschool-api.vw.co.za/videos/VW Night School – Intro.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div
            className={`${style.videoTranscriptHeader} d-flex justify-content-center`}
          >
            <div className={style.videoTranscriptHeaderBlock}>
              {/* <div className="d-flex align-items-center justify-content-between pt-10 pb-30">
                <p className={style.videoTranscriptHeading}>Video Transcript</p>
                <div
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  {open ? (
                    <TfiAngleUp className={style.arrowIcons} />
                  ) : (
                    <TfiAngleDown className={style.arrowIcons} />
                  )}
                </div>
              </div> */}
              {/* <Collapse in={open}>
                <div className={style.videoTranscriptBody}>
                  {VIDEO_TRANSCRIPT.NIGHT_SCHOOL_TRANSCRIPT_DATA.map(
                    (item, index) => (
                      <div key={item.title + index}>
                        <h5 className={`${style.videoTranscriptTitle} pt-12`}>
                          {item?.title}
                        </h5>
                        <p
                          className={`${style.videoTranscriptDescription} mt-2 mb-4 `}
                        >
                          {item?.description}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </Collapse> */}
            </div>
          </div>
        </div>

        <div>
          <div className={style.bottomBanner}>
            <div>
              <img
                src={DottedImage}
                alt="DottedImage"
                className={style.dottedBottomImage}
              />
            </div>
            <div className={style.readyToBeginWrap}>
              <div className="container">
                <div className={style.bottomBannerContainer}>
                  <div>
                    <h2 className={style.bottomBannerSloganHeading}>
                      Ready to begin?
                    </h2>
                  </div>
                  <div className={style.bottomBannerSloganDescriptionContainer}>
                    <p className={style.bottomBannerSloganDescription}>
                      Your journey towards confident and safe night driving
                      starts here. Click the button below to embark on a
                      learning adventure that could redefine your driving
                      experience.
                    </p>
                  </div>
                  {!userTokenMethod("get") && <SignupForm page="embark" />}
                  {userTokenMethod("get") && (
                    <div className={style.EnrolAndLoginBtn}>
                      <AppColorButton
                        buttonName="Enroll Now"
                        handleClick={() => {
                          navigate("/chapters/1");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmbarkVideo;
