import { useContext, useEffect, useRef } from "react";
import style from "./ChapterSection.module.scss";
import { ThemeContext } from "../../Context/ThemeContext";

const ChapterSection = ({ data, border, handleRef }) => {
  const { number, heading, content, id } = data;
  const sectionRef = useRef();
  const { theme } = useContext(ThemeContext);

  console.log("theme", theme);

  useEffect(() => {
    if (handleRef) {
      handleRef(sectionRef);
    }
  }, [sectionRef]);

  return (
    // id used for progress bar, has to be a number otherwise it must be null
    <div id={number ? number : null} ref={sectionRef}>
      <div className={style.section} id={id ? id : ""}>
        {heading && (
          <h2 className={`${style.heading} mb-30 mb-md-5`}>
            {number && (
              <>
                <span className={style.number}>{number}</span>
                <br />
              </>
            )}
            {heading}
          </h2>
        )}
        {content && <div className={style.content}>{content}</div>}
      </div>
      {border && (
        <div
          className={theme === "dark" ? style.lightDivider : style.darkDivider}
        />
      )}
    </div>
  );
};

export default ChapterSection;
