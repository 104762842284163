import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  View,
  PDFDownloadLink,
  Text,
} from "@react-pdf/renderer";
import BackImage from "../../../Assets/pdf/A4_cert_bg_without_logo.png";
import Logo from "../../../Assets/pdf/vw-pdf-logo.png";
import HeadingSlogan from "../../../Assets/pdf/heading.png";
import DottedFont from "../../../Assets/Fonts/Night_School_Pixel_Font1.otf";
import VWHeadRegular from '../../../Assets/Fonts/00_VW_Fonts/00_VW_Fonts/VW_Print_Head_Text/VW_Head/VWHead-Regular.otf'
import { AppColorButton } from "../../AppButton/AppButton";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../Utils/Services/Common.Services";

const Pdf = ({ certificateData }) => {
  Font.register({
    family: "Dotted",
    src: DottedFont,
  });

  Font.register({
    family: 'VWHeadRegular',
    src: VWHeadRegular,
  });

  const styles = StyleSheet.create({
    container: {
      position: "relative",
    },

    imageMain: {
      height: 841.7,
      width: "auto",
    },

    vwLogo: {
      width: "220px",
      height: "200px",
    },

    vwCenteredLogo: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    vwCenteredSlogan: {
      position: "absolute",
      top: "24%",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    vwSlogan: {
      width: "700px",
      height: "90px",
    },

    // vwCenteredSlogan: {
    //   position: "absolute",
    //   top: "26%",
    //   left: "0",
    //   right: "0",
    //   textAlign: "center",
    //   color: "white",
    //   textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5) !important",
    // },

    // vwAwarded: {
    //   margin: "9px 0 0 0",
    // },

    personName: {
      textStroke: "4px red",
      fontSize: "37px",
      fontWeight: "bold",
      color: "#77d9ff",
      position: "absolute",
      top: "36%",
      left: "0",
      right: "0",
      textAlign: "center",
      fontFamily: "Dotted",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },

    vwBottomText: {
      fontFamily: "VWHeadRegular",
      position: "absolute",
      bottom: "8%",
      fontSize: "13px",
      left: "0",
      right: "0",
      textAlign: "center",
      color: "#9EE4FF",
      borderColor: "#9EE4FF",
      borderStyle: "solid",
      borderWidth: "2px 2px 0 2px",
      borderBottom: 0,
      margin: "0 70px 0 70px",
      padding: "25px",
      textShadow: "0 0 10px rgba(158, 228, 255, 0.8)"
    },

    vwBorderText: {
      margin: "0 0px 50px 0px",
      position: "absolute",
      bottom: "10px",
      left: "0%",
      color: "#9EE4FF",
      width: "100%",
      textAlign: "center",
      fontSize: "14px",
      fontFamily: "VWHeadRegular",
      textShadow: "0 0 10px rgba(158, 228, 255, 0.8)"
    },

    vwBorderTextWrapper: {
      position: "relative",
      textShadow: "0 0 10px rgba(158, 228, 255, 0.8)"
    },

    heightBox: {
      height: "10px",
      width: "100px",
      backgroundColor: "red",
    },

    sideLine1: {
      width: "110px",
      height: "2px",
      backgroundColor: "#9EE4FF",
      position: "absolute",
      bottom: "6px",
      left: "70px",
    },

    sideLine2: {
      width: "110px",
      height: "2px",
      position: "absolute",
      bottom: "6px",
      right: "70px",
      backgroundColor: "#9EE4FF",
    },
  });

  return (
    <>
      <PDFDownloadLink
        document={
          <Document>
            <Page size="A4">
              <View style={styles.container}>
                <Image src={BackImage} style={styles.imageMain} />
                <View style={styles.vwCenteredLogo}>
                  <Image src={Logo} style={styles.vwLogo} />
                </View>

                {/* <View style={styles.vwCenteredSlogan}>
                  <Text>CERTIFICATE OF COMPLETION</Text>
                  <Text style={styles.vwAwarded}>AWARDED TO</Text>
                </View> */}

                <View style={styles.vwCenteredSlogan}>
                  <Image src={HeadingSlogan} style={styles.vwSlogan} />
                </View>

                <View style={styles.personName}>
                  <Text>
                    {certificateData?.firstname?.toUpperCase()}{" "}
                    {certificateData?.surname?.toUpperCase()}
                  </Text>
                </View>

                <View style={styles.vwBottomText}>
                  <Text>
                    By successfully completing this course,{" "}
                    {capitalizeFirstLetter(certificateData?.firstname)}{" "}
                    {capitalizeFirstLetter(certificateData?.surname)} has
                  </Text>
                  <Text>
                    exhibited the dedication and competence necessary to help
                    make the
                  </Text>
                  <Text>roads safer for everyone at night.</Text>
                </View>

                <View style={styles.vwBorderText}>
                  <View style={styles.vwBorderTextWrapper}>
                    <View style={styles.sideLine1}></View>
                    <Text>
                      {moment(certificateData?.date).format("ll")} | no.{" "}
                      {certificateData?.certificate_number}
                    </Text>
                    <View style={styles.sideLine2}></View>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        }
        fileName="Volkswagen-Certificate.pdf"
      >
        <AppColorButton buttonName="Download" />
      </PDFDownloadLink>
    </>
  );
};

export default Pdf;
