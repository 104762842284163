export const BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

export const API_CONSTANT = {
  /* Auth Service*/
  REGISTER: "user/register",
  LOGIN: "user/login",
  LOGOUT: "user/logout",
  RESET_PASSWORD_EMAIL: "user/password/email",
  RESET_PASSWORD_CODE_CHECK: "user/password/code/check",
  RESET_PASSWORD: "user/password/reset",
};
