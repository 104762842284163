import axios from "axios";
import { BASE_URL } from "../Utils/Helper/ApiConstant";
import { userTokenMethod } from "../Utils/Services/Common.Services";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    // "Cache-Control": "no-cache, private",
    // "Content-Length": "1031148",
    // "Content-Type": "text/html; charset=UTF-8",
    // Date: "Tue, 24 Oct 2023 08:52:43 GMT",
    // Server: "Microsoft IIS/10.0",
    // Vary: "Origin",
    // "X-Powered-By": "ASP.NET",
    // "X-Powered-By": "PHP/8.2.9",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(function (config) {
  let token;
  if (userTokenMethod("get")) {
    token = userTokenMethod("get");
  }

  if (process.env.REACT_APP_TOKEN_TEMP) {
    token = process.env.REACT_APP_TOKEN_TEMP;
  }

  return {
    ...config,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      userTokenMethod("delete");
      // window.location.reload();
      //redirect to signin page if unauthenticated
      window.location = `${process.env.PUBLIC_URL}/sign-in`;
    }
    return error;
  }
);

const responseBody = (response) => {
  return {
    ...(response?.response?.data || response.data),
    status: response?.response?.status || response?.status || 404,
  };
};

const requests = {
  get: (url, body) => instance.get(url, body).then(responseBody),

  post: (url, body, headers) =>
    instance.post(url, body, headers).then(responseBody),

  put: (url, body) => instance.put(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default requests;
