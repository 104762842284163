import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import { Link } from "react-router-dom";
import Group from "../../Assets/Chapters/Group-1459.svg";
import { AppColorButton } from "../../Components/AppButton/AppButton";
import Progress from "../Progress/Progress";

const ChapterIncomplete = ({ number, chapterNumber }) => {
  const sectionRefs = useRef([]);
  const data = [
    {
      content: (
        <>
          <img src={Group} alt="" className={`${style.infoIcon} mb-3`} />
          <h2 className={`${style.heading}`}>
            <span className={`${style.number}`}>You’re not ready yet,</span>
            <br />
            Let’s go back to Chapter {number}
            <br />
            <Link to={`/chapters/${number}`}>
              <AppColorButton
                // handleClick={() => onSubmit()}
                buttonName={`Go Back`}
              />
            </Link>
          </h2>
        </>
      ),
    },
  ];

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`}>
      <div className="row">
        <div className="col-md-3">
          <Progress
            data={data}
            chapterNumber={`${chapterNumber}`}
            sectionRefs={sectionRefs}
          />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default ChapterIncomplete;
