import React from "react";
import { EmbarkHeader } from "../../Components/Embark/EmbarkHeader";
import EmbarkCard from "../../Components/Embark/EmbarkCard";

// Component: Embark
const Embark = () => {
  return (
    <>
      <EmbarkHeader />
      <EmbarkCard />
    </>
  );
};
export default Embark;
