import { useRef } from "react";
import ChapterSection from "./ChapterSection";
import style from "./ChapterSection.module.scss";
import img2 from "../../Assets/Chapters/Chapter5/image2.png";
import img3 from "../../Assets/Chapters/Chapter5/image3.png";
import img4 from "../../Assets/Chapters/Chapter5/image4.png";
import img5 from "../../Assets/Chapters/Chapter5/image5.png";
import icon1 from "../../Assets/Chapters/Chapter5/icon1.svg";
import icon2 from "../../Assets/Chapters/Chapter5/icon2.svg";
import icon3 from "../../Assets/Chapters/Chapter5/icon3.svg";
import icon4 from "../../Assets/Chapters/Chapter5/icon4.svg";
import icon5 from "../../Assets/Chapters/Chapter5/icon5.svg";
import icon6 from "../../Assets/Chapters/Chapter5/icon6.svg";
import icon7 from "../../Assets/Chapters/Chapter5/icon7.svg";
import icon8 from "../../Assets/Chapters/Chapter5/icon8.svg";
import icon9 from "../../Assets/Chapters/Chapter5/icon9.svg";
import icon10 from '../../Assets/Chapters/Chapter5/Speed_60_update.png'
import video1 from "../../Assets/Chapters/Chapter5/Fog lights alternative exterior 1.mp4";
import { AppColorButton } from "../AppButton/AppButton";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import { Link } from "react-router-dom";
import Progress from "../Progress/Progress";

const Chapter5 = ({ results, children }) => {
  const sectionRefs = useRef([]);

  let data = null;

  if (results) {
    data = [
      {
        number: "5.3.9",
        heading: "Chapter 5: Test Summary",
        content: children,
      },
    ];
  } else {
    data = [
      {
        number: "5.1",
        heading: "Weather Introduction",

        content: (
          <>
            <p className={`${style.mediumBold} my-4`}>
              Welcome to chapter 5.1, Weather conditions.{" "}
            </p>
            <p className={`${style.small} mb-4`}>
              Bad weather conditions play a huge part in road safety, so it’s
              important to understand how these impact driving at night when
              visibility is already low.
            </p>
            <p className={style.small}>
              This chapter will teach you about driving in various weather
              conditions like rain, fog, mist, and icy roads. You’ll also learn
              about using your fog lights and to be cautious when driving in
              rain. To learn more about driving in bad weather conditions – sign
              up for the in-person advanced driving Night School course.
            </p>
          </>
        ),
      },

      {
        number: "5.1.1",
        heading: "Tips: Keep vehicle ventilated",
        content: (
          <>
            <ul className={style.specialBullets}>
              <li className={`${style.small} my-4`}>
                Keep your vehicle well-ventilated when driving at night in
                extreme weather conditions. This helps to prevent foggy windows
                and condensation, ensuring that you can see clearly and maintain
                good visibility at night.
              </li>

              <li className={style.small}>
                Beware when driving in extreme weather at night, this includes
                rain, fog, mist, and icy roads. Avoid driving if possible.
              </li>
            </ul>
          </>
        ),
      },

      {
        number: "5.1.2",
        heading: "Tips: Fog lights",

        content: (
          <>
            <video className={style.Video} loop controls autoPlay={"autoplay"}>
              <source src={video1} type="video/mp4" />
            </video>

            <p className={`${style.mediumBold} mt-5`}>
              When it’s foggy at night, never use your headlights; always opt to
              use your fog lights for better vision, as they pierce through fog
              and mist to give you better visibility.
            </p>
          </>
        ),
      },

      {
        number: "5.1.3",
        heading: "Tips: Rain",
        content: (
          <>
            <div>
              <ul className={style.specialBullets}>
                <li className={`${style.small} mb-3`}>
                  When it rains, several things occur that make driving more
                  dangerous at night.{" "}
                </li>

                <li className={`${style.small} mb-3`}>
                  13% of accidents on the roads are caused by wet or slippery
                  roads because of rain.{" "}
                </li>

                <li className={`${style.small} mb-3`}>
                  Slow down even more to ensure you can brake and manoeuvre in
                  time without causing your vehicle to hydroplane.
                </li>

                <li className={`${style.small} mb-3`}>
                  Hydroplaning happens when your tyres drive on a wet road
                  resulting in a loss of traction and the ability to steer the
                  car.
                </li>

                <li className={`${style.small} mb-3`}>
                  Ensure your tyres are correctly inflated, to ensure maximum
                  traction.{" "}
                </li>

                <li className={`${style.small} mb-3`}>
                  Potholes fill up with water, reducing your ability to judge
                  how large/deep the potholes are, resulting in damage to your
                  tyre and potentially leading to an accident.
                </li>

                <li className={`${style.small} mb-3`}>
                  Wet roads reflect light when it rains causing astigmatism.
                  Refer to the “Vision” chapter for more information.
                </li>

                <li className={`${style.small} mb-3`}>
                  Drive slower to safely navigate the things in front of you.
                </li>

                <li className={style.small}>
                  Keep a safe following distance from the vehicle in front of
                  you as water displaced from their tyres might splash onto your
                  windscreen, causing obscured visibility as well as lights to
                  distort your vision.{" "}
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "5.1.4",
        heading: "Tips: Sleet or icy conditions",
        content: (
          <>
            <div className="row m-0">
              <div className={`${style.mobileImage} col-12 col-md-6`}>
                <img className={style.imageWidth} src={img2} alt="" />
              </div>

              <div className="align-self-center mt-4 mt-sm-5 my-md-0 col-12 col-md-6">
                <ul className={`${style.specialBullets2}`}>
                  <li className={`${style.small24} mb-5`}>
                    Evening temperatures drop (especially in winter), causing
                    the road to be covered in sleet. This can compromise the
                    grip on your tyres, leading to an accident.
                  </li>

                  <li className={style.small24}>
                    Make sure your tyres have the correct tread, and drive
                    slower as a precaution.
                  </li>
                </ul>
              </div>

              <div className={`${style.desktopImage} col-12 col-md-6`}>
                <img className={style.imageWidth} src={img2} alt="" />
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.1.5",
        heading: "Tips: Road signs",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon10}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold} mb-3`}>
                  Concentrate when driving in extreme weather.{" "}
                </p>

                <p className={style.smallBold}>
                  Pay attention to all road signs.
                </p>
              </div>
            </div>
          </>
        ),
      },


      

      {
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left">
                <img src={icon1} alt="" className={`${style.iconSize}`} />
              </div>

              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.medium} my-4 my-md-0`}>
                  For practical training on how to manage to skids, visit the{" "}
                  <strong className={style.mediumBold}>
                    Advanced Driving Academy
                  </strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3"></div>

              <div className="col-12 col-md-9">
                <div className="mt-0 mt-md-4">
                  <Link
                    to="https://www.vw.co.za/en/volkswagen-experience/driving-academy.html"
                    target="_blank"
                  >
                    <AppColorButton
                      buttonName={BUTTON_CONSTANT.FIND_OUT_MORE}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.2",
        heading: "Gravel Roads Introduction",
        content: (
          <>
            <div>
              <p className={`${style.mediumBold} mb-4`}>
                Welcome to chapter 5.2, Driving on Gravel roads at Night.{" "}
              </p>

              <p className={`${style.small} mb-4`}>
                Many South Africans drive on gravel roads, whether it’s for
                their daily commute or travelling back home to a rural area at
                the end of the year. Gravel roads create their unique challenges
                to safe driving at night.
              </p>

              <p className={style.small}>
                In this chapter, you will be equipped with the knowledge to
                better handle gravel roads at night like controlling your speed
                to avoiding possible collisions with animals.
              </p>
            </div>
          </>
        ),
      },

      {
        number: "5.2.1",
        heading: (
          <>
            Tips: Gravel <span className="lowercase">vs</span> suburban road
          </>
        ),
        content: (
          <>
            <div>
              <img className={style.imageWidth} src={img4} alt="" />

              <div className={`${style.mediumBold} my-5`}>
                <p>
                  Gravel roads are very dangerous at night due to the lack of
                  lighting (Street lights, or cat eyes) and adequate surface to drive on.
                </p>
              </div>
              <ul className={`${style.specialBullets} ${style.medium} mb-0`}>
                <li className={`${style.small} mb-3`}>
                  Bad weather conditions affect the quality of gravel roads and
                  can change their structures, so always exercise caution on
                  gravel roads, regardless of how often you use them.
                </li>

                <li className={`${style.small} mb-3`}>
                  When driving on gravel roads, it’s recommended to have
                  reflective tape on your vehicle so you’re visible to other
                  drivers on the gravel road at night.
                </li>

                <li className={`${style.small} mb-3`}>
                  Always control your speed, and don’t do any quick manoeuvres.
                </li>

                <li className={`${style.small} mb-3`}>
                  Stay in tracks – drive in the existing tyre tracks worked into
                  the gravel.
                </li>

                <li className={`${style.small} mb-3`}>
                  Take your foot off the accelerator if your car begins to skid,
                  and always steer in the correct direction.
                </li>

                <li className={`${style.small} mb-3`}>
                  Don’t make any sudden movements or overcorrect when you
                  experience a skid.
                </li>

                <li className={`${style.small} mb-3`}>
                  When it rains, the gravel becomes mud, which can be slippery –
                  always be prepared and in control of your vehicle.
                </li>

                <li className={`${style.small} mb-3`}>
                  Be cautious when overtaking on gravel, especially at night.{" "}
                </li>

                <li className={`${style.small} mb-3`}>
                  Be cautious of any livestock that may be on the road while
                  you’re driving at night.
                </li>

                <li className={style.small}>
                  This is where the Touareg’s Night Vision Function comes in
                  handy when driving on gravel roads. o Find out more about{" "}
                  <strong className={style.smallBold}>
                    Volkswagen’s Night Vision.
                  </strong>
                </li>
              </ul>
            </div>
          </>
        ),
      },

      {
        number: "5.2.2",
        heading: "Tips: Tyres on gravel",
        content: (
          <>
            <div className="row m-0">
              <div className="col-12 col-md-6 align-self-center">
                <img className={style.imageWidth} src={img3} alt="" />
              </div>

              <div className="align-self-center mt-4 mt-sm-5 my-md-0 col-12 col-md-6">
                <ul className={`${style.specialBullets2}`}>
                  <li className={`${style.small24} mb-3`}>
                    Make sure your tyres are safe to handle gravel and be aware
                    of their reduced traction on gravel roads.
                  </li>

                  <li className={`${style.small24} mb-3`}>
                    The chances of getting a puncture on gravel roads are much
                    higher than normal roads.
                  </li>

                  <li className={style.small24}>
                    If you do get a flat tyre and need to change your tyre,
                    avoid possible collisions by using the correct precautions
                    to make yourself visible to oncoming vehicles.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3",
        heading: "Loadshedding Introduction",

        content: (
          <>
            <p className={`${style.mediumBold} mb-4 mt-4`}>
              Welcome To Chapter 5.3, Loadshedding.{" "}
            </p>
            <p className={`${style.small} mb-4`}>
              Living in South Africa means you must deal with the inevitable,
              loadshedding. As inconvenient as it is, loadshedding is going to
              be a reality for years to come. Loadshedding can turn the safest
              roads into threats, as it affects traffic lights, traffic flow,
              and your general safety at night.
            </p>
            <p className={style.small}>
              In this chapter we will unpack the dangers of driving during
              loadshedding at night and tips of how to navigate through it.
            </p>
          </>
        ),
      },

      {
        number: "5.3.1",
        heading: "Tips: Plan your route",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon3}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Plan your route and avoid heavy traffic periods at night.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.2",
        heading: "Tips: Four-way stop",
        content: (
          <>
            <div className="row m-0">
              <div className="col-12 col-md-6 align-self-center">
                <img className={style.imageWidth} src={img5} alt="" />
              </div>

              <div className="align-self-center mt-4 mt-sm-5 my-md-0 col-12 col-md-6">
                <ul className={`${style.specialBullets}`}>
                  <li className={`${style.small} mb-3`}>
                    During load shedding, an intersection should be treated as a
                    four-way stop and must always be approached with caution.
                    Each car should proceed from their respective stop streets,
                    one at a time, in the same order that they arrived. However,
                    always look left, right, and ahead before proceeding, to
                    ensure that the other cars are abiding by the same rules.
                  </li>
                </ul>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.3",
        heading: "Tips: Loadshedding schedule",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon4}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Check apps like Eskom SePush for regular updates on the
                  loadshedding schedule.{" "}
                </p>

                <p className={style.smallBold}>
                  Other apps include Eskom Website, Loadshedding Notifier etc.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.4",
        heading: "Tips: Your surroundings",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon5}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Always stay alert of your surroundings when driving during
                  loadshedding at night.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.5",
        heading: "Tips: Criminals",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon6}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Be attentive to the dangers of criminals lurking in the dark.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.6",
        heading: "Tips: Share live location",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon7}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Share your live location with loved ones when driving at night
                  during loadshedding.{" "}
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.7",
        heading: "Tips: Charged cell phone",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon8}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Ensure that your cell phone is fully charged when power is
                  available.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        number: "5.3.8",
        heading: "Tips: Temporary lighting",
        content: (
          <>
            <div className="row m-0">
              <div className="p-0 col-12 col-md-3 text-left text-sm-center">
                <img
                  src={icon9}
                  alt=""
                  className={`${style.iconSize} align-self-center`}
                />
              </div>

              <div className="align-self-center col-12 col-md-9 mt-4 mt-md-0">
                <p className={`${style.mediumBold}`}>
                  Keep temporary lighting readily available in case of an
                  emergency.
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        content: (
          <>
            <div className="row">
              <div className="col-12 col-md-3 text-left">
                <img src={icon1} alt="" className={`${style.iconSize}`} />
              </div>

              <div className="align-self-center col-12 col-md-9">
                <p className={`${style.medium} my-4 my-md-0`}>
                  Visit the Volkswagen Advanced Driving Academy to learn more
                  about defensive driving techniques.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3"></div>

              <div className="col-12 col-md-9">
                <div className="mt-0 mt-md-">
                  <Link
                    to="https://www.vw.co.za/en/volkswagen-experience/driving-academy.html"
                    target="_blank"
                  >
                    <AppColorButton buttonName={BUTTON_CONSTANT.LEARN_MORE} />
                  </Link>
                </div>
              </div>
            </div>
          </>
        ),
      },

      {
        id: "test",
        number: "5.3.9",
        heading: "Take the Chapter Test",
        content: children,
      },
    ];
  }

  const handleRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };

  const renderChapterSections = () => {
    return data.map((obj, i) => {
      return (
        <ChapterSection
          data={obj}
          key={obj.number}
          border={i !== data.length - 1}
          handleRef={handleRef}
        />
      );
    });
  };

  return (
    <div className={`container ${style.chapterContainer}`} id="section-2">
      <div className="row">
        <div className="col-md-3">
          <Progress data={data} chapterNumber="5" sectionRefs={sectionRefs} />
        </div>
        <div className="col-md-9 mt-5 mt-md-0">{renderChapterSections()}</div>
      </div>
    </div>
  );
};

export default Chapter5;
