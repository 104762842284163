import React from "react";
import HomeHeader from "../../Components/Home/HomeHeader";
import HomeSkill from "../../Components/Home/HomeSkill";
import HomeWhatYouLearnCard from "../../Components/Home/HomeWhatYouLearnCard";

// Component: Home
const Home = () => {
  return (
    <>
      <HomeHeader />
      <HomeSkill />
      <HomeWhatYouLearnCard />
    </>
  );
};
export default Home;
