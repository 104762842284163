import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChapterHero from "../../Components/Chapter/ChapterHero";
import apiService from "../../Utils/Services/ApiService";
import Chapter1 from "../../Components/Chapter/Chapter1";
import Chapter2 from "../../Components/Chapter/Chapter2";
import Chapter3 from "../../Components/Chapter/Chapter3";
import Chapter4 from "../../Components/Chapter/Chapter4";
import Chapter5 from "../../Components/Chapter/Chapter5";
import Chapter6 from "../../Components/Chapter/Chapter6";
import ChapterIncomplete from "../../Components/Chapter/ChapterIncomplete";
import { Link } from "react-router-dom";
import ChapterBreadcrumbs from "../../Components/Chapter/ChapterBreadcrumbs";
import style from "./Chapter.module.scss";
import {
  AppColorButton,
  AppBorderButton,
} from "../../Components/AppButton/AppButton";
import WrapUp from "../../Components/Chapter/WrapUp";
import CustomProgressBar, {
  ProgressType,
} from "../../Components/CustomProgressBar/CustomProgressBar";
import smoothScrollToSection from "../../smoothScrollToSection";

// Component: Chapter
export default function Chapter() {
  //chapter number from URL param
  let { chapterNumber } = useParams();

  //test data
  let [data, setData] = useState(null);

  //progress data
  let [progress, setProgress] = useState(null);

  let [summary, setSummary] = useState(null);

  useEffect(() => {
    //fetch test data based on URL param
    if (!isNaN(Number(String(chapterNumber)))) {
      apiService
        .fetchChapter(chapterNumber)
        .then((res) => {
          setData(res?.status === 200 ? res : null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //fetch progress
    apiService
      .fetchProgress()
      .then((res) => {
        setProgress(res?.status === 200 ? res : null);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chapterNumber]);

  useEffect(() => {
    if (data) {
      //fetch chapter results
      apiService
        .fetchChapterResults(data?.chapter_id)
        .then((res) => {
          setSummary(res?.status === 200 ? res : null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  //map through all results of correct / incorrect answers
  const renderResults = () => {
    let { results } = summary;

    if (!results) {
      return <></>;
    }

    return results.map(({ question_number, result, answer_explained }) => {
      return (
        <div className="_question-result my-3" key={question_number}>
          <p
            className={
              result === "Correct" ? style.correctQuestion : style.failQuestion
            }
            style={{ textDecoration: answer_explained ? "underline" : "none" }}
          >
            Question {question_number}
          </p>
          <p>{answer_explained && answer_explained}</p>
        </div>
      );
    });
  };

  //question changes based on question number
  const renderSummary = () => {
    if (!data) {
      return <></>;
    }

    if (!summary) {
      return <></>;
    }

    if (!progress) {
      return <></>;
    }

    const {
      current_chapter_number,
      highest_completed_chapter_number,
      total_chapters,
    } = progress;
    const { correct, total, passed, results } = summary;
    const { description, chapter_number } = data;
    const percentage = Math.floor((correct / total) * 100);

    if (!results) {
      return (
        <>
          <p className={`${style.small} mb-4`}>
            You have not completed Chapter {chapterNumber} yet
          </p>
          <Link to={`/chapters/${chapterNumber}`}>
            <AppColorButton buttonName={`Go Back`} />
          </Link>
        </>
      );
    }

    return (
      <>
        <p className={`${style.small} mb-4`}>
          Your score is {correct}/{total}. You {passed ? "passed" : "failed"}{" "}
          chapter {chapter_number}: {description}.
        </p>
        <div className="py-0 py-sm-4 row">
          <div className="col-sm-6 col-md-3">
            <div className="py-4 py-sm-0">
              <CustomProgressBar
                type={ProgressType?.rounded}
                progressValue={percentage}
                color="#5DD87F"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-9">
            <div>
              <h2 className={`${style.progressTitle}`}>
                You {passed ? "Passed" : "Failed"}!
              </h2>
              <p className={`${style.small} mt-2 mb-4`}>
                See answer summary below:
              </p>
            </div>
            {renderResults()}
            <div className={`_buttons mt-5`}>
              {current_chapter_number > chapterNumber && (
                <Link to={`/chapters/${parseInt(chapterNumber) + 1}`}>
                  <AppColorButton buttonName="Next Chapter" />
                </Link>
              )}
              {total_chapters === highest_completed_chapter_number && (
                <Link to={`/chapters/wrap-up`}>
                  <AppColorButton buttonName="Wrap Up" />
                </Link>
              )}
              <Link to={`/chapters/${parseInt(chapterNumber)}`}>
                <AppBorderButton
                  handleClick={() => smoothScrollToSection("test", 1000)}
                  buttonName="Retake Test"
                />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderChapter = () => {
    //cannot go to this chapter if previous chapter is incomplete
    const tmpChapterNumber = isNaN(Number(String(chapterNumber)))
      ? progress?.current_chapter_number || 7
      : chapterNumber;
    if (progress && progress.current_chapter_number < tmpChapterNumber) {
      return <ChapterIncomplete number={progress.current_chapter_number} />;
    }

    //return chapter component based on chapter number and pass test as children prop
    switch (String(chapterNumber).toLowerCase()) {
      case "1":
        return <Chapter1 results>{renderSummary()}</Chapter1>;
      case "2":
        return <Chapter2 results>{renderSummary()}</Chapter2>;
      case "3":
        return <Chapter3 results>{renderSummary()}</Chapter3>;
      case "4":
        return <Chapter4 results>{renderSummary()}</Chapter4>;
      case "5":
        return <Chapter5 results>{renderSummary()}</Chapter5>;
      case "6":
        return <Chapter6 results>{renderSummary()}</Chapter6>;
      case "wrap-up":
        return <WrapUp></WrapUp>;

      default:
        return <></>;
    }
  };

  return (
    <>
      <ChapterHero
        number={chapterNumber}
        heading={data ? data?.description : ""}
      />
      <ChapterBreadcrumbs>
        <Link to="/home">Home</Link> &gt; <Link to="/embark">Nightschool</Link> &gt;{" "}
        <Link to={`/chapters/${chapterNumber}`}>
          {!isNaN(Number(String(chapterNumber)))
            ? data
              ? "Chapter " + chapterNumber
              : ""
            : "Wrap Up"}
        </Link>{" "}
        &gt; Summary
      </ChapterBreadcrumbs>

      {renderChapter()}
    </>
  );
}
