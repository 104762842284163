export const MESSAGE = {
  FIRST_NAME: "Please enter a valid first name",
  SUR_NAME: "Please enter a valid surname",
  STATE: "State is required",
  EMAIL_VALID: "Must be a valid email",
  EMAIL_REQUIRED: "Email is required",
  DATE_VALID: "Date of birth is required",
  PASSWORD_MINIMUM: "Password should be of minimum 10 characters length",
  PASSWORD_MAXIMUM: "Password should be of maximum 100 characters length",
  PASSWORD_REQUIRED: "Password is required",
  CONFIRM_PASSWORD_REQUIRED: "Please confirm your password",
  PASSWORD_MATCH: "Passwords does not match",
  SPACES_MATCH: "Leading and trailing spaces are not allowed."
};
