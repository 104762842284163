import React, { useContext, useRef, useState } from "react";
import style from "./LoginForm.module.scss";
import { AppColorButton } from "../AppButton/AppButton";
import { Link, Navigate } from "react-router-dom";
import { BUTTON_CONSTANT } from "../../Utils/Helper/Constants";
import ShowPassword from "../Auth/ShowPassword";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from "../../Assets/General/vw-logo.svg";
import Car from "../../Assets/SignIn/SignIncar.png";
import mailIcon from "../../Assets/General/email-icon.svg";
import { ThemeContext } from "../../Context/ThemeContext";
import apiService from "../../Utils/Services/ApiService";
import * as Yup from "yup";
import { MESSAGE } from "../../Utils/Helper/Message";
import { ErrorMessage, Formik } from "formik";
import { userTokenMethod } from "../../Utils/Services/Common.Services";
import ErrorMessages from "../Messages/ErrorMessages";

const LoginForm = () => {
  const recaptcha = useRef();
  const [passwordType, setPasswordType] = useState("password");
  const { theme } = useContext(ThemeContext);
  const [imageButton, setImageButton] = useState(true);
  const [errorsInfo, setErrorsInfo] = useState(null);
  const [processing, setProcessing] = useState(false);
  const gRecaptchaSiteKey = process.env.REACT_APP_G_RECAPTCHA_SITE_KEY;

  const searchParams = new URLSearchParams(window.location.search);

  const initialValues = {
    email: searchParams.has("email") ? searchParams.get("email") : "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
    password: Yup.string().required(MESSAGE.PASSWORD_REQUIRED),
  });

  const handleSubmit = async (values) => {

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      setErrorsInfo({
        'g-recaptcha': 'Please verify the reCAPTCHA!'
      })
      return
    }

    setProcessing(true);

    const data = {
      email: values?.email,
      password: values?.password,
      g_recaptcha_token: captchaValue
    };

    apiService
      .login(data)
      .then((res) => {
        const { status, token, errors } = res;
        if (status === 200) {
          userTokenMethod("set", token);
          window.location = `${process.env.PUBLIC_URL}/user-profile`; //needs to rerender for logged in state
        } else {
          setErrorsInfo(errors);
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  if (userTokenMethod("get")) {
    return <Navigate to="/user-profile" />;
  }

  return (
    <>
      <div className="container">
        <div className={style.mainContainer}>
          {/* logo */}

          <div className={style.logoContainer}>
            <img src={Logo} alt="VW-Logo" className={style.logoIcon} />
          </div>

          {/*=========================== Main ============================*/}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={`${style.rowContainer} row`}>
                  <div className="col-12 col-md-6">
                    <div className={style.loginContainer}>
                      <p className={style.sign1}>Sign In</p>

                      <div className={`${style.registerNavigation} pt-22`}>
                        <p className={style.account}>Don't have an account? </p>
                        <span className={style.account}>
                          Register{" "}
                          <Link to="/signup" className={style.here}>
                            here.
                          </Link>
                        </span>
                      </div>

                      {/* ------------ inputs ---------------- */}

                      <div className={style.inputField}>
                        <p className={style.fields}>*Mandatory fields</p>

                        {errorsInfo && (
                          <>
                            <br />
                            <ErrorMessages errors={errorsInfo} />
                          </>
                        )}

                        {/* email */}

                        <div className={style.all}>
                          <div className="underLineInputGroup field mb-10">
                            <div className={style.inputMainContainer}>
                              <input
                                type="email"
                                className={`${
                                  theme === "light" && style.darkBorder
                                } underLineInputField pr-40`}
                                placeholder="Email Address*"
                                name="email"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                              />
                              <label className="underLineInputLabel">
                                Email Address<span className="redStar">*</span>
                              </label>

                              <div className={style.inputIconContainer}>
                                <img
                                  src={mailIcon}
                                  className={style.inputIcon}
                                  alt="mailIcon"
                                />
                              </div>

                              <ErrorMessage
                                name="email"
                                component="div"
                                className="errorMessage"
                              />
                            </div>
                          </div>

                          {/* password */}

                          <div className="underLineInputGroup field mb-10">
                            <div className={style.inputMainContainer}>
                              <input
                                type={passwordType}
                                placeholder="Password*"
                                className={`${
                                  theme === "light" && style.darkBorder
                                } underLineInputField`}
                                name="password"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                              />
                              <label className="underLineInputLabel">
                                Password<span className="redStar">*</span>
                              </label>

                              <ShowPassword
                                setImageButton={setImageButton}
                                passwordType={passwordType}
                                setPasswordType={setPasswordType}
                                imageButton={imageButton}
                                flag="password"
                              />

                              <ErrorMessage
                                name="password"
                                component="div"
                                className="errorMessage"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${style.captcha} d-flex justify-content-center`}
                        >
                          <ReCAPTCHA
                            ref={recaptcha}
                            sitekey={
                              gRecaptchaSiteKey
                                ? gRecaptchaSiteKey
                                : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            }/>
                        </div>

                        <div className={style.forgotPass}>
                          <Link to={!processing && "/reset-password"}>
                            <p>Forgot your password?</p>
                          </Link>
                        </div>

                        <div>
                          <AppColorButton
                            buttonName={
                              processing
                                ? BUTTON_CONSTANT.PROCESSING
                                : BUTTON_CONSTANT.SIGN_IN
                            }
                            type={"submit"}
                            disabled={processing}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* image */}

                  <div className="col-12 col-md-6 text-center mt-md-0 mt-5">
                    <img src={Car} alt="carImage" className={style.carImage} />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
